<template>
  <DefaultModal @close="$emit('close')">
    <template slot="body">
      <div class="applicant-info-wrapper">
        <div
          v-for="(member, index) in content.members"
          :key="index"
          class="member-wrapper"
        >
          <h3 class="name">
            {{ member.name }}
            <span v-if="member.onCurrentContract" class="contract">
              (Står på kontraktet)
            </span>
          </h3>
          <span class="address">{{ content.address }}, {{ content.city }}</span>
          <span class="email">
            <BaseIconText :icon-url="svgs.ICONS.ENVELOPE">
              {{ member.email || '-' }}
            </BaseIconText>
          </span>
          <span class="ssn">
            <BaseIconText :icon-url="svgs.ICONS.ADMINISTRATOR_GRAY">
              {{ member.ssn || '-' }}
            </BaseIconText>
          </span>
          <span class="phone">
            <BaseIconText :icon-url="svgs.ICONS.PHONE">
              {{ member.phone || '-' }}
            </BaseIconText>
          </span>
        </div>

        <span v-if="content.propositionId" class="lb-connection">
          <BaseIconText :icon-url="svgs.ICONS.LB_LOGO" size="larger">
            <p>Bytesparten kommer från Lägenhetsbyte.se</p>
            <a
              href="https://api2.lagenhetsbyte.se/api/v2/preview"
              @click.stop.prevent="generatePreviewLink()"
            >
              Visa annons på Lägenhetsbyte.se
            </a>
          </BaseIconText>
        </span>
      </div>
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'PartInfoModal',
  components: {
    DefaultModal,
    BaseIconText
  },

  props: {
    content: {
      type: Object,
      default: null
    },
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs
    };
  },

  methods: {
    generatePreviewLink() {
      this.$http
        .getLbPreviewLink(this.applicationId, this.content.propositionId)
        .then(result => window.open(result, '_blank', 'noreferrer'));
    }
  }
};
</script>

<style scoped>
.member-wrapper {
  margin-bottom: 20px;
}

.applicant-info-wrapper {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-grey);
}

.applicant-info-wrapper .name {
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}

.applicant-info-wrapper .name .contract {
  color: var(--color-blue);
}

.applicant-info-wrapper .address {
  display: block;
  margin-top: 5px;
}

.applicant-info-wrapper .email,
.applicant-info-wrapper .ssn,
.applicant-info-wrapper .phone {
  display: block;
  margin-top: 1.5rem;
}

.applicant-info-wrapper .ssn :deep(.icon) {
  background-size: contain;
}

.applicant-info-wrapper .lb-connection {
  display: block;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid var(--color-light-grey);
  line-height: 20px;
}

.applicant-info-wrapper .lb-connection a {
  color: var(--color-blue);
  text-decoration: underline;
  font-weight: 600;
}

.applicant-info-wrapper .lb-connection :deep(.text) {
  display: block;
}
</style>
