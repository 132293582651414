<template>
  <div class="filter-btn-wrapper">
    <button
      v-if="datePickerValue !== null"
      class="clear-date-btn"
      @click="clearDate"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          fill="#2c3e50"
        />
      </svg>
    </button>
    <button
      v-if="activeDatePicker !== datePickerType"
      type="button"
      class="filter-btn"
      :class="{
        'is-selected': datePickerValue !== null
      }"
      @click="toggleDatePicker"
    >
      {{ formatDate(datePickerValue) }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    activeDatePicker: {
      type: String,
      default: ''
    },
    datePickerValue: {
      type: Date,
      default: null
    },
    datePickerType: {
      type: String,
      default: ''
    }
  },
  methods: {
    clearDate() {
      this.$store.commit('applications/setDatePickerValue', {
        type: this.datePickerType,
        value: null
      });
    },
    toggleDatePicker() {
      this.$emit('toggle-date-picker', this.datePickerType);
    },
    formatDate(date) {
      if (date instanceof Date) {
        const currentDate = new Date();
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        if (
          day === currentDay &&
          month === currentMonth &&
          year === currentYear
        ) {
          return 'Idag';
        } else {
          const monthNames = [
            'januari',
            'februari',
            'mars',
            'april',
            'maj',
            'juni',
            'juli',
            'augusti',
            'september',
            'oktober',
            'november',
            'december'
          ];
          const formattedDate = `${day} ${monthNames[month]} ${year}`;
          return formattedDate;
        }
      } else {
        return 'Välj datum';
      }
    }
  }
};
</script>
<style scoped>
.filter-btn-wrapper {
  position: relative;
}

.clear-date-btn {
  top: 0;
  width: 16px;
  z-index: 10;
  cursor: pointer;
  height: 16px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 0;
  transform: translate(30%, -30%);
  border: 1px solid var(--color-dark-blue);
}

.clear-date-btn svg {
  width: 100%;
}

.filter-btn {
  box-sizing: border-box;
  padding: 12.5px 20px;
  min-width: 63px;
  cursor: pointer;
  background-color: #fff;
  background-size: contain;
  border: 1px solid #878b9d;
  border-radius: 0.5rem;
  height: 4rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.filter-btn.is-selected {
  color: #fff;
  background-color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);
  outline: none;
}
</style>
