// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-14.use[1]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-14.use[2]!../../../node_modules/vue2-datepicker/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mx-datepicker * {\n\tfont-family: var(--font-averta);\n}\n\n.mx-datepicker .mx-table-date {\n\tcolor: #000;\n}\n\n.mx-datepicker .mx-calendar-header {\n\tborder-bottom: 1px solid #bdbdbd;\n\tmargin-left: 16px;\n\tmargin-right: 16px;\n\theight: 48px;\n}\n\n.mx-datepicker .mx-datepicker-main {\n\tpadding: 16px 32px 32px 32px;\n\tborder: none;\n}\n\n.mx-datepicker .mx-calendar {\n\twidth: 427px;\n\tpadding: 0;\n\tborder: none;\n}\n\n.mx-datepicker .mx-calendar-content {\n\theight: 367px;\n\twidth: 427px;\n}\n\n.mx-datepicker .mx-icon-double-left,\n.mx-datepicker .mx-icon-double-right {\n\tdisplay: none;\n}\n\n.mx-datepicker .mx-date-row {\n\tborder-collapse: separate;\n\tborder-spacing: 10px;\n}\n\n.mx-datepicker .mx-table-date .cell {\n\tfont-size: 18px;\n\tfont-weight: 600;\n\tline-height: 22px;\n\tcolor: #000;\n\theight: 42px;\n\tbackground-color: white;\n}\n\n.mx-datepicker .mx-table-date .cell.cell.disabled {\n\tcolor: rgba(0, 0, 0, 0.4);\n\tbackground-color: white;\n}\n\n.mx-datepicker .mx-table-date .cell:hover {\n\tcolor: var(--color-blue);\n\tbackground-color: white;\n}\n\n.mx-datepicker .mx-table-date .cell.cell.active {\n\tbackground-color: var(--color-blue);\n\tborder-radius: 100px;\n\tcolor: #fff;\n}\n\n.mx-datepicker .mx-btn {\n\tfont-size: 20px;\n}\n\n.mx-datepicker .mx-icon-left:before,\n.mx-datepicker .mx-icon-right:before {\n\twidth: 11px;\n\theight: 11px;\n\tborder-width: 3.5px 0 0 3.5px;\n\tcolor: #000;\n}\n\n.mx-datepicker .mx-btn-text {\n\tcolor: #828282;\n}\n\n.mx-datepicker .mx-table {\n\tborder-collapse: separate;\n\tborder-spacing: 15px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
