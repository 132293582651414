var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "shuffle", appear: "" } },
    [
      _c(
        "BaseModal",
        _vm._g(
          _vm._b(
            {
              attrs: {
                "close-btn-color": _vm.closeBtnColor,
                "show-close-btn": _vm.showCloseBtn,
              },
            },
            "BaseModal",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("div", { staticClass: "default-modal-wrapper" }, [
            _vm.$slots.header
              ? _c(
                  "section",
                  {
                    staticClass: "header flex-center",
                    class: { "border-bottom": !_vm.skipHeaderUnderline },
                  },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm.$slots.body
              ? _c("section", { staticClass: "body" }, [_vm._t("body")], 2)
              : _c("section", { staticClass: "body" }, [_vm._t("default")], 2),
            _vm.$slots.footer
              ? _c(
                  "section",
                  {
                    class: {
                      footer: true,
                      "flex-center": true,
                      "drop-shadow": _vm.footerShadow,
                    },
                  },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }