var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("DefaultModalTitle", [
            _vm._v(
              _vm._s(_vm.isSkandia ? "Neka byte" : "Neka byte på grund av")
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "transition",
            { attrs: { name: "reject-list-toggle", appear: "" } },
            [
              _vm.rejectOptionsListVisible && !_vm.textAreaVisible
                ? _c(
                    "section",
                    { staticClass: "reject-options-container" },
                    [
                      _c("div", { staticClass: "text-info-container" }, [
                        _c("h3", [
                          _vm._v("Välj ett alternativ för att neka bytet:"),
                        ]),
                        _c("p", [
                          _c("i", [
                            _vm._v(
                              "OBS! Endast anslutna hyresvärdar får ta del av denna information."
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "options-scroll-container" },
                        _vm._l(_vm.skandiaRejectOptionsList, function (opt) {
                          return _c(
                            "div",
                            { key: opt.id, staticClass: "reject-options" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "reject-options-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectRejectOption(opt)
                                    },
                                  },
                                },
                                [
                                  _c("p", { staticClass: "reject-option" }, [
                                    _vm._v(_vm._s(opt.label)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.rejectOptionModalVisible
                        ? _c("OptionsModal", {
                            attrs: {
                              "option-to-send": _vm.note,
                              "is-reject-reason": true,
                            },
                            on: {
                              send: _vm.sendRejectOption,
                              close: function ($event) {
                                _vm.rejectOptionModalVisible = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("section", [
                    _c(
                      "div",
                      { staticClass: "display-reject-options-container" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "display-reject-options-btn",
                            on: { click: _vm.handleRejectOptionsListVisible },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "display-reject-options-text" },
                              [_vm._v(" Färdiga alternativ för att neka byte ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "footer-wrapper" },
          [
            _vm.textAreaVisible && !_vm.isSkandia
              ? _c(
                  "transition",
                  { attrs: { name: "textarea-toggle", appear: "" } },
                  [
                    _c("div", { staticClass: "textarea-wrapper" }, [
                      _c("textarea", {
                        staticClass: "area",
                        attrs: {
                          disabled: _vm.isSaving,
                          cols: "30",
                          rows: "5",
                        },
                        domProps: { value: _vm.note },
                        on: {
                          input: function ($event) {
                            _vm.note = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "button-container" },
              [
                !_vm.textAreaVisible && !_vm.isSkandia
                  ? _c(
                      "BaseButton",
                      {
                        attrs: {
                          "is-disabled": _vm.isSaving,
                          "is-rounded": true,
                          icon: _vm.svgs.ICONS.PEN_WHITE,
                        },
                        on: { click: _vm.handleTextAreaVisible },
                      },
                      [_vm._v("Fritext")]
                    )
                  : !_vm.isSkandia
                  ? _c(
                      "BaseButton",
                      {
                        attrs: {
                          "is-loading": _vm.isSaving,
                          "is-rounded": true,
                          "is-warning": true,
                          "is-disabled": _vm.note.length === 0,
                          icon: _vm.svgs.ICONS.ERROR_WHITE,
                        },
                        on: { click: _vm.rejectApplication },
                      },
                      [_vm._v(" Neka byte ")]
                    )
                  : _vm._e(),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      "is-disabled": _vm.isSaving,
                      "is-rounded": true,
                      "is-default": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Avbryt")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }