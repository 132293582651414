function normalizeHash(hash, min, max) {
  return Math.floor((hash % (max - min)) + min);
}

function getHashOfString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
}

export default function generateHSL(
  str,
  { hRange = [0, 350], sRange = [60, 100], lRange = [30, 60] } = {}
) {
  const hash = getHashOfString(str);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);

  return `hsl(${h}, ${s}%, ${l}%)`;
}
