<template>
  <div>
    <form>
      <DefaultModal :blur-bg="true" :show-close-btn="false">
        <template v-if="success" slot="body">
          <div class="message-wrapper">
            <h4>Lösenordet är ändrat</h4>
            <p>
              <a href="/logga-in" class="link">Klicka här för att logga in.</a>
            </p>
          </div>
        </template>

        <template v-else-if="error" slot="body">
          <div class="message-wrapper">
            <h4>Oops!</h4>
            <p>
              Återställningslänken är antingen redan använd, eller så är den
              felaktig.
            </p>

            <p>
              Vänligen kontakta er kontaktperson hos oss för att få en ny
              återställningslänk,
              <a href="/logga-in" class="link">eller gå till inloggningen.</a>
            </p>
          </div>
        </template>

        <template v-else slot="body">
          <form @submit.stop.prevent="changePassword()">
            <h4>Välj nytt lösenord</h4>
            <div class="login-wrapper">
              <DefaultInput
                v-model="password"
                name="password"
                :disabled="changingPassword"
                placeholder="Nytt lösenord"
                type="password"
                @clear="clearPassword"
              ></DefaultInput>

              <p class="msg">
                <span v-if="password">
                  <span v-if="passwordErrors.includes('uppercase')"
                    >Lösenordet måste innehålla minst en stor bokstav.
                  </span>
                  <span v-else-if="passwordErrors.includes('lowercase')"
                    >Lösenordet måste innehålla minst en liten bokstav.
                  </span>
                  <span v-else-if="passwordErrors.includes('digits')"
                    >Lösenordet måste innehålla minst siffra.
                  </span>
                  <span v-else-if="passwordErrors.includes('min')"
                    >Lösenordet måste minst 8 tecken.
                  </span>
                </span>
              </p>

              <DefaultInput
                v-model="passwordRepeat"
                name="passwordRepeat"
                :disabled="changingPassword"
                placeholder="Repetera nytt lösenord"
                type="password"
                @clear="clearPasswordRepeat"
              ></DefaultInput>
            </div>

            <p class="msg">
              <span v-if="errorMessage" class="text-error">{{
                errorMessage
              }}</span>
            </p>

            <div class="login-button">
              <BaseButton
                type="submit"
                :is-loading="changingPassword"
                :is-disabled="changingPassword"
                :is-fullwidth="false"
                :is-rounded="true"
                >Ändra lösenord</BaseButton
              >
            </div>
          </form>
        </template>
      </DefaultModal>
    </form>
  </div>
</template>

<script>
import DefaultInput from '@/components/DefaultInput.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import passwordValidator from 'password-validator';

export default {
  components: {
    DefaultInput,
    DefaultModal,
    BaseButton
  },

  data() {
    return {
      changingPassword: false,
      errorMessage: false,
      password: '',
      passwordRepeat: '',
      schema: new passwordValidator(),
      success: false,
      error: false
    };
  },

  computed: {
    passwordErrors() {
      return this.schema.validate(this.password, { list: true });
    }
  },

  created() {
    this.schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits(1); // Must have at least 2 digits
  },

  methods: {
    async changePassword() {
      this.changingPassword = true;
      this.errorMessage = null;

      if (this.passwordErrors.length > 0) {
        this.passwordErrorVisible = true;
        this.changingPassword = false;
        return;
      }

      if (this.password !== this.passwordRepeat) {
        this.errorMessage =
          'Vänligen säkerställ att lösenordet är samma i båda fält.';
        this.changingPassword = false;
        return;
      }

      const pathArr = window.location.pathname.split('/');
      const uuuid = pathArr[pathArr.length - 1];
      try {
        await this.$http.changePassword(this.password, uuuid);
        this.success = true;
      } catch {
        this.error = true;
      }

      this.clearPassword();
      this.clearPasswordRepeat();
      this.changingPassword = false;
    },
    clearPassword() {
      this.password = '';
    },
    clearPasswordRepeat() {
      this.passwordRepeat = '';
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 1.6rem;
  margin-bottom: 14px;
}

p {
  padding-top: 10px;
}

.text-error {
  color: #ff3843 !important;
}

.msg {
  min-height: 20px;
  padding: 0;
  padding-top: 2px;
  margin: 0 !important;
  font-size: 1.2rem;
  color: blue;
}

.login-wrapper > *:not(:first-child) {
  margin-top: 10px;
}

.login-button {
  margin: 20px 20px 20px 0px;
  display: flex;
  justify-content: center;
}

.message-wrapper {
  padding-bottom: 30px;
  font-size: 14px;
  max-width: 500px;
}

.link {
  color: blue;
}
</style>
