function loadJs() {
  return new Promise(resolve => {
    const address =
      'https://cdn.maptiler.com/maplibre-gl-js/v1.14.0/maplibre-gl.js';
    const exists = document.querySelector(`script[src="${address}"]`);

    if (exists) {
      exists.onload = () => resolve();
    }
    const script = document.createElement('script');
    script.onload = () => resolve();
    script.async = true;
    script.src = address;
    document.head.appendChild(script);
  });
}

function loadCss() {
  return new Promise(resolve => {
    const address =
      'https://cdn.maptiler.com/maplibre-gl-js/v1.14.0/maplibre-gl.css';
    const exists = document.querySelector(`link[href="${address}"]`);

    if (exists) {
      exists.onload = () => resolve();
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.onload = () => resolve();
    link.async = true;
    link.href = address;
    document.head.appendChild(link);
  });
}

export { loadJs, loadCss };
