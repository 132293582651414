var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Ändra bytet till godkänd")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _vm.isFetchingNote
          ? _c("div", { staticClass: "spinner-container" }, [
              _c("div", { staticClass: "spinner" }, [_c("BaseSpinner")], 1),
            ])
          : _c(
              "p",
              { staticClass: "reject-reason" },
              [
                _vm.lastChangedReason
                  ? [
                      _c("p", [
                        _vm._v(" ("),
                        _c("b", [_vm._v(_vm._s(_vm.lastChangedReason.name))]),
                        _vm._v(" " + _vm._s(_vm.lastChangedReason.text) + ") "),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isFetchingNote
            ? [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      "is-loading": _vm.isSaving,
                      "is-rounded": true,
                      icon: _vm.svgs.ICONS.CHECK_WHITE,
                    },
                    on: { click: _vm.approveApplication },
                  },
                  [_vm._v("Ändra till godkänd")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      "is-disabled": _vm.isSaving,
                      "is-rounded": true,
                      "is-default": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Avbryt")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }