<template>
  <ErrorModal v-if="hasError" @close="$emit('close')" />
  <DefaultModal v-else @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Godkänn ansökan?</DefaultModalTitle>
    </template>

    <template slot="body">
      <div :style="{ textAlign: 'center' }">
        <p class="swap-description">
          <span v-if="!rentPeriodChanged">Godkänn med följande period:</span>
          <span v-else class="rentchangetitle">Godkänn med ny period:</span>
        </p>
        <div class="date-container">
          <div class="datePeriod">
            <Input
              v-model="startDateProxy"
              :class="{ hasChanged: rentFromChanged }"
              label="Start datum"
              type="date"
              :min="sweDateStr(new Date())"
            /><span class="line"></span
            ><Input
              v-model="endDateProxy"
              :class="{ hasChanged: rentToChanged }"
              label="Slut datum"
              type="date"
              :min="startDateProxy"
            />
          </div>
        </div>
        <p v-if="rentPeriodChanged" class="wish-period">
          Önskad period:
          <br />
          <b
            ><span :class="{ hasChanged: rentFromChanged }">{{
              rentFromProxy
            }}</span>
            -
            <span :class="{ hasChanged: rentToChanged }">{{
              rentToProxy
            }}</span></b
          >
        </p>
        <p v-if="startDateIsAfterEndDate" class="wish-period no-padding">
          <span class="red"
            ><b>Startdatumet</b> måste vara före <b>slutdatumet</b></span
          >
        </p>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isSaving"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        :is-disabled="startDateIsAfterEndDate"
        @click="approveAhApplication"
        >Godkänn ansökan</BaseButton
      >
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
import Input from '@/components/DateInput.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import ErrorModal from '@/components/ErrorModal.vue';
// import churnService from '@/utils/churn360';
// import { events, features } from '@/utils/trackingEvents';

export default {
  name: 'SubletApprovalModal',
  components: {
    DefaultModal,
    BaseButton,
    Input,
    DefaultModalTitle,
    ErrorModal
  },

  props: {
    applicationId: {
      type: Number,
      default: null
    },
    //Actual confirmed dates by hyresvärd
    initialStartDate: {
      type: String,
      default: null
    },
    initialEndDate: {
      type: String,
      default: null
    },
    //Dates suggested by uthyraren
    rentTo: {
      type: String,
      default: null
    },
    rentFrom: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      startDate: this.initialStartDate,
      endDate: this.initialEndDate,
      hasError: false
    };
  },
  computed: {
    startDateIsAfterEndDate() {
      return (
        new Date(this.startDateProxy).getTime() >
        new Date(this.endDateProxy).getTime()
      );
    },
    rentPeriodChanged() {
      return this.rentFromChanged || this.rentToChanged;
    },
    rentFromChanged() {
      return this.startDateProxy !== this.rentFromProxy;
    },
    rentToChanged() {
      return this.endDateProxy !== this.rentToProxy;
    },
    rentToProxy() {
      return this.getDate(this.rentTo);
    },
    rentFromProxy() {
      return this.getDate(this.rentFrom);
    },
    startDateProxy: {
      get() {
        return this.startDate
          ? this.getDate(this.startDate)
          : this.rentFromProxy;
      },
      set(value) {
        this.startDate = value;
      }
    },
    endDateProxy: {
      get() {
        return this.endDate ? this.getDate(this.endDate) : this.rentToProxy;
      },
      set(value) {
        this.endDate = value;
      }
    }
  },
  methods: {
    ...mapActions({
      approveAhApp: 'applications/approveAhApp',
      setAhRentPeriod: 'applications/setAhRentPeriod'
    }),
    async approveAhApplication() {
      this.isSaving = true;
      this.hasError = false;
      // churnService.track(
      //   events.CHANGE_SWAP_STATUS,
      //   features.CHANGE_SWAP_STATUS.APPROVE
      // );
      try {
        await this.setAhRentPeriod({
          id: this.applicationId,
          startDate: this.startDateProxy,
          endDate: this.endDateProxy
        });
        await this.approveAhApp({ id: this.applicationId });
        this.$emit('onApproved');
      } catch {
        this.hasError = true;
      }
      this.isSaving = false;
    },
    getDate(value) {
      return value ? this.sweDateStr(value) : '';
    },
    sweDateStr(value = null) {
      return new Date(value).toLocaleDateString('sv-SE');
    }
  }
};
</script>

<style scoped>
.swap-description {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}
.swap-description b {
  color: var(--color-dark-blue);
}
.wish-period {
  font-size: 14px;
  padding: 8px;
}
.wish-period.no-padding {
  padding: 0;
}
.new {
  color: #40c07b;
}
.rentchangetitle {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}
.datePeriod {
  position: relative;
  display: inline-block;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.datePeriod > * {
  flex: 1 1 auto;
}
.line {
  height: 2px;
  width: 30px;
  background-color: var(--color-dark-blue);
}
.hasChanged {
  color: var(--color-blue);
}
.hasChanged :deep(input) {
  border-color: var(--color-blue);
}
.red {
  color: var(--color-red);
}
</style>
