var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Ej tilldelad")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("p", [_vm._v("Du är inte tilldelad bytet, vill du radera ändå?")]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, icon: _vm.svgs.ICONS.CHECK_WHITE },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [_vm._v("Radera")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Avbryt")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }