<template>
  <transition name="shuffle" appear>
    <BaseModal
      v-bind="$attrs"
      :close-btn-color="closeBtnColor"
      :show-close-btn="showCloseBtn"
      v-on="$listeners"
    >
      <div class="default-modal-wrapper">
        <section
          v-if="$slots.header"
          class="header flex-center"
          :class="{ 'border-bottom': !skipHeaderUnderline }"
        >
          <slot name="header"></slot>
        </section>

        <section v-if="$slots.body" class="body">
          <slot name="body"></slot>
        </section>

        <section v-else class="body">
          <slot></slot>
        </section>

        <section
          v-if="$slots.footer"
          :class="{
            footer: true,
            'flex-center': true,
            'drop-shadow': footerShadow
          }"
        >
          <slot name="footer"></slot>
        </section>
      </div>
    </BaseModal>
  </transition>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
export default {
  components: { BaseModal },
  props: {
    closeBtnColor: {
      type: String,
      default: '#2C3E50'
    },
    skipHeaderUnderline: {
      type: Boolean,
      default: false
    },
    footerShadow: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>
.default-modal-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  min-width: 500px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}
.border-bottom {
  border-bottom: solid 1px #e8e8e8;
}
.header {
  color: var(--color-dark-blue);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex: 1;
}
.body {
  padding: 35px;
  max-height: 70vh;
  overflow: auto;
}
.footer {
  padding: 0 35px 35px;
  gap: 15px;
}
.footer.drop-shadow {
  box-shadow: 0 0px 11px 1px rgba(193, 191, 191, 0.5);
  z-index: 2;
  position: relative;
}
</style>
