var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultPage", [
    _c("h1", { staticClass: "page-title" }, [_vm._v("Statistik")]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "ChartCard",
          { attrs: { title: "Utfall av byten" } },
          [
            _c("ApprovedSwapsChart", {
              attrs: {
                "approved-amount": _vm.general.approved,
                "rejected-amount": _vm.general.rejected,
                "cancelled-amount": _vm.general.cancelled,
                "removed-amount": _vm.general.removed,
                loading: _vm.loadingGeneral,
              },
            }),
          ],
          1
        ),
        _c(
          "ChartCard",
          { attrs: { title: "Byte av hyresvärd" } },
          [
            _c("SameLandlordChart", {
              attrs: {
                "swaps-with-other-landlords-amount":
                  _vm.userStats.movingWithinLandlord?.other,
                "swaps-within-landlord-amount":
                  _vm.userStats.movingWithinLandlord?.same,
                loading: _vm.loadingUserStats,
              },
            }),
          ],
          1
        ),
        _c(
          "ChartCard",
          {
            staticClass: "card-full-width",
            attrs: {
              title:
                "Hur länge hyresgästen bott i lägenheten innan påbörjat byte",
              subtitle: _vm.userStats?.avgYears
                ? `(Genomsnitt <b>${_vm.userStats.avgYears}</b> år)`.replace(
                    ".",
                    ","
                  )
                : "",
              height500: "",
            },
          },
          [
            _c("YearsBeforeChart", {
              attrs: {
                "had-years-list": _vm.userStats.hadYearsList,
                loading: _vm.loadingUserStats,
              },
            }),
          ],
          1
        ),
        _c(
          "ChartCard",
          {
            staticClass: "card-full-width",
            attrs: { title: "Ansökningar senaste 12 månaderna", height500: "" },
          },
          [
            _c("ApplicationsChart", {
              attrs: {
                "sent-to-host": _vm.general.sentToHost,
                loading: _vm.loadingGeneral,
              },
            }),
          ],
          1
        ),
        _c(
          "ChartCard",
          { attrs: { title: "Typ av byte" } },
          [
            _c("SwapTypeChart", {
              attrs: {
                "swap-type-counts": _vm.swapTypes.swapTypeCounts,
                loading: _vm.loadingSwapTypes,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "ChartCard",
              { attrs: { title: "Kuriosa" } },
              [
                _vm.loadingGeneral
                  ? _c("BaseLoadingSpinner")
                  : _vm.general.avgSwapTimeDays
                  ? _c("p", [
                      _c("strong", { staticClass: "big" }, [
                        _vm._v(
                          " " + _vm._s(Math.round(_vm.general.avgSwapTimeDays))
                        ),
                      ]),
                      _vm._v(" dagar är den genomsnittliga bytestiden "),
                    ])
                  : _c("p", [_vm._v("Ingen data tillgänglig")]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }