var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      class: { "h-300": _vm.height300, "h-500": _vm.height500 },
    },
    [
      _c("h3", { staticClass: "card__title" }, [
        _vm._v(_vm._s(_vm.title)),
        _c("small", { domProps: { innerHTML: _vm._s(_vm.subtitle) } }),
      ]),
      _c("div", { staticClass: "card__body" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }