var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrapper" }, [
    _c(
      "input",
      _vm._b(
        {
          attrs: {
            name: _vm.label,
            type: _vm.$attrs.type || "text",
            disabled: _vm.$attrs.disabled,
          },
          domProps: { value: _vm.strValue },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }