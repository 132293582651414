var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.applications
    ? _c("div", { staticClass: "lane-wrapper" }, [
        _c(
          "div",
          { staticClass: "sub-header" },
          [
            _c(
              "h2",
              { staticClass: "lane-title", class: [_vm.statusTextClass] },
              [
                _vm._v(" " + _vm._s(_vm.title) + " "),
                _vm.applications.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass: "lane-title-note",
                        class: [_vm.statusBgClass],
                      },
                      [
                        _c("span", { staticClass: "lane-title-note-number" }, [
                          _vm._v(_vm._s(_vm.applications.length)),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._t("controls"),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "applications-container", style: _vm.containerStyle },
          [_vm._t("body")],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }