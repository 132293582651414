<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Filtypen stöds inte</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="f-description">
        Det gick inte att ladda upp filen/filerna eftersom filtypen inte stöds.
        Godkända filformat är
        <strong>{{ readableAcceptedFiles }}</strong
        >.
      </p>
    </template>

    <template slot="footer">
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    readableAcceptedFiles() {
      return this.files.map(type => type.split('/')[1]).join(', ');
    }
  }
};
</script>

<style scoped>
.f-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
}

.f-description b {
  color: var(--color-dark-blue);
}
</style>
