<template>
  <ul v-outside-click="close">
    <li v-for="item in items" :key="item.title" :class="[item.theme]">
      <div class="icon-text-container">
        <span class="icon-container" v-html="item.icon"></span>
        <span class="text">{{ item.title }}</span>
      </div>
      <p class="content">{{ item.content }}</p>
      <button type="button" class="cta" @click="$emit('click', item.type)">
        {{ item.cta }}
      </button>
    </li>
  </ul>
</template>

<script>
import outsideClick from '@/directives/outside-click';

export default {
  directives: { outsideClick },

  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    close(e) {
      e.stopPropagation();
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
ul {
  background-color: #fff;
  text-align: left;
  padding: 3rem;
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
  width: 30rem;
}

ul::after {
  content: ' ';
  position: absolute;
  border-style: solid;
  top: 100%;
  left: 50%;
  border-width: 10px;
  border-color: transparent transparent #fff transparent;
  transform: rotate(180deg) translateX(-50%);
  transform-origin: left;
}

ul li {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px;
  padding-bottom: 14px;
}

ul li:nth-last-child(1) {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.content {
  color: var(--color-dark-blue);
  font-size: 12px;
  margin-bottom: 0.7rem;
  line-height: 1.4;
}

.cta {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  color: var(--color-blue);
  font-weight: 600;
  cursor: pointer;
}

.icon-text-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 0.5rem;
}

.icon-container {
  width: 22px;
  height: 22px;
  display: block;
}

.icon-container svg {
  width: 100%;
  display: block;
}

.text {
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  color: var(--color-dark-blue);
}

.red .cta {
  color: var(--color-red);
}

.yellow .cta {
  color: var(--status-aborted);
}

.blue .cta {
  color: var(--status-waiting);
}
</style>
