<template>
  <button class="tag-button" @click="toggleTagModal(applicationId)">
    <img :src="svgs.ICONS.ADD_ICON" />
  </button>
</template>
<script>
import { mapMutations } from 'vuex';
import svgs from '@/utils/icon-constants';

export default {
  props: {
    applicationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      svgs
    };
  },
  methods: {
    ...mapMutations({
      toggleTagModal: 'tags/toggleTagModal'
    })
  }
};
</script>

<style scoped>
.tag-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  height: 2rem;
  width: 2rem;
  transition: 0.2s ease;
  padding: 0;
  opacity: 0.8;
}

.tag-button img {
  width: 100%;
  height: 100%;
  display: block;
}

.tag-button:hover {
  transform: scale(1.3);
  opacity: 1;
}
</style>
