import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import applications from './modules/applications';
import landlordFiles from './modules/landlordFiles';
import tags from './modules/tags';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    app,
    applications,
    landlordFiles,
    tags
  }
});
