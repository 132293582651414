<template>
  <ul ref="container" class="tags">
    <transition-group name="shuffle" appear tag="li"
      ><Tag v-for="tagId in tags" :key="tagId" :tag-id="tagId"
    /></transition-group>
  </ul>
</template>
<script>
import Tag from '@/components/Tag.vue';

export default {
  components: {
    Tag
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tags li {
  transition: all 150ms linear;
  height: 0;
  overflow: hidden;
}

.tags li:not(:empty) {
  height: 21px;
}

.tags li:not(:empty):nth-child(1) {
  margin-top: 0.7rem;
}
</style>
