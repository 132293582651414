<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ej tilldelad</DefaultModalTitle>
    </template>

    <template slot="body">
      <p>Du är inte tilldelad bytet, vill du radera ändå?</p>
    </template>

    <template slot="footer">
      <BaseButton
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        @click="$emit('confirm')"
        >Radera</BaseButton
      >
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

import svgs from '@/utils/icon-constants';

export default {
  name: 'NotAssignedModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  data() {
    return {
      svgs
    };
  }
};
</script>

<style scoped>
p {
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

p b {
  color: var(--color-dark-blue);
}
</style>
