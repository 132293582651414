<template>
  <DefaultPieChart :data="data" :loading="loading" />
</template>
<script>
import DefaultPieChart from '@/components/DefaultPieChart.vue';

export default {
  components: {
    DefaultPieChart
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    rejectedAmount: {
      type: Number,
      default: 0
    },
    approvedAmount: {
      type: Number,
      default: 0
    },
    cancelledAmount: {
      type: Number,
      default: 0
    },
    removedAmount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    data() {
      if (
        !this.approvedAmount &&
        !this.rejectedAmount &&
        !this.cancelledAmount &&
        !this.removedAmount
      ) {
        return {};
      }
      return {
        labels: ['Godkända', 'Nekade', 'Avbrutna', 'Borttagna'],
        datasets: [
          {
            backgroundColor: ['#40c07b', '#ff3843', '#eed039', '#878b9d'],
            data: [
              this.approvedAmount,
              this.rejectedAmount,
              this.cancelledAmount,
              this.removedAmount
            ]
          }
        ]
      };
    }
  }
};
</script>
