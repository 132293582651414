var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultPage", [
    _c("h1", { staticClass: "page-title" }, [
      _vm._v("Sidan kunde inte hittas"),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "card" }, [
        _vm._v(
          " Sidan du försökte komma åt kunde inte hittas. Detta kan bero på flera faktorer, såsom en felaktig länk, ett stavfel i webbadressen, eller att sidan har tagits bort/flyttats. "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }