<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Radera byte på grund av</DefaultModalTitle>
    </template>

    <template slot="body">
      <transition name="textarea-toggle" appear>
        <div class="textarea-wrapper">
          <textarea
            :value="note"
            class="area"
            :disabled="isSaving"
            cols="30"
            rows="5"
            @input="note = $event.target.value"
          ></textarea>
          <p class="disclaimer">
            När du raderar ett byte kommer all känslig information om bytet
            raderas permanent. Bytet kommer att finnas kvar i arkivet. Du kan
            ångra borttagningen i fem dagar ifall något blivit fel.
          </p>
        </div>
      </transition>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isSaving"
        :is-rounded="true"
        :is-warning="true"
        :icon="svgs.ICONS.ERROR_WHITE"
        @click="removeApplication"
      >
        Radera byte
      </BaseButton>
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
// import churnService from '@/utils/churn360';
// import { events, features } from '@/utils/trackingEvents';

export default {
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      note: ''
    };
  },

  methods: {
    ...mapActions({
      removeAhApp: 'applications/removeAhApp'
    }),
    async removeApplication() {
      this.isSaving = true;
      await this.removeAhApp({ id: this.applicationId, note: this.note });
      // churnService.track(
      //   events.CHANGE_SWAP_STATUS,
      //   features.CHANGE_SWAP_STATUS.REMOVE
      // );
      this.isSaving = false;
      this.$emit('onRemoved');
    }
  }
};
</script>

<style scoped>
.textarea-wrapper {
  overflow: hidden;
}

.area {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-inter);
}

.disclaimer {
  padding: 0 35px;
  left: 0;
  right: 0;
  position: absolute;
}
</style>
