var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "notification-symbol",
    style: {
      "border-color": _vm.borderColor,
      right: _vm.offsetRight ? `${_vm.offsetRight}px` : null,
      top: _vm.offsetTop ? `${_vm.offsetTop}px` : null,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }