import CHECK from '@/assets/svgs/icons/check.svg';
import CHECK_NOTE from '@/assets/svgs/icons/check_note.svg';
import CHECK_NOTE_WHITE from '@/assets/svgs/icons/check_note-white.svg';
import CHECK_NOTE_GREY from '@/assets/svgs/icons/check_note-grey.svg';
import CHECK_WHITE from '@/assets/svgs/icons/check-white.svg';
import ERROR from '@/assets/svgs/icons/error.svg';
import ERROR_WHITE from '@/assets/svgs/icons/error-white.svg';
import ATTACHMENT from '@/assets/svgs/icons/attachment.svg';
import NOTEBOOK from '@/assets/svgs/icons/notebook.svg';
import FOLDER from '@/assets/svgs/icons/folder.svg';
import PDF from '@/assets/svgs/icons/pdf.svg';
import PDF_ALT from '@/assets/svgs/icons/pdf-alt.svg';
import PDF_ALT_GREY from '@/assets/svgs/icons/pdf-alt-grey.svg';
import JPG from '@/assets/svgs/icons/jpg.svg';
import CONTACT from '@/assets/svgs/icons/contact.svg';
import COMMENT from '@/assets/svgs/icons/comment.svg';
import ENVELOPE from '@/assets/svgs/icons/envelope.svg';
import PHONE from '@/assets/svgs/icons/phone.svg';
import ADMINISTRATOR from '@/assets/svgs/icons/administrator.svg';
import ADMINISTRATOR_GRAY from '@/assets/svgs/icons/administrator-gray.svg';
import CLOSE from '@/assets/svgs/icons/close.svg';
import YELLOW_CLOCK from '@/assets/svgs/icons/yellow_clock.svg';
import LOGOUT from '@/assets/svgs/icons/logout.svg';
import LOCKED from '@/assets/svgs/icons/locked.svg';
import DOWNLOAD from '@/assets/svgs/icons/download.svg';
import DOWNLOAD_GREY from '@/assets/svgs/icons/download-grey.svg';
import UPLOAD from '@/assets/svgs/icons/upload.svg';
import LOGO from '@/assets/svgs/logo.svg';
import COMMUNITY from '@/assets/svgs/community.svg';
import LB_LOGO from '@/assets/svgs/icons/icon_lb.svg';
import INFO from '@/assets/svgs/icons/info.svg';
import PEN from '@/assets/svgs/icons/pen.svg';
import PEN_WHITE from '@/assets/svgs/icons/pen-white.svg';
import TRASH from '@/assets/svgs/icons/trash.svg';
import TRASH_WHITE from '@/assets/svgs/icons/trash-white.svg';
import TIP from '@/assets/svgs/icons/tip-bubble.svg';
import ARROW_RIGHT from '@/assets/svgs/icons/arrow-right.svg';
import ADD_DOCUMENT from '@/assets/svgs/icons/add-document.svg';
import MORE from '@/assets/svgs/icons/more.svg';
import REMOVE_CIRCLE_GREY from '@/assets/svgs/icons/remove-circle-grey.svg';
import RECYCLE from '@/assets/svgs/icons/recycle.svg';
import ADD_ICON from '@/assets/svgs/icons/add-icon.svg';

export default {
  ICONS: {
    LOGOUT,
    YELLOW_CLOCK,
    CHECK,
    CHECK_NOTE,
    CHECK_NOTE_WHITE,
    CHECK_WHITE,
    ERROR,
    ERROR_WHITE,
    ATTACHMENT,
    NOTEBOOK,
    FOLDER,
    PDF,
    PDF_ALT,
    PDF_ALT_GREY,
    JPG,
    CONTACT,
    COMMENT,
    ENVELOPE,
    PHONE,
    ADMINISTRATOR,
    ADMINISTRATOR_GRAY,
    CLOSE,
    DOWNLOAD,
    DOWNLOAD_GREY,
    UPLOAD,
    LOCKED,
    LB_LOGO,
    INFO,
    PEN,
    PEN_WHITE,
    TRASH,
    TIP,
    ARROW_RIGHT,
    ADD_DOCUMENT,
    TRASH_WHITE,
    MORE,
    CHECK_NOTE_GREY,
    REMOVE_CIRCLE_GREY,
    RECYCLE,
    ADD_ICON
  },
  GENERAL: {},
  LOGO,
  COMMUNITY
};
