var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationCard",
    {
      attrs: {
        type: _vm.type,
        to: `/byten/alla/${_vm.application.id}`,
        status: _vm.application.status,
        subtitle: _vm.subtitle,
        "subtitle-class": _vm.subtitleClass,
        "is-new": _vm.isNew,
        "full-name": _vm.fullName,
        "last-name": _vm.lastName,
        "status-date": _vm.statusDate,
        "unread-notes": _vm.application?.unreadNotes,
        "closed-statuses": _vm.closedStatuses,
        "application-id": _vm.application.id,
        "application-tags": _vm.application.tags,
        "show-tag-button": true,
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "application-card-info" }, [
          _c(
            "ul",
            { staticClass: "info-wrapper" },
            [
              _vm._l(_vm.parts, function (applicant) {
                return _vm._l(applicant, function (location, i) {
                  return _c(
                    "li",
                    { key: `${location.id}-${i}`, staticClass: "address" },
                    [
                      _c("div", { staticClass: "address-text" }, [
                        _c("span", { staticClass: "capitalize-first" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.memberNameOrFallback(location.members[0])
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "light" }, [
                          _vm._v(
                            " " + _vm._s(location.address || "(Adress saknas)")
                          ),
                        ]),
                        _c("p", { staticClass: "landlord" }, [
                          _vm._v(
                            " (" +
                              _vm._s(
                                location.landlord.name.length > 0
                                  ? location.landlord.name
                                  : "Hyresvärd ej angivet"
                              ) +
                              ") "
                          ),
                        ]),
                      ]),
                      location.members[0]?.comments.length
                        ? _c("BaseImage", {
                            staticClass: "has-comment-icon",
                            attrs: {
                              title: `${_vm.memberNameOrFallback(
                                location.members[0]
                              )} har en eller flera kommentarer`,
                              url: _vm.svgs.ICONS.COMMENT,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                })
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("p", [
          _vm._v(" Vill byta: "),
          _c("b", { staticStyle: { "text-transform": "capitalize" } }, [
            _vm._v(_vm._s(_vm.swapDate)),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }