<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Avbryt radering av bytet</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="swap-description">
        Det finns fortfarande tid kvar för att avbryta raderingen av bytet.
      </p>
    </template>

    <template slot="footer">
      <BaseButton :is-rounded="true" :is-dark="true" @click="cancelDeletion">
        Avbryt radering
      </BaseButton>
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Tillbaka</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CancelRemovalModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isSaving: false
    };
  },

  methods: {
    ...mapActions({
      cancelRemoveApp: 'applications/cancelRemoveApp'
    }),
    async cancelDeletion() {
      this.isSaving = true;
      await this.cancelRemoveApp(this.applicationId);
      this.isSaving = false;
      this.$emit('onCancelledDeletion');
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

.abort-btn {
  background-color: var(--status-aborted);
}
</style>
