var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    { attrs: { to: "modal" } },
    [
      _c("transition", { attrs: { name: "shuffle", appear: "" } }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c("div", { staticClass: "overlay", on: { click: _vm.closeModal } }),
          _c(
            "div",
            { ref: "modalElement", staticClass: "modal" },
            [
              _c(
                _vm.component,
                _vm._b(
                  { tag: "component", on: { close: _vm.closeModal } },
                  "component",
                  _vm.$attrs,
                  false
                )
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }