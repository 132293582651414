var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultPage",
    [
      _c("FilterMain", {
        attrs: { value: _vm.filterQuery },
        on: { input: _vm.debouncedSearch, clear: _vm.clearInputSearch },
        model: {
          value: _vm.filterQuery,
          callback: function ($$v) {
            _vm.filterQuery = $$v
          },
          expression: "filterQuery",
        },
      }),
      _c(
        "div",
        { staticClass: "applications-view-wrapper" },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "loading-wrapper" },
                [
                  _c("BaseLoadingSpinner", {
                    attrs: { "with-overlay": false, size: 60 },
                  }),
                ],
                1
              )
            : _c("section", { staticClass: "applications-wrapper" }, [
                _vm.filteredRemovedApps
                  ? _c("div", { staticClass: "lane-wrapper" }, [
                      _c("div", { staticClass: "sub-header" }, [
                        _c("h2", { staticClass: "lane-title" }, [
                          _vm._v(" Arkiverade byten "),
                          _vm.filteredRemovedApps.length > 0
                            ? _c("span", { staticClass: "lane-title-note" }, [
                                _c(
                                  "span",
                                  { staticClass: "lane-title-note-number" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.filteredRemovedApps.length)
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "applications-container" },
                        _vm._l(_vm.filteredRemovedApps, function (application) {
                          return _c(
                            "router-link",
                            {
                              key: application.id,
                              staticClass: "application-wrapper",
                              class: {
                                approved:
                                  application.status ===
                                  _vm.statusTypes.APPROVED,
                                rejected:
                                  application.status ===
                                  _vm.statusTypes.REJECTED,
                                new: _vm.cardIsNew(application),
                              },
                              attrs: { to: `/byten/arkiv/${application.id}` },
                            },
                            [
                              _vm.isFromLb(application)
                                ? _c("BaseImage", {
                                    key: `${application.id}-lb`,
                                    staticClass: "lb-icon",
                                    attrs: { url: _vm.svgs.ICONS.LB_LOGO },
                                  })
                                : _vm._e(),
                              _c("div", [
                                _c("h3", { staticClass: "application-title" }, [
                                  _vm._v(" " + _vm._s(application.type) + " "),
                                ]),
                                _c(
                                  "ul",
                                  { staticClass: "info-wrapper" },
                                  [
                                    _vm._l(
                                      application.parts,
                                      function (applicant) {
                                        return _vm._l(
                                          applicant,
                                          function (location, i) {
                                            return _c(
                                              "li",
                                              {
                                                key: `${location.id}-${i}`,
                                                staticClass: "address",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "address-text",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "light" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              location.address ||
                                                                "(Adress saknas)"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "landlord",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              location.landlord
                                                                .name.length > 0
                                                                ? location
                                                                    .landlord
                                                                    .name
                                                                : "Hyresvärd ej angivet"
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                location.members[0]?.comments
                                                  .length
                                                  ? _c("BaseImage", {
                                                      staticClass:
                                                        "has-comment-icon",
                                                      attrs: {
                                                        title: `${_vm.memberNameOrFallback(
                                                          location.members[0]
                                                        )} har en eller flera kommentarer`,
                                                        url: _vm.svgs.ICONS
                                                          .COMMENT,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "section",
                                { staticClass: "application-footer light" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "application-footer-info" },
                                    [
                                      _vm.getStatusDate(
                                        application.status,
                                        application.statusUpdated,
                                        application.sentToHost
                                      )
                                        ? _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getStatusText(
                                                    application.status
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getStatusDate(
                                                      application.status,
                                                      application.statusUpdated,
                                                      application.sentToHost
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "UserIcon",
                                    {
                                      staticClass: "user-icon",
                                      attrs: {
                                        "first-name": _vm.fullName(application),
                                        "last-name": _vm.lastName(application),
                                        "show-icon":
                                          !_vm.fullName(application) &&
                                          !_vm.lastName(application),
                                      },
                                    },
                                    [
                                      application?.unreadNotes > 0
                                        ? _c("NotificationSymbol")
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
          _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }