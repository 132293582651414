<template>
  <div class="application">
    <slot name="top-content" />
    <div class="application-card" :class="{ 'flex-row': flex }">
      <div class="card-content"><slot /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flex: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.application {
  width: 320px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.application-card {
  width: 100%;
  min-height: 300px;
  background: #f8f8fa;
  padding: 2.2rem;
  border-radius: 10px;
  color: var(--color-dark-blue);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.application-card.flex-row {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(2, 1fr);
}

.card-content {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.card-content:nth-last-child(1) {
  margin-bottom: 0;
}
.card-content > h3 {
  font-weight: 700;
  margin-bottom: 5px;
}
.card-content > p {
  font-weight: 400;
}
</style>
