<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Avbryt byte</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="swap-description">
        Bytet kommer att bli markerat som avbrutet.
      </p>
    </template>

    <template slot="footer">
      <BaseButton
        class="abort-btn"
        :is-loading="isSaving"
        :is-rounded="true"
        :icon="svgs.ICONS.ERROR_WHITE"
        @click="cancelApplication"
      >
        Markera som avbrutet
      </BaseButton>
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions } from 'vuex';
import { events, features } from '@/utils/trackingEvents';
import churnService from '@/utils/churn360';
import svgs from '@/utils/icon-constants';

import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'RejectModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false
    };
  },

  methods: {
    ...mapActions({
      abortApp: 'applications/abortApp'
    }),
    async cancelApplication() {
      this.isSaving = true;
      await this.abortApp({ id: this.applicationId });
      churnService.track(
        events.CHANGE_SWAP_STATUS,
        features.CHANGE_SWAP_STATUS.ABORT
      );
      this.isSaving = false;
      this.$emit('onAborted');
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

.abort-btn {
  background-color: var(--status-aborted);
}
</style>
