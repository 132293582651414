const statusTypes = {
  INCOMING: 'INCOMING',
  READY: 'READY',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  ABORTED: 'ABORTED',
  REMOVED: 'REMOVED',
  PENDING_REMOVE: 'PENDING_REMOVE',
  ONGOING: 'ONGOING',
  RESTORE: 'RESTORE'
};

export default statusTypes;
