<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Återställ byte</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="swap-description">Bytet kommer flyttas till "Fatta beslut"</p>
    </template>

    <template slot="footer">
      <BaseButton
        class="restore-btn"
        :is-loading="isSaving"
        :is-rounded="true"
        :icon="svgs.ICONS.RECYCLE"
        @click="cancelApplication"
      >
        Återställ byte
      </BaseButton>
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions } from 'vuex';
import svgs from '@/utils/icon-constants';

import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'RejectModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false
    };
  },

  methods: {
    ...mapActions({
      restoreApp: 'applications/restoreApp'
    }),
    async cancelApplication() {
      this.isSaving = true;
      await this.restoreApp(this.applicationId);
      this.isSaving = false;
      this.$emit('onRestored');
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

.restore-btn {
  background-color: var(--status-waiting);
}
</style>
