var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { ref: "container", staticClass: "tags" },
    [
      _c(
        "transition-group",
        { attrs: { name: "shuffle", appear: "", tag: "li" } },
        _vm._l(_vm.tags, function (tagId) {
          return _c("Tag", { key: tagId, attrs: { "tag-id": tagId } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }