import Vue from 'vue';
import Router from 'vue-router';
import PortalVue from 'portal-vue';
import ApplicationsView from '../views/ApplicationsView.vue';
import ApplicationModal from '../components/ApplicationModal.vue';
import ApplicationView from '../views/ApplicationView.vue';
import ArchiveView from '../views/ArchiveView.vue';
import SubletApplicationsView from '../views/sublet/SubletApplicationsView.vue';
import SubletApplicationView from '../views/sublet/SubletApplicationView/SubletApplicationView.vue';
import SubletArchive from '../views/sublet/SubletArchive.vue';
import churnService from '@/utils/churn360';
import LogoutView from '@/views/LogoutView.vue';
import StatisticsView from '../views/StatisticsView.vue';
import SettingsView from '../views/SettingsView.vue';
import SupportView from '../views/SupportView.vue';
import NotFound from '../views/NotFound.vue';

import { getLocalStorage, getQueryParams } from '@/utils/windowUtils';
import { APP_NAMES, APP_PATHS } from '@/utils/constants';

Vue.use(PortalVue);
Vue.use(Router);

const routes = [
  {
    path: '/logga-ut',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/byten/arkiv',
    name: 'archive',
    component: ArchiveView,
    meta: {
      baRoute: true
    },
    children: [
      {
        path: ':id',
        component: ApplicationModal,
        props: {
          component: ApplicationView
        }
      }
    ]
  },
  {
    path: '/byten/alla',
    component: ApplicationsView,
    meta: {
      baRoute: true
    },
    children: [
      {
        path: ':id',
        component: ApplicationModal,
        props: {
          component: ApplicationView
        }
      }
    ]
  },
  {
    path: '/byten/',
    redirect: '/byten/alla',
    meta: {
      baRoute: true
    }
  },
  {
    path: '/andrahand/',
    redirect: '/andrahand/alla',
    meta: {
      ahRoute: true
    }
  },
  {
    path: '/andrahand/alla',
    component: SubletApplicationsView,
    meta: {
      ahRoute: true
    },
    children: [
      {
        path: ':id',
        component: ApplicationModal,
        meta: {
          ahRoute: true
        },
        props: {
          component: SubletApplicationView
        }
      }
    ]
  },
  {
    path: '/andrahand/arkiv',
    name: 'subletArchive',
    component: SubletArchive,
    meta: {
      ahRoute: true
    },
    children: [
      {
        path: ':id',
        component: ApplicationModal,
        meta: {
          ahRoute: true
        },
        props: {
          component: SubletApplicationView
        }
      }
    ]
  },
  {
    path: '/statistik',
    name: 'statistics',
    component: StatisticsView,
    meta: {
      baRoute: true
    }
  },
  {
    path: '/installningar',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/support',
    name: 'support',
    component: SupportView
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes
});

function getNextPath(app, applicationId) {
  if (applicationId) {
    return `/${app}/alla/${applicationId}`;
  } else {
    return `/${app}/alla`;
  }
}

function getAppPath(appName) {
  switch (appName) {
    case APP_NAMES.BYTESANSOKAN:
      return APP_PATHS.BYTESANSOKAN;
    case APP_NAMES.ANDRAHAND:
      return APP_PATHS.ANDRAHAND;
    default:
      return '';
  }
}

router.beforeEach(async (to, from, next) => {
  churnService.page({
    domain: window.location.host,
    environment: process.env.NODE_ENV
  });

  if (to.path === '/') {
    const LAST_ACTIVE_APP =
      getLocalStorage('BA_ACTIVE_APP') || APP_NAMES.BYTESANSOKAN;
    const { applicationId, app } = getQueryParams();

    if (app === APP_NAMES.ANDRAHAND || app === APP_NAMES.BYTESANSOKAN) {
      // Start checking if params contain ref to app
      const path = getAppPath(app);
      next(getNextPath(path, applicationId));
    } else if (
      LAST_ACTIVE_APP === APP_NAMES.ANDRAHAND ||
      LAST_ACTIVE_APP === APP_NAMES.BYTESANSOKAN
    ) {
      // Otherwise go to last activeApp
      const path = getAppPath(LAST_ACTIVE_APP);
      next(getNextPath(path, applicationId));
    } else {
      next();
    }
  } else {
    next();
  }
});

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
