<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ta bort fil</DefaultModalTitle>
    </template>

    <template slot="body">
      <p v-if="isUploader" class="swap-description">
        Vill du radera filen <b>{{ fileName }}</b
        >?
      </p>
      <p v-else class="swap-description">
        Filen <b>{{ fileName }}</b> laddades upp av en annan handläggare, vill
        du radera den ändå?
      </p>
    </template>

    <template slot="footer">
      <BaseButton
        :is-rounded="true"
        :is-warning="true"
        @click="$emit('confirm')"
      >
        Radera
      </BaseButton>
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },
  props: {
    fileName: {
      type: String,
      default: ''
    },
    isUploader: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
}

.swap-description b {
  color: var(--color-dark-blue);
}
</style>
