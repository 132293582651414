<template>
  <DefaultModal
    :skip-header-underline="true"
    :footer-shadow="activeTab !== 'LOG'"
    close-btn-color="#fff"
    @close="$emit('close')"
  >
    <template slot="header">
      <TabHeader :tabs="tabList" @tabClick="handleTabClick" />
    </template>

    <template slot="body">
      <div ref="bodyWrapper" class="body-wrapper">
        <div v-if="isLoadingData" class="is-fetching-container container">
          <div class="spinner"><BaseSpinner /></div>
        </div>
        <div
          v-else-if="!isLoadingData && showError"
          class="is-fetching-container container"
        >
          <div class="error-container">
            <p>Något gick fel...</p>
            <BaseButton
              :is-rounded="true"
              type="button"
              @click.stop="fetchCurrentTab"
              >Försök igen</BaseButton
            >
          </div>
        </div>
        <template v-else>
          <template v-if="activeTab === 'GENERAL'">
            <DeleteNoteModal
              v-if="showDeleteNote"
              :note-id="noteToDeleteId"
              :application-id="applicationId"
              @close="showDeleteNote = false"
              @onDeleteSuccess="handleDeleteNoteCompleted"
            ></DeleteNoteModal>
            <EditNoteModal
              v-if="activeEditNote"
              :original-note="activeEditNote.text"
              :note-id="activeEditNote.id"
              :application-id="applicationId"
              @close="activeEditNote = null"
              @onEditSuccess="handleUpdateNote"
            ></EditNoteModal>
          </template>
          <section
            v-if="activeTab === 'LOG'"
            class="swap-log-wrapper container"
          >
            <article
              v-for="article of log"
              :key="`${article.date}-${article.text}-${article.type}`"
            >
              <h5>
                {{ new Date(article.date).toLocaleDateString() }}
                {{
                  new Date(article.date).toLocaleTimeString('sv-SE', {
                    hour: 'numeric',
                    minute: 'numeric'
                  })
                }}
              </h5>
              <p>{{ getDescription(article) }}</p>
            </article>
          </section>
          <section
            v-else-if="activeTab === 'GENERAL' || activeTab === 'CHAT'"
            id="scroll-container"
            class="note-wrapper container"
          >
            <section>
              <article
                v-for="note in notes"
                :key="note.id"
                :class="{ note: true, deleted: note.deletedAt }"
              >
                <section class="title">
                  <UserIcon
                    :first-name="note.firstName"
                    :last-name="note.lastName"
                    class="mr-10"
                  ></UserIcon>
                  <h2 class="title-name">
                    {{ note.firstName }} {{ note.lastName }}
                    <span class="title-text"
                      >{{ note.landlordName }} ({{ getNoteDate(note) }})</span
                    >
                    <span
                      v-if="note.editedAt && !note.deletedAt"
                      class="note-edited-msg"
                      >(Redigerad)</span
                    >
                    <template
                      v-if="
                        !readOnlyMode &&
                        activeTab === 'GENERAL' &&
                        showNoteBtnsFor(note)
                      "
                    >
                      <div v-if="!note.deletedAt" class="btns-container">
                        <button
                          class="edit-note-btn note-btn"
                          type="button"
                          @click="activeEditNote = note"
                        >
                          <BaseImage :url="svgs.ICONS.PEN" />
                        </button>
                        <button
                          class="delete-note-btn note-btn"
                          type="button"
                          @click="
                            showDeleteNote = true;
                            noteToDeleteId = note.id;
                          "
                        >
                          <BaseImage :url="svgs.ICONS.TRASH" />
                        </button>
                      </div>
                    </template>
                  </h2>
                </section>

                <p class="note-text">
                  <span v-if="note.deletedAt" class="note-deleted-msg">
                    <i>Borttagen</i>
                  </span>
                  <span v-else>
                    {{ note.text }}
                  </span>
                </p>
              </article>
            </section>
          </section>
        </template>
      </div>
    </template>

    <template slot="footer">
      <div class="footer-container" :class="{ 'text-options': isSkandia }">
        <div class="textarea-margin-helper">
          <transition
            :name="!readOnlyMode ? 'textarea-toggle' : ''"
            appear
            mode="in-out"
          >
            <section
              v-if="activeTab !== 'LOG' && !isSkandia"
              class="footer-textarea"
            >
              <textarea
                v-if="!readOnlyMode"
                class="area"
                :value="newNote"
                :placeholder="dynamicPlaceholderText"
                cols="30"
                rows="5"
                :disabled="isSaving || isLoadingData || showError"
                @input="newNote = $event.target.value"
              ></textarea>
              <p v-if="activeTab === 'CHAT'" class="info-text">
                OBS! Endast anslutna hyresvärdar får ta del av denna chatt.
              </p>
            </section>
          </transition>
          <transition
            :name="!readOnlyMode ? 'textarea-toggle' : ''"
            appear
            mode="in-out"
          >
            <section
              v-if="activeTab !== 'LOG' && isSkandia"
              class="chat-options-container"
            >
              <div class="text-info-container">
                <h3>Välj ett alternativ för att börja chatta:</h3>
                <p v-if="activeTab === 'CHAT' && isSkandia">
                  <i
                    >OBS! Endast anslutna hyresvärdar får ta del av denna
                    chatt.</i
                  >
                </p>
              </div>
              <div
                v-for="opt in skandiaChatOptionsList"
                :key="opt.id"
                class="chat-options"
              >
                <button class="chat-options-btn" @click="selectChatOption(opt)">
                  <p class="chat-option">{{ opt.text }}</p>
                </button>
              </div>
              <OptionsModal
                v-if="chatOptionModalVisible"
                :option-to-send="newNote"
                :is-chat="true"
                @send="sendChatOption"
                @close="chatOptionModalVisible = false"
              />
            </section>
          </transition>
        </div>
        <section v-if="!isSkandia" class="footer-buttons">
          <BaseButton
            v-if="!readOnlyMode && activeTab !== 'LOG'"
            :is-loading="isSaving"
            :is-rounded="true"
            :is-disabled="disabledSubmitBtn"
            :icon="svgs.ICONS.CHECK_WHITE"
            @click="handleAddNote"
            >Skicka</BaseButton
          >
          <BaseButton
            :is-disabled="isSaving"
            :is-rounded="true"
            :is-default="true"
            @click="$emit('close')"
            >Stäng</BaseButton
          >
        </section>
      </div>
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import OptionsModal from '@/components/OptionsModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import TabHeader from '@/components/TabHeader.vue';
import UserIcon from '@/components/UserIcon.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import BaseImage from '@/components/BaseImage.vue';
import DeleteNoteModal from '@/components/DeleteNoteModal.vue';
import EditNoteModal from '@/components/EditNoteModal.vue';
import statusReason from '@/utils/statusReason';
import { mapState, mapGetters } from 'vuex';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';

export default {
  name: 'NotesModal',
  components: {
    UserIcon,
    DefaultModal,
    OptionsModal,
    BaseButton,
    TabHeader,
    BaseSpinner,
    BaseImage,
    DeleteNoteModal,
    EditNoteModal
  },

  props: {
    applicationId: {
      type: String,
      default: null
    },
    unreadNotes: {
      type: Object,
      default: null
    },
    readOnlyMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      notes: null,
      newNote: '',
      log: [],
      activeTab: 'GENERAL',
      isLoadingData: false,
      showError: false,
      showDeleteNote: false,
      noteToDeleteId: null,
      activeEditNote: null,
      chatOptionModalVisible: false
    };
  },

  computed: {
    ...mapGetters({
      isSkandia: 'app/isSkandia'
    }),
    disabledSubmitBtn() {
      if (this.isLoadingData || this.showError) {
        return true;
      }
      if (!this.newNote || this.newNote.length < 2) {
        return true;
      }
      return false;
    },
    currentNoteType() {
      if (this.activeTab === 'CHAT') {
        return 'CHAT';
      } else if (this.activeTab === 'GENERAL') {
        return 'GENERAL';
      } else {
        return '';
      }
    },

    ...mapState('app', ['account']),
    landlordId() {
      return this.account.landlordId;
    },
    disableInternalNotes() {
      if (this.isSkandia) {
        return true;
      }
      return !this.applicationId && !this.landlordId;
    },
    disableAllNotes() {
      return !this.applicationId;
    },
    disableSwapLog() {
      return false;
    },
    tabList() {
      return [
        {
          title: 'Interna noteringar',
          active: this.activeTab === 'GENERAL',
          disabled: this.disableInternalNotes,
          hidden: this.isSkandia,
          showNotification: this.unreadNotes?.GENERAL > 0,
          tabLink: 'GENERAL'
        },
        {
          title: 'Chatt hyresvärd',
          active: this.activeTab === 'CHAT',
          disabled: this.disableAllNotes,
          showNotification: this.unreadNotes?.CHAT > 0,
          tabLink: 'CHAT'
        },
        {
          title: 'Bytets logg',
          active: this.activeTab === 'LOG',
          disabled: this.disableSwapLog,
          tabLink: 'LOG'
        }
      ];
    },
    skandiaChatOptionsList() {
      return [
        { text: 'Vi har ett gemensamt byte – kontakta mig.', id: 1 },
        {
          text: 'Vi behöver komma i kontakt med er gällande vårt gemensamma byte',
          id: 2
        }
      ];
    },
    dynamicPlaceholderText() {
      if (this.activeTab === 'CHAT') {
        return 'Skriv ett nytt meddelande här';
      } else if (this.activeTab === 'GENERAL') {
        return 'Skriv en ny notering här';
      } else return '';
    }
  },

  watch: {
    activeTab: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.fetchCurrentTab();
          this.$emit('activeTabChange', newVal);
        }
      },
      immediate: true
    }
  },

  async created() {
    if (this.isSkandia) {
      this.activeTab = 'CHAT';
    }
    await this.fetchCurrentTab();
  },

  methods: {
    getDescription({ firstName, landlordName, lastName, text, type }) {
      const resObj = statusReason(
        {
          firstName,
          landlordName,
          lastName,
          text,
          type
        },
        true
      );
      if (resObj.name) {
        return `${resObj.name} ${resObj.text}`;
      } else {
        return resObj.text;
      }
    },
    handleUpdateNote(note) {
      const noteIndex = this.notes.findIndex(
        note => note.id === this.activeEditNote.id
      );
      if (noteIndex !== -1) {
        this.$set(this.notes, noteIndex, {
          ...this.notes[noteIndex],
          text: note,
          editedAt: true
        });
      }
      this.activeEditNote = null;
    },
    handleDeleteNoteCompleted() {
      const noteIndex = this.notes.findIndex(
        note => note.id === this.noteToDeleteId
      );
      if (noteIndex !== -1) {
        this.$set(this.notes, noteIndex, {
          ...this.notes[noteIndex],
          deletedAt: true
        });
      }
      this.showDeleteNote = false;
    },
    showNoteBtnsFor(note) {
      return note.userId === this.account.userId;
    },
    async fetchCurrentTab() {
      this.showError = false;
      this.isLoadingData = true;
      this.clearCurrentData();
      try {
        if (this.activeTab === 'CHAT') {
          this.notes = await this.$http.getNotes(this.applicationId, ['CHAT']);
        } else if (this.activeTab === 'GENERAL') {
          this.notes = await this.$http.getNotes(this.applicationId, [
            'GENERAL'
          ]);
        } else if (this.activeTab === 'LOG') {
          this.log = await this.$http.getApplicationLog(this.applicationId);
        }
      } catch {
        this.showError = true;
      }
      this.isLoadingData = false;

      this.scrollToLatestNote();
    },
    getNoteDate(note) {
      const dateStr = new Date(note.updatedAt).toLocaleDateString('sv-SE');
      const timeStr = new Date(note.updatedAt).toLocaleTimeString('sv-SE');
      const hhmm = timeStr.substring(0, 5);
      return `${dateStr} ${hhmm}`;
    },
    async handleAddNote() {
      this.isSaving = true;

      const data = await this.$http.saveNote(
        this.applicationId,
        this.currentNoteType,
        this.newNote
      );

      // No need to await tracking
      if (this.activeTab === 'GENERAL') {
        churnService.track(events.SENT_MESSAGE, features.SENT_MESSAGE.GENERAL);
      }
      if (this.activeTab === 'CHAT') {
        churnService.track(events.SENT_MESSAGE, features.SENT_MESSAGE.CHAT);
      }

      this.newNote = '';
      this.notes.push(data);
      this.isSaving = false;

      this.scrollToLatestNote();
    },
    async selectChatOption(opt) {
      this.newNote = opt.text;
      this.chatOptionModalVisible = true;
    },
    async sendChatOption() {
      await this.handleAddNote();
      this.chatOptionModalVisible = false;
    },
    handleTabClick(tabId) {
      this.activeTab = tabId;
    },
    clearCurrentData() {
      this.notes = null;
      this.log = [];
      this.newNote = '';
    },
    scrollToLatestNote() {
      this.$nextTick(() => {
        const scrollWrapper = this.$refs.bodyWrapper?.parentElement;
        if (scrollWrapper) {
          scrollWrapper.scrollTo(0, scrollWrapper.scrollHeight);
        }
      });
    }
  }
};
</script>

<style scoped>
.footer-textarea {
  overflow: hidden;
}

.textarea-margin-helper {
  padding-top: 2.5rem;
}

.area {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-inter);
}
.title {
  display: inline-flex;
  align-content: center;
  align-items: center;
  font-size: 12px;
}

.title-name {
  font-weight: 700;
  font-size: 12px;
  color: var(--color-dark-blue);
  margin-top: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-text {
  margin-left: 5px;
  font-weight: 400;
  color: #878b9d;
}

.note-text {
  margin-top: 0.8rem;
  color: var(--color-dark-blue);
  font-weight: 400;
  font-size: 12px;
  font-family: var(--font-inter);
}

.note-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.note {
  margin-bottom: 30px;
}
.deleted {
  opacity: 0.5;
}

.info-text {
  position: absolute;
}

.container {
  width: 44.8rem;
  max-width: 100%;
  margin: 0 auto;
  height: 40vh;
}

.swap-log-wrapper article {
  margin-bottom: 0.8rem;
  font-family: var(--font-inter);
}
.swap-log-wrapper article:nth-last-child(1) {
  margin-bottom: 0;
}
.swap-log-wrapper h5,
.swap-log-wrapper p {
  margin: 0;
  font-weight: 400;
  color: var(--color-dark-blue);
}
.swap-log-wrapper h5 {
  font-size: 1.2rem;
}
.swap-log-wrapper p {
  font-size: 1.2rem;
}

.mr-10 {
  margin-right: 10px;
}

.footer-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.chat-options-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.chat-options-container .text-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.chat-options-container .chat-options-btn {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  min-width: 100%;
  max-width: 100%;
}
.chat-options-container .chat-options-btn .chat-option {
  font-size: 1.2rem;
  background-color: #fff;
  color: var(--color-grey);
  padding: 1.2rem;
  transition: transform 0.2s ease;
  border-radius: 1rem;
  border: 0.2rem solid var(--color-light-grey);
}
.chat-options-container .chat-options-btn .chat-option:hover {
  transform: scale(1.05);
  color: var(--color-dark-blue);
}

.footer-buttons {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 15px;
}

.body-wrapper {
  position: relative;
}

.is-fetching-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 5rem;
  width: 5rem;
}

#spinner {
  border-top-color: var(--color-blue);
}

.error-container {
  text-align: center;
}
.error-container p {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}
.error-container button {
  font-size: 1.8rem;
}

.note-edited-msg {
  color: #40c07b;
  margin-left: 3px;
  font-weight: normal;
}

.note-deleted-msg {
  font-size: 1rem;
}

.btns-container {
  display: inline-flex;
  justify-self: center;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
  height: 100%;
}

.note-btn {
  height: 1.2rem;
  width: 1.2rem;
  padding: 0;
  border: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
}
.note-btn > * {
  height: 100%;
  width: 100%;
}
</style>
