var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      attrs: {
        "skip-header-underline": true,
        "footer-shadow": _vm.activeTab !== "LOG",
        "close-btn-color": "#fff",
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("TabHeader", {
            attrs: { tabs: _vm.tabList },
            on: { tabClick: _vm.handleTabClick },
          }),
        ],
        1
      ),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { ref: "bodyWrapper", staticClass: "body-wrapper" },
          [
            _vm.isLoadingData
              ? _c("div", { staticClass: "is-fetching-container container" }, [
                  _c("div", { staticClass: "spinner" }, [_c("BaseSpinner")], 1),
                ])
              : !_vm.isLoadingData && _vm.showError
              ? _c("div", { staticClass: "is-fetching-container container" }, [
                  _c(
                    "div",
                    { staticClass: "error-container" },
                    [
                      _c("p", [_vm._v("Något gick fel...")]),
                      _c(
                        "BaseButton",
                        {
                          attrs: { "is-rounded": true, type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.fetchCurrentTab.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Försök igen")]
                      ),
                    ],
                    1
                  ),
                ])
              : [
                  _vm.activeTab === "GENERAL"
                    ? [
                        _vm.showDeleteNote
                          ? _c("DeleteNoteModal", {
                              attrs: {
                                "note-id": _vm.noteToDeleteId,
                                "application-id": _vm.applicationId,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.showDeleteNote = false
                                },
                                onDeleteSuccess: _vm.handleDeleteNoteCompleted,
                              },
                            })
                          : _vm._e(),
                        _vm.activeEditNote
                          ? _c("EditNoteModal", {
                              attrs: {
                                "original-note": _vm.activeEditNote.text,
                                "note-id": _vm.activeEditNote.id,
                                "application-id": _vm.applicationId,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.activeEditNote = null
                                },
                                onEditSuccess: _vm.handleUpdateNote,
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.activeTab === "LOG"
                    ? _c(
                        "section",
                        { staticClass: "swap-log-wrapper container" },
                        _vm._l(_vm.log, function (article) {
                          return _c(
                            "article",
                            {
                              key: `${article.date}-${article.text}-${article.type}`,
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      new Date(
                                        article.date
                                      ).toLocaleDateString()
                                    ) +
                                    " " +
                                    _vm._s(
                                      new Date(article.date).toLocaleTimeString(
                                        "sv-SE",
                                        { hour: "numeric", minute: "numeric" }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.getDescription(article))),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm.activeTab === "GENERAL" || _vm.activeTab === "CHAT"
                    ? _c(
                        "section",
                        {
                          staticClass: "note-wrapper container",
                          attrs: { id: "scroll-container" },
                        },
                        [
                          _c(
                            "section",
                            _vm._l(_vm.notes, function (note) {
                              return _c(
                                "article",
                                {
                                  key: note.id,
                                  class: {
                                    note: true,
                                    deleted: note.deletedAt,
                                  },
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "title" },
                                    [
                                      _c("UserIcon", {
                                        staticClass: "mr-10",
                                        attrs: {
                                          "first-name": note.firstName,
                                          "last-name": note.lastName,
                                        },
                                      }),
                                      _c(
                                        "h2",
                                        { staticClass: "title-name" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(note.firstName) +
                                              " " +
                                              _vm._s(note.lastName) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "title-text" },
                                            [
                                              _vm._v(
                                                _vm._s(note.landlordName) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.getNoteDate(note)
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          note.editedAt && !note.deletedAt
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "note-edited-msg",
                                                },
                                                [_vm._v("(Redigerad)")]
                                              )
                                            : _vm._e(),
                                          !_vm.readOnlyMode &&
                                          _vm.activeTab === "GENERAL" &&
                                          _vm.showNoteBtnsFor(note)
                                            ? [
                                                !note.deletedAt
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "btns-container",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "edit-note-btn note-btn",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.activeEditNote =
                                                                  note
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("BaseImage", {
                                                              attrs: {
                                                                url: _vm.svgs
                                                                  .ICONS.PEN,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "delete-note-btn note-btn",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.showDeleteNote = true
                                                                _vm.noteToDeleteId =
                                                                  note.id
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("BaseImage", {
                                                              attrs: {
                                                                url: _vm.svgs
                                                                  .ICONS.TRASH,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", { staticClass: "note-text" }, [
                                    note.deletedAt
                                      ? _c(
                                          "span",
                                          { staticClass: "note-deleted-msg" },
                                          [_c("i", [_vm._v("Borttagen")])]
                                        )
                                      : _c("span", [
                                          _vm._v(" " + _vm._s(note.text) + " "),
                                        ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "footer-container",
            class: { "text-options": _vm.isSkandia },
          },
          [
            _c(
              "div",
              { staticClass: "textarea-margin-helper" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: !_vm.readOnlyMode ? "textarea-toggle" : "",
                      appear: "",
                      mode: "in-out",
                    },
                  },
                  [
                    _vm.activeTab !== "LOG" && !_vm.isSkandia
                      ? _c("section", { staticClass: "footer-textarea" }, [
                          !_vm.readOnlyMode
                            ? _c("textarea", {
                                staticClass: "area",
                                attrs: {
                                  placeholder: _vm.dynamicPlaceholderText,
                                  cols: "30",
                                  rows: "5",
                                  disabled:
                                    _vm.isSaving ||
                                    _vm.isLoadingData ||
                                    _vm.showError,
                                },
                                domProps: { value: _vm.newNote },
                                on: {
                                  input: function ($event) {
                                    _vm.newNote = $event.target.value
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.activeTab === "CHAT"
                            ? _c("p", { staticClass: "info-text" }, [
                                _vm._v(
                                  " OBS! Endast anslutna hyresvärdar får ta del av denna chatt. "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: !_vm.readOnlyMode ? "textarea-toggle" : "",
                      appear: "",
                      mode: "in-out",
                    },
                  },
                  [
                    _vm.activeTab !== "LOG" && _vm.isSkandia
                      ? _c(
                          "section",
                          { staticClass: "chat-options-container" },
                          [
                            _c("div", { staticClass: "text-info-container" }, [
                              _c("h3", [
                                _vm._v(
                                  "Välj ett alternativ för att börja chatta:"
                                ),
                              ]),
                              _vm.activeTab === "CHAT" && _vm.isSkandia
                                ? _c("p", [
                                    _c("i", [
                                      _vm._v(
                                        "OBS! Endast anslutna hyresvärdar får ta del av denna chatt."
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._l(_vm.skandiaChatOptionsList, function (opt) {
                              return _c(
                                "div",
                                { key: opt.id, staticClass: "chat-options" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "chat-options-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectChatOption(opt)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "chat-option" }, [
                                        _vm._v(_vm._s(opt.text)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm.chatOptionModalVisible
                              ? _c("OptionsModal", {
                                  attrs: {
                                    "option-to-send": _vm.newNote,
                                    "is-chat": true,
                                  },
                                  on: {
                                    send: _vm.sendChatOption,
                                    close: function ($event) {
                                      _vm.chatOptionModalVisible = false
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            !_vm.isSkandia
              ? _c(
                  "section",
                  { staticClass: "footer-buttons" },
                  [
                    !_vm.readOnlyMode && _vm.activeTab !== "LOG"
                      ? _c(
                          "BaseButton",
                          {
                            attrs: {
                              "is-loading": _vm.isSaving,
                              "is-rounded": true,
                              "is-disabled": _vm.disabledSubmitBtn,
                              icon: _vm.svgs.ICONS.CHECK_WHITE,
                            },
                            on: { click: _vm.handleAddNote },
                          },
                          [_vm._v("Skicka")]
                        )
                      : _vm._e(),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          "is-disabled": _vm.isSaving,
                          "is-rounded": true,
                          "is-default": true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v("Stäng")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }