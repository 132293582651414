var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      staticClass: "options-modal-wrapper",
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "body" }, [
        _vm.isChat
          ? _c("div", { staticClass: "body-wrapper" }, [
              _c("h2", [_vm._v("Vill du skicka:")]),
              _c("h3", [
                _vm._v("'" + _vm._s(_vm.optionToSend) + "' "),
                _c("span", { staticClass: "light-text" }, [
                  _vm._v("i chatten?"),
                ]),
              ]),
            ])
          : _vm.isRejectReason
          ? _c("div", { staticClass: "body-wrapper" }, [
              _c("h2", [_vm._v("Vill du välja:")]),
              _c("h3", [
                _vm._v(" '" + _vm._s(_vm.optionToSend) + "' "),
                _c("span", { staticClass: "light-text" }, [
                  _vm._v(" för att neka bytet?"),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isChat
            ? _c(
                "BaseButton",
                {
                  attrs: {
                    "is-rounded": true,
                    icon: _vm.svgs.ICONS.CHECK_WHITE,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("send")
                    },
                  },
                },
                [_vm._v("Skicka")]
              )
            : _vm.isRejectReason
            ? _c(
                "BaseButton",
                {
                  attrs: {
                    "is-rounded": true,
                    "is-warning": true,
                    icon: _vm.svgs.ICONS.ERROR_WHITE,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("send")
                    },
                  },
                },
                [_vm._v(" Neka byte ")]
              )
            : _vm._e(),
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Stäng")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }