var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "splash-container" },
    [
      _vm.entraStatus >= 400 && 499 >= _vm.entraStatus
        ? _c("ErrorModal", {
            attrs: { message: _vm.entraStatusMessage },
            on: { close: _vm.closeEntraModal },
          })
        : _vm._e(),
      _c("div", { staticClass: "logo-wrapper" }, [
        _c("img", {
          staticClass: "app-title flex-center",
          attrs: { src: _vm.svgs.LOGO },
        }),
      ]),
      _c("div", { staticClass: "banner" }, [
        _vm._m(0),
        _c("img", {
          staticClass: "splash-image",
          attrs: { src: _vm.svgs.COMMUNITY },
        }),
      ]),
      _c(
        "form",
        [
          !_vm.resetPasswordVisible
            ? _c("div", { staticClass: "login-box" }, [
                _c("div", { staticClass: "login-box-inner" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.loginLocal({
                            username: _vm.username,
                            password: _vm.password,
                          })
                        },
                      },
                    },
                    [
                      _c("h4", [_vm._v("Logga in")]),
                      _c(
                        "div",
                        { staticClass: "login-wrapper" },
                        [
                          _c("DefaultInput", {
                            attrs: {
                              name: "username",
                              disabled: _vm.isLoading,
                              placeholder: "Användarnamn",
                              type: "text",
                              autofocus: "",
                              autocomplete: "username",
                            },
                            on: { clear: _vm.clearUsername },
                            model: {
                              value: _vm.username,
                              callback: function ($$v) {
                                _vm.username = $$v
                              },
                              expression: "username",
                            },
                          }),
                          _c("DefaultInput", {
                            ref: "passwordInput",
                            attrs: {
                              name: "password",
                              disabled: _vm.isLoading,
                              placeholder: "Lösenord",
                              type: "password",
                              autocomplete: "password",
                            },
                            on: { clear: _vm.clearPassword },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "msg" }, [
                        _vm.showErrorMessage
                          ? _c("span", { staticClass: "error-msg" }, [
                              _vm._v("Fel användarnamn och/eller lösenord"),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "login-button" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                type: "submit",
                                "is-loading": _vm.isLoading,
                                "is-disabled": _vm.isLoading || !_vm.username,
                                "is-fullwidth": false,
                                "is-rounded": true,
                              },
                            },
                            [_vm._v("Logga in")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("p", [
                    _c(
                      "button",
                      {
                        staticClass: "simple-button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.resetPasswordVisible = true
                          },
                        },
                      },
                      [_vm._v(" Glömt lösenordet? ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "entra-box" }, [
                  _c(
                    "a",
                    {
                      staticClass: "entra-login",
                      class: {
                        disabled: _vm.entraIsLoading,
                      },
                      attrs: { href: _vm.entraSignInUrl },
                      on: {
                        click: function ($event) {
                          _vm.entraIsLoading = true
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "entra-login-inner" }, [
                        _vm._v(
                          _vm._s(
                            _vm.entraIsLoading
                              ? "Loggar in..."
                              : "Logga in med Entra"
                          ) + " "
                        ),
                        _vm.entraIsLoading
                          ? _c(
                              "span",
                              { staticClass: "spinner" },
                              [_c("BaseSpinner")],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _c(
                "DefaultModal",
                {
                  attrs: { "blur-bg": true },
                  on: {
                    close: function ($event) {
                      _vm.resetPasswordVisible = false
                    },
                  },
                },
                [
                  _c("template", { slot: "body" }, [
                    _vm.emailSent
                      ? _c("div", [
                          _c("h4", [_vm._v("Skickat!")]),
                          _c("p", [
                            _vm._v(
                              "Vänligen titta efter ett återställnings e-mail i din inbox."
                            ),
                          ]),
                        ])
                      : _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.requestPasswordReset({
                                  username: _vm.username,
                                })
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v("Återställ lösenord")]),
                            _c("p", [
                              _vm._v(
                                " Ett e-mail skickas till din e-postadress med instruktioner på hur du återställer ditt lösenord. "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "login-wrapper" },
                              [
                                _c("DefaultInput", {
                                  attrs: {
                                    name: "username",
                                    disabled: _vm.isLoading,
                                    placeholder: "E-postadress",
                                    type: "text",
                                    autofocus: "",
                                  },
                                  on: { clear: _vm.clearUsername },
                                  model: {
                                    value: _vm.username,
                                    callback: function ($$v) {
                                      _vm.username = $$v
                                    },
                                    expression: "username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("p", { staticClass: "msg" }, [
                              _vm.resetPasswordErrorVisible
                                ? _c("span", { staticClass: "error-msg" }, [
                                    _vm._v(
                                      "Det gick inte att återställa lösenordet för användaren."
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "login-button" },
                              [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      type: "submit",
                                      "is-loading": _vm.isLoading,
                                      "is-disabled":
                                        _vm.isLoading || !_vm.username,
                                      "is-fullwidth": false,
                                      "is-rounded": true,
                                    },
                                  },
                                  [_vm._v("Återställ lösenord")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                    _c("p", [
                      _c(
                        "button",
                        {
                          staticClass: "simple-button link",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.resetPasswordVisible = false
                            },
                          },
                        },
                        [_vm._v(" Tillbaka till inloggning ")]
                      ),
                    ]),
                  ]),
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h1", [_vm._v("Portal")]),
      _c("p", [
        _vm._v(
          " Bytesansökan förenklar bytesprocessen för dig som hyresvärd och alla bytesparter. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Vi levererar ett komplett samt lättöverskådligt beslutsunderlag för att du som handläggare tryggt ska kunna ta ställning till ett byte. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }