var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-help-container" },
    [
      _c(
        "div",
        { staticClass: "main-filter-wrapper" },
        [
          _c("input", {
            class: ["main-filter", _vm.showClearBtn ? "active" : ""],
            attrs: { type: "text", placeholder: _vm.placeholder },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          }),
          _c("transition", { attrs: { name: "closeBtn-fade" } }, [
            _vm.showClearBtn
              ? _c(
                  "button",
                  {
                    key: "1",
                    staticClass: "close-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("clear", $event)
                      },
                    },
                  },
                  [
                    _c("BaseImage", {
                      staticClass: "close-icon",
                      attrs: { url: _vm.svgs.ICONS.CLOSE },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }