var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.errorModalVisible
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _c(
        "DefaultModal",
        {
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("DefaultModalTitle", [_vm._v("Ändra notis")])],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("textarea", {
              staticClass: "textarea",
              attrs: { disabled: _vm.isLoading, cols: "30", rows: "5" },
              domProps: { value: _vm.note },
              on: {
                input: function ($event) {
                  _vm.note = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    "is-rounded": true,
                    icon: _vm.svgs.ICONS.CHECK_WHITE,
                    "is-disabled": _vm.note.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateNote(
                        _vm.applicationId,
                        _vm.note,
                        _vm.noteId
                      )
                    },
                  },
                },
                [_vm._v(" Uppdatera ")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-disabled": _vm.isLoading,
                    "is-rounded": true,
                    "is-default": true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Avbryt")]
              ),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }