var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "green-checkbox" }, [
    _c(
      "input",
      _vm._b(
        {
          staticClass: "no-pointer-events",
          attrs: {
            id: _vm.id,
            name: _vm.id,
            type: "checkbox",
            disabled: _vm.isDisabled,
          },
          domProps: { checked: _vm.checked },
          on: {
            change: function ($event) {
              return _vm.$emit("change", $event)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _c("label", { attrs: { id: "checkbox", for: _vm.id } }, [
      _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
        _c("path", {
          staticClass: "box",
          attrs: {
            d: "M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z",
          },
        }),
        _c("polyline", {
          staticClass: "check",
          attrs: { points: "25.5,53.5 39.5,67.5 72.5,34.5 " },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }