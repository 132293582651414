<template>
  <BaseLoadingSpinner v-if="loading" :with-overlay="false" :size="60" />
  <BaseBarChart
    v-else-if="hasData"
    :options="options"
    :data="data"
    class="warp-in"
  />
  <div v-else-if="!loading && !hasData">Ingen data tillgänglig</div>
</template>

<script>
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import BaseBarChart from '@/components/BaseBarChart.vue';

export default {
  components: { BaseBarChart, BaseLoadingSpinner },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    hasData() {
      return (
        !!this.data?.datasets?.length && !!this.data?.datasets[0].data?.length
      );
    }
  }
};
</script>
