<template>
  <DefaultPieChart :data="data" :loading="loading" />
</template>
<script>
import DefaultPieChart from '@/components/DefaultPieChart.vue';

export default {
  components: {
    DefaultPieChart
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    swapTypeCounts: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    swapTypeCountsKeys() {
      return Object.keys(this.swapTypeCounts);
    },
    swapTypeCountsValues() {
      return Object.values(this.swapTypeCounts);
    },
    data() {
      return {
        labels: this.swapTypeCountsKeys.map(key => `${key}-part`),
        datasets: [
          {
            backgroundColor: ['#409fff', '#40c07b', '#c040ac', '#FF3843'],
            data: this.swapTypeCountsValues
          }
        ]
      };
    }
  }
};
</script>
