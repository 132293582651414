var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    _vm._g(
      {
        class: [
          "button",
          { "is-fullwidth": _vm.isFullwidth },
          { "is-disabled": _vm.isDisabled },
          { "is-rounded": _vm.isRounded },
          { "is-default": _vm.isDefault },
          { "is-dark": _vm.isDark },
          { "is-warning": _vm.isWarning },
          { "is-loading": _vm.isLoading },
          ..._vm.classes,
        ],
        attrs: { disabled: _vm.isDisabled },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "inner-wrapper" },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "spinner" }, [_c("BaseSpinner")], 1)
            : _vm._e(),
          !!_vm.icon && !_vm.isLoading
            ? _c("BaseImage", { staticClass: "icon", attrs: { url: _vm.icon } })
            : _vm._e(),
          _c(
            "span",
            { class: { hidden: _vm.isLoading } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }