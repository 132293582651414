<template>
  <ul>
    <li
      v-for="otherCheckbox in otherCheckboxes"
      :key="otherCheckbox.category"
      class="list-items"
    >
      <ApplicationListCheck
        :hide-icon="true"
        :is-disabled="isIncoming || isRejected || isRemoved"
        :is-checked="checkedFiles.includes(otherCheckbox.category)"
        :icon-url="getCheckboxIconUrl(otherCheckbox.category)"
        :comment="null"
        :has-files="false"
        :icon-title="getCheckboxTitle(otherCheckbox.category)"
        @checked="
          $emit('checksChange', {
            checked: $event,
            category: otherCheckbox.category
          })
        "
        @selectedComment="$emit('selectedComment', $event)"
        >{{ getLabel(otherCheckbox.category) }}: {{ otherCheckbox.value }}
      </ApplicationListCheck>
    </li>
    <li
      v-for="category in allDocumentsUploadedForType"
      :key="category"
      class="list-items"
    >
      <ApplicationListCheck
        :hide-icon="!isIncoming"
        :is-disabled="isIncoming || isRejected || isRemoved"
        :is-checked="checkedFiles.includes(category)"
        :icon-url="getCheckboxIconUrl(category)"
        :comment="null"
        :has-files="!isIncoming && !isRejected && fileExists(category)"
        :icon-title="getCheckboxTitle(category)"
        @checked="$emit('checksChange', { checked: $event, category })"
        @selectedComment="$emit('selectedComment', $event)"
        @openFile="$emit('openFile', { index: 0, category })"
        >{{ getLabel(category) }}
      </ApplicationListCheck>
    </li>
  </ul>
</template>

<script>
import svgs from '@/utils/icon-constants';
import docTypeUtils from '@/utils/docs-utils';
import ApplicationListCheck from '@/components/ApplicationListCheck.vue';

export default {
  components: {
    ApplicationListCheck
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    reason: {
      type: Object,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    checkedFiles: {
      type: Array,
      required: true
    },
    otherCheckboxes: {
      type: Array,
      required: true
    },
    applicationStatus: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    allDocumentsUploadedForType() {
      return [
        ...this.requiredDocumentsForType,
        ...this.noneRequiredDocumentsForType
      ].filter(type => this.fileExists(type));
    },
    requiredDocumentsForType() {
      if (!this.reason.type) {
        return [];
      }

      switch (this.reason.type) {
        case 'sambo':
          return this.application.requiredDocs.sambo[
            this.reason.housingType || 'default'
          ];
        case 'arbete':
        case 'studie':
          return this.application.requiredDocs[this.reason.type][
            this.reason.movingAbroad ? 'movingAbroad' : 'default'
          ];
        case 'other':
          return this.application.requiredDocs.other.default;
        default:
          return [];
      }
    },
    noneRequiredDocumentsForType() {
      if (!this.reason.type) {
        return [];
      }

      switch (this.reason.type) {
        case 'sambo':
          return this.application.noneRequiredDocs.sambo[
            this.reason.housingType || 'default'
          ];
        case 'arbete':
        case 'studie':
          return this.application.noneRequiredDocs[this.reason.type][
            this.reason.movingAbroad ? 'movingAbroad' : 'default'
          ];
        case 'other':
          return this.application.noneRequiredDocs.other.default;
        default:
          return [];
      }
    },
    isIncoming() {
      return this.applicationStatus === 'INCOMING';
    },
    isRejected() {
      return this.applicationStatus === 'REJECTED';
    },
    isRemoved() {
      return this.applicationStatus === 'REMOVED';
    }
  },
  methods: {
    fileExists(category) {
      return this.files.some(file => file.type === category);
    },
    getCheckboxIconUrl(category) {
      const fileExist = this.fileExists(category);
      if (fileExist) {
        return this.svgs.ICONS.CHECK;
      }
      return this.svgs.ICONS.YELLOW_CLOCK;
    },
    getCheckboxTitle(category) {
      const fileExist = this.fileExists(category);
      if (fileExist) {
        return 'Uppgifter inskickade';
      }

      return 'Uppgifter har inte skickats in';
    },
    getLabel(category) {
      return docTypeUtils.getDocTypeStr(category);
    }
  }
};
</script>

<style scoped>
.list-items {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 0 2px 0;
}
.list-items .text-icon {
  width: 100%;
}
.list-items :deep(.icon-wrapper) {
  flex-shrink: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-light-grey);
  border: none;
  margin: 5px 0;
}
</style>
