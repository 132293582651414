<template>
  <div class="search-help-container">
    <div class="main-filter-wrapper">
      <input
        type="text"
        :placeholder="placeholder"
        :class="['main-filter', showClearBtn ? 'active' : '']"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <transition name="closeBtn-fade">
        <button
          v-if="showClearBtn"
          key="1"
          class="close-btn"
          @click="$emit('clear', $event)"
        >
          <BaseImage :url="svgs.ICONS.CLOSE" class="close-icon" />
        </button>
      </transition>
    </div>
    <slot />
  </div>
</template>
<script>
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'FilterMain',
  components: {
    BaseImage
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Filtrera på adress, namn, hyresvärd, handläggare mm'
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    showClearBtn() {
      return this.value.length > 0;
    }
  }
};
</script>

<style scoped>
.search-help-container {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  padding: 1.6rem 0 3.2rem 3.2rem;
  align-content: center;
  gap: 3.2rem;
  align-items: center;
}

.main-filter-wrapper {
  width: max(360px, 30vw);
  height: 4rem;
  position: relative;
}
.main-filter {
  width: 100%;
  height: 100%;
  color: var(--color-grey);
  border: none;
  border-radius: 50px;
  padding: 0.3rem 2.5rem 0.3rem 2.5rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

  text-overflow: ellipsis;
}

.main-filter.active {
  padding-right: 3.3rem;
}

input[type='text']:focus {
  outline: none;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-grey);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-grey);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-grey);
}

.close-btn {
  position: absolute;
  top: 50%;
  right: 1.2rem;
  width: 1.6rem;
  height: 1.6rem;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
}

.close-icon {
  width: 100%;
  height: 100%;
}

.closeBtn-fade-enter-active {
  transition: opacity 0.4s;
}
.closeBtn-fade-enter,
.closeBtn-fade-leave-to {
  opacity: 0;
}
</style>
