import Vue from 'vue';

function setIsReady(state, ready) {
  state.ready = ready;
}
function setIsAuthenticated(state, authenticated) {
  state.authenticated = authenticated;
}
function setAccount(state, account) {
  state.account = account;
}

function markTooltipAsSeen(state, reference) {
  const index = state.tooltips
    .map(tooltip => tooltip.reference)
    .indexOf(reference);

  if (index >= 0) {
    state.tooltips[index].seen = true;
  }
}

function resetFirstPartOfTooltipsArray(state) {
  const newTooltips = state.tooltips.map((tooltip, index) => ({
    ...tooltip,
    seen: index < 3 ? false : tooltip.seen
  }));

  state.tooltips = newTooltips;
}

function resetSecondPartOfTooltipsArray(state) {
  const newTooltips = state.tooltips.map((tooltip, index) => ({
    ...tooltip,
    seen: index >= 3 ? false : tooltip.seen
  }));

  state.tooltips = newTooltips;
}

function markSyncedTooltipAsSeen(state, reference) {
  const index = state.tooltips
    .map(tooltip => tooltip.reference)
    .indexOf(reference);

  if (index >= 0) {
    Vue.set(state.tooltips, index, {
      ...state.tooltips[index],
      seen: true
    });
  }
}

function setActiveApp(state, name) {
  state.activeApp = name;
}

export default {
  setIsReady,
  setIsAuthenticated,
  setAccount,
  // setScreenSize,
  markTooltipAsSeen,
  resetFirstPartOfTooltipsArray,
  resetSecondPartOfTooltipsArray,
  markSyncedTooltipAsSeen,
  setActiveApp
};
