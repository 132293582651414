<template>
  <div>
    <DefaultModal v-if="apartmentInfoModal" @close="apartmentInfoModal = ''">
      <template slot="header">
        <DefaultModalTitle>Annat fastighetsinnehav</DefaultModalTitle></template
      >
      <template slot="body"
        ><p class="apartmentInfo-content">{{ apartmentInfoModal }}</p>
      </template>
    </DefaultModal>
    <ul>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :has-error="!hasResided(part)"
          checkbox-name="ACCOMONDATION_TIME"
          :hide-icon="hideIcon"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'ACCOMONDATION_TIME',
              checked: $event
            })
          "
        >
          Bosatt:
          {{ getResidedString(part) }}</SwapApplicationListCheck
        >
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :has-error="!hasAccomondations(part.currentAccommodation)"
          checkbox-name="ACCOMONDATION_CURRENT"
          :hide-icon="hideIcon"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'ACCOMONDATION_CURRENT',
              checked: $event
            })
          "
        >
          Boende idag:
          {{
            getAccomondationStr(part.currentAccommodation)
          }}</SwapApplicationListCheck
        >
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="MIN_UC"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'MIN_UC',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'MIN_UC')"
        >
          UC
        </SwapApplicationListCheck>
        <Tooltip v-if="showTooltip" step="openFiles" position="right"></Tooltip>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="RENTAL_BASE"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'RENTAL_BASE',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'RENTAL_BASE')"
        >
          Hyra
        </SwapApplicationListCheck>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="RENTAL_AGREEMENT"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'RENTAL_AGREEMENT',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'RENTAL_AGREEMENT')"
        >
          Hyresavtal
        </SwapApplicationListCheck>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="ID_PROOF"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'ID_PROOF',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'ID_PROOF')"
        >
          Familjebevis
        </SwapApplicationListCheck>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="INCOME_BASE"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'INCOME_BASE',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'INCOME_BASE')"
        >
          Intyg på inkomst
        </SwapApplicationListCheck>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :has-error="!part.members[0].swapReason"
          checkbox-name="REASON"
          :hide-icon="hideIcon"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'REASON',
              checked: $event
            })
          "
        >
          Bytesskäl
        </SwapApplicationListCheck>
      </li>
      <li class="list-items">
        <SwapApplicationListCheck
          :part="part"
          :is-disabled="isDisabled"
          :is-commentable="true"
          checkbox-name="OTHER_DOCUMENTS"
          :hide-icon="hideIcon"
          :documents="
            documentsCollections[getFullIdPath(part, documentsCollections)]
          "
          :application-status="applicationStatus"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'OTHER_DOCUMENTS',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @openFile="$emit('openFile', 'OTHER_DOCUMENTS')"
        >
          Övriga dokument
        </SwapApplicationListCheck>
      </li>
      <hr
        v-if="part.members[selectedMemberIdx]?.otherApartmentInfo"
        class="divider"
      />
      <li
        v-if="part.members[selectedMemberIdx]?.otherApartmentInfo"
        class="list-items"
      >
        <SwapApplicationListCheck
          class="other-apartment-checkbox"
          :class="{ disabled: isDisabled }"
          :part="part"
          :is-disabled="isDisabled"
          :custom-icon="svgs.ICONS.INFO"
          :disable-tooltip="true"
          checkbox-name="OTHER_APARTMENT_INFO"
          :hide-icon="hideIcon"
          @checksChange="
            $emit('checksChange', {
              checkboxType: 'OTHER_APARTMENT_INFO',
              checked: $event
            })
          "
          @selectedComment="$emit('selectedComment', $event)"
          @didClick="
            openApartmentInfoModal(
              part.members[selectedMemberIdx]?.otherApartmentInfo
            )
          "
        >
          Annat fastighetsinnehav
        </SwapApplicationListCheck>
      </li>
    </ul>
  </div>
</template>

<script>
import svgs from '@/utils/icon-constants';
import statusTypes from '@/utils/statusTypes';
import SwapApplicationListCheck from '@/components/SwapApplicationListCheck.vue';
import Tooltip from '@/components/Tooltip.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import { getFullIdPath } from '@/utils/misc';

export default {
  components: {
    SwapApplicationListCheck,
    Tooltip,
    DefaultModal,
    DefaultModalTitle
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedMemberIdx: {
      type: Number,
      required: true
    },
    showTooltip: {
      type: Boolean,
      required: true
    },
    documentsCollections: {
      type: Object,
      required: true
    },
    applicationStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      svgs,
      apartmentInfoModal: '',
      getFullIdPath
    };
  },
  computed: {
    hideIcon() {
      return !this.isIncoming;
    },
    isIncoming() {
      return this.applicationStatus === statusTypes.INCOMING;
    }
  },
  methods: {
    openApartmentInfoModal(info) {
      this.apartmentInfoModal = info;
    },
    hasResided(part) {
      const resided = part.members[this.selectedMemberIdx].resided;
      return !!resided;
    },

    getResidedString(part) {
      const resided = part.members[this.selectedMemberIdx].resided;
      if (resided.months === 0) {
        return `${resided.years} år`;
      }
      return `${resided.years} år, ${resided.months} mån`;
    },
    hasAccomondations(accomondations) {
      const noAdults = !accomondations.adults || accomondations.adults === 0;
      return !noAdults;
    },

    getAccomondationStr(accomondations) {
      let str = '';

      if (!accomondations.adults) {
        return `ej angivet`;
      }

      if (accomondations.adults === 0) {
        return `1 vuxen`;
      }

      if (accomondations.adults === 1) {
        str += `1 vuxen`;
      } else {
        str += `${accomondations.adults} vuxna`;
      }

      if (accomondations.children > 0) {
        str += `, ${accomondations.children} barn`;
      }

      return str;
    }
  }
};
</script>

<style scoped>
.list-items {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 0 2px 0;
}
.list-items .text-icon {
  width: 100%;
}
.list-items :deep(.icon-wrapper) {
  flex-shrink: 0;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-light-grey);
  border: none;
  margin: 5px 0;
}
.other-apartment-checkbox {
  text-decoration: underline;
  cursor: pointer;
}
.other-apartment-checkbox:hover {
  color: var(--color-blue);
}
.other-apartment-checkbox.disabled {
  text-decoration: none;
  cursor: text;
}
.other-apartment-checkbox.disabled:hover {
  color: inherit;
}
.apartmentInfo-content {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  color: var(--color-dark-blue);
}
</style>
