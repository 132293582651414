var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b({ staticClass: "user-icon-wrapper" }, "div", _vm.$attrs, false),
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "p",
            {
              class: ["user-icon", _vm.showIcon ? "icon" : ""],
              style: {
                "background-color": _vm.bgColor,
              },
            },
            [_vm._v(" " + _vm._s(!_vm.showIcon ? _vm.initials : "") + " ")]
          ),
          _vm._t("default"),
        ],
        2
      ),
      _vm.fullNameVisible
        ? _c("span", { staticClass: "fullname" }, [
            _vm._v(_vm._s(_vm.fullName)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }