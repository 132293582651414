<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ändra till nekad på grund av</DefaultModalTitle>
    </template>

    <template slot="body">
      <div v-if="isFetchingNote" class="spinner-container">
        <div class="spinner"><BaseSpinner /></div>
      </div>
      <template v-else>
        <div class="textarea-wrapper">
          <textarea
            :value="note"
            class="area"
            :disabled="isSaving"
            cols="30"
            rows="5"
            @input="note = $event.target.value"
          ></textarea>
        </div>
        <p class="approved-reason">
          <template v-if="lastChangedReason">
            (<b>{{ lastChangedReason.name }}</b> {{ lastChangedReason.text }})
          </template>
        </p></template
      >
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isSaving"
        :is-rounded="true"
        :is-warning="true"
        :is-disabled="note.length === 0"
        :icon="svgs.ICONS.ERROR_WHITE"
        @click="rejectApplication"
      >
        Neka byte
      </BaseButton>
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
import { getLastChangedReason } from '@/utils/statusReason';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';

export default {
  components: {
    DefaultModal,
    BaseButton,
    BaseSpinner,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      note: '',
      isFetchingNote: false,
      lastChangedReason: null
    };
  },

  async mounted() {
    this.isFetchingNote = true;
    try {
      this.lastChangedReason = await getLastChangedReason(this.applicationId);
    } catch {
      console.error('Something went wrong');
    }
    this.isFetchingNote = false;
  },

  methods: {
    ...mapActions({
      rejectApp: 'applications/rejectApp'
    }),
    async rejectApplication() {
      this.isSaving = true;
      await this.rejectApp({ id: this.applicationId, note: this.note });
      churnService.track(
        events.CHANGE_SWAP_STATUS,
        features.CHANGE_SWAP_STATUS.CHANGE_TO_REJECT
      );
      this.isSaving = false;
      this.$emit('onRejected');
    }
  }
};
</script>

<style scoped>
.approved-reason {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 1rem;
}

.approved-reason b {
  color: var(--color-dark-blue);
}
.textarea-wrapper {
  overflow: hidden;
}

.area {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-inter);
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 4rem;
  width: 4rem;
}

#spinner {
  border-top-color: var(--color-blue);
}
</style>
