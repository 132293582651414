var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "default-wrapper" }, [
    _c(
      "div",
      { staticClass: "default-box", class: { large: _vm.isLarge } },
      [
        _vm.icon
          ? _c("div", { staticClass: "left" }, [
              _c(
                "span",
                { staticClass: "default-icon-wrapper" },
                [
                  _c("BaseImage", {
                    attrs: { url: _vm.icon, classes: ["default-left-icon"] },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.icon
          ? _c("span", { staticClass: "default-icon-separator" })
          : _vm._e(),
        _c(
          "BaseInput",
          _vm._g(
            _vm._b(
              {
                staticClass: "default-input",
                attrs: { value: _vm.value, name: _vm.name },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event)
                  },
                },
              },
              "BaseInput",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.isLoading
                ? _c("div", { staticClass: "spinner" }, [_c("BaseSpinner")], 1)
                : _vm._e(),
              !_vm.isLoading && _vm.value && _vm.value.length > 0
                ? _c("div", {
                    staticClass: "clear-default",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("clear")
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ],
          1
        ),
        !_vm.isLoading ? _vm._t("default") : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }