var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultPage", [
    _c("h1", { staticClass: "page-title" }, [_vm._v("Inställningar")]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "card" }, [
        _vm.emailSent
          ? _c("div", [
              _c("h4", [_vm._v("Skickat!")]),
              _c("p", [
                _vm._v(
                  "Vänligen titta efter ett återställnings e-mail i din inbox."
                ),
              ]),
            ])
          : _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.requestPasswordReset({ username: _vm.username })
                  },
                },
              },
              [
                _c("h4", [_vm._v("Byt lösenord")]),
                _c("p", [
                  _vm._v(
                    " Ett e-mail skickas till din e-postadress med instruktioner på hur du byter ditt lösenord. "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "email-input-wrapper" },
                  [
                    _c("DefaultInput", {
                      attrs: {
                        name: "username",
                        disabled: _vm.isLoading,
                        placeholder: "E-postadress",
                        type: "text",
                      },
                      on: { clear: _vm.clearUsername },
                      model: {
                        value: _vm.username,
                        callback: function ($$v) {
                          _vm.username = $$v
                        },
                        expression: "username",
                      },
                    }),
                  ],
                  1
                ),
                _vm.hasError
                  ? _c("p", { staticClass: "error-msg" }, [
                      _vm._v(" Något gick fel, försök igen senare "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "reset-button" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          type: "submit",
                          "is-loading": _vm.isLoading,
                          "is-disabled": _vm.isLoading || !_vm.username,
                          "is-fullwidth": false,
                          "is-rounded": true,
                        },
                      },
                      [_vm._v("Återställ lösenord")]
                    ),
                  ],
                  1
                ),
              ]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }