<template>
  <LineBase :options="chartOptions" :data="data" class="chart" />
</template>

<script>
import { Line as LineBase } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export default {
  components: {
    LineBase
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          tooltip: {
            padding: 20,
            boxPadding: 10
          }
        },
        ...this.options
      };
    }
  }
};
</script>
<style scoped>
.chart {
  width: 100% !important;
  height: 100% !important;
}
</style>
