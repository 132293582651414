var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.applications
    ? _c(
        "ApplicationsLane",
        {
          attrs: {
            title: _vm.title,
            applications: _vm.applications,
            "container-style": _vm.containerStyle,
            status: _vm.status,
          },
        },
        [
          _c(
            "template",
            { slot: "controls" },
            [
              _vm.status === "waiting"
                ? _c("SelectWrapper", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.activeSorting,
                            expression: "activeSorting",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.activeSorting = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "sentToHostAt-NEW" } }, [
                          _vm._v("Nyaste ansökan"),
                        ]),
                        _c("option", { attrs: { value: "sentToHostAt-OLD" } }, [
                          _vm._v("Äldsta ansökan"),
                        ]),
                        _c("option", { attrs: { value: "rentFrom-NEW" } }, [
                          _vm._v("Uthyrningsdatum (senare)"),
                        ]),
                        _c("option", { attrs: { value: "rentFrom-OLD" } }, [
                          _vm._v("Uthyrningsdatum (tidigast)"),
                        ]),
                      ]
                    ),
                  ])
                : _c("SelectWrapper", { attrs: { hidden: "" } }, [
                    _c("select", { attrs: { disabled: "" } }),
                  ]),
            ],
            1
          ),
          _c(
            "template",
            { slot: "body" },
            _vm._l(
              _vm.groupedApplications,
              function (groupedApplication, date, index) {
                return _c(
                  "transition-group",
                  {
                    key: date,
                    staticClass: "relative",
                    class: { "card-group": _vm.status === "closed" },
                    attrs: { name: "fade-list", tag: "div", appear: "" },
                  },
                  [
                    _vm.status === "ongoing"
                      ? _c("div", { key: index, staticClass: "app-date" }, [
                          _vm._v(" " + _vm._s(_vm.getGroupText(date)) + " "),
                        ])
                      : _vm._e(),
                    _vm._l(groupedApplication, function (application) {
                      return _c("SubletApplicationCard", {
                        key: application.id,
                        attrs: {
                          application: application,
                          "reason-type-title": _vm.reasonText(
                            application.reason
                          ),
                          subtitle: _vm.subtitle(application),
                          "subtitle-class": _vm.subtitleClass(application),
                          "is-new": _vm.cardIsNew(application),
                          "full-name": _vm.fullName(application),
                          "last-name": _vm.lastName(application),
                          "status-date": _vm.getStatusDate(
                            _vm.status,
                            application.statusUpdated,
                            application.sentToHost
                          ),
                          "to-path": _vm.toPath,
                        },
                      })
                    }),
                  ],
                  2
                )
              }
            ),
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }