<template>
  <div class="folder-info">
    <div class="folder-name">{{ name }}</div>
    <div class="file-meta">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Uppladdade filer'
    }
  }
};
</script>

<style scoped>
.folder-info {
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
}

.folder-name {
  display: block;
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}

.file-meta {
  display: block;
  font-size: 1.4rem;
  color: #878b9d;
  font-weight: 600;
  line-height: 17px;
}
.upload-files {
  color: var(--color-blue);
  text-decoration: underline;
  font-weight: 600;
}

.file-type {
  text-transform: capitalize;
}

.fake-link {
  color: var(--color-blue);
  text-decoration: underline;
}
</style>
