<template>
  <div id="map-container">
    <div v-if="isLoading" id="map-loading-wrapper">
      <BaseLoadingSpinner :with-overlay="false" :size="60" />
    </div>
    <div v-else-if="hasError" id="map-error-container">
      <p>Kunde inte visa kartan, en/flera av adresserna gick inte att hitta</p>
    </div>
    <div
      id="map"
      :class="{
        ready: mapIsReady
      }"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { loadJs, loadCss } from '@/utils/maptiler';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import bearing from '@turf/bearing';
import { point } from '@turf/helpers';
import distance from '@turf/distance';
import along from '@turf/along';
import arrow from '../assets/arrow-map.png';

export default {
  components: {
    BaseLoadingSpinner
  },
  props: {
    applicationId: {
      type: String,
      default: ''
    },
    sublet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      markers: [],
      isLoading: false,
      hasError: false,
      frames: 120,
      arrows: {},
      lines: {},
      parts: []
    };
  },
  computed: {
    ...mapState('app', ['account']),
    mapIsReady() {
      if (this.isLoading) {
        return false;
      }
      if (this.hasError) {
        return false;
      }
      return true;
    }
  },
  async mounted() {
    await this.fetchAndCreateMap();
  },
  beforeDestroy() {
    //  Cleanup
    this.clearArrows();
    this.clearMarkers();
  },
  methods: {
    async fetchAndCreateMap() {
      this.isLoading = true;
      try {
        let parts = [];
        if (this.sublet) {
          parts = await this.$http.getAhMapCoordinates(this.applicationId);
        } else {
          parts = await this.$http.getMapCoordinates(this.applicationId);
        }

        if (!parts?.length) {
          throw new Error();
        }

        this.parts = parts;
        await Promise.all([loadJs(), loadCss()]);
        this.initializeMap();
        this.updateMarkerPoints();
      } catch (error) {
        this.hasError = true;
      }
      this.isLoading = false;
    },
    updateMarkerPoints() {
      if (!this.parts) return;

      this.clearMarkers();
      const bounds = new maplibregl.LngLatBounds();

      for (const part of this.parts) {
        const { coordinate, location, name, landlordId } = part;

        const marker = new maplibregl.Marker();
        const markerElement = marker.getElement();
        markerElement.innerHTML = `
        <div class="map-tooltip">
          <div class="map-tooltip-inner">
            <div class="map-tooltip-title">${location}</div>
            <div class="map-tooltip-text">${name}</div>
          </div>
          <div class="map-tooltip-arrow"></div>
        </div>
        <div class="marker">
            <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse opacity="0.25" cx="14.0369" cy="35.1154" rx="10.5365" ry="3.38672" fill="${this.getEdgeColor(
                landlordId
              )}"/>
              <path fill="${this.getFillColor(
                landlordId
              )}" stroke="${this.getEdgeColor(
          landlordId
        )}" stroke-width="1.5" d="M26.3769 13.3527L26.3769 13.3515C26.2566 6.73357 20.7695 1.33105 14.1589 1.25092L14.1585 1.25091C7.26976 1.17081 1.62158 6.73738 1.62158 13.6286C1.62158 15.9144 2.26315 18.0767 3.34287 19.9162L3.35374 19.9347L3.36564 19.9526C3.42647 20.0438 3.48138 20.1375 3.54881 20.2531L3.55286 20.26C3.61547 20.3674 3.68977 20.4948 3.7761 20.6247L12.3528 33.9411L12.3527 33.9411L12.3593 33.951L12.4345 34.0639L12.4596 34.1016L12.4891 34.1359C12.8138 34.5148 13.3074 34.8494 13.9617 34.8494C14.5807 34.8494 15.1464 34.5439 15.4751 34.0838L15.4821 34.0739L15.4888 34.0639L15.5641 33.951L15.5642 33.951L15.5706 33.9411L24.1471 20.6249L24.1553 20.6127C24.292 20.4076 24.4563 20.1612 24.5877 19.9051C25.782 17.9821 26.4168 15.7428 26.3769 13.3527Z" />
              <path fill="${this.getEdgeColor(
                landlordId
              )}" d="M8.27925 13.5537H8.91898V18.0319C8.91898 18.7375 9.49282 19.3113 10.1985 19.3113H17.8753C18.5809 19.3113 19.1547 18.7375 19.1547 18.0319V13.5537H19.7945C19.921 13.5537 20.0446 13.5162 20.1498 13.4459C20.255 13.3756 20.337 13.2757 20.3854 13.1588C20.4338 13.0419 20.4465 12.9133 20.4218 12.7892C20.3971 12.6651 20.3362 12.5512 20.2468 12.4617L14.4892 6.70409C14.4298 6.64463 14.3593 6.59746 14.2817 6.56527C14.2041 6.53308 14.1209 6.51652 14.0369 6.51652C13.9528 6.51652 13.8696 6.53308 13.792 6.56527C13.7144 6.59746 13.6439 6.64463 13.5846 6.70409L7.82695 12.4617C7.73751 12.5512 7.67661 12.6651 7.65193 12.7892C7.62726 12.9133 7.63993 13.0419 7.68834 13.1588C7.73675 13.2757 7.81872 13.3756 7.9239 13.4459C8.02908 13.5162 8.15274 13.5537 8.27925 13.5537ZM14.0369 8.06097L17.8753 11.8994V14.8332L17.8759 18.0319H10.1985V11.8994L14.0369 8.06097Z" />
          </svg>
        </div>`;
        markerElement.addEventListener('click', e => {
          this.clearTooltips();
          const target = e.target;
          target.classList.add('active');
          target.style.zIndex = '999';
        });
        marker.setLngLat({ lng: coordinate.lng, lat: coordinate.lat });
        marker.addTo(this.map);

        bounds.extend([coordinate.lng, coordinate.lat]);
        this.markers.push(marker);
      }

      this.map.fitBounds(bounds, {
        padding: 50
      });
    },
    getFillColor(landlordId) {
      return this.account.landlordId === landlordId ? '#409fff' : '#fff';
    },
    getEdgeColor(landlordId) {
      return this.account.landlordId === landlordId ? '#fff' : '#000';
    },
    clearTooltips() {
      const mapMarkersEls = Array.from(
        document.querySelectorAll('.mapboxgl-marker')
      );
      mapMarkersEls.forEach(marker => {
        marker.classList.remove('active');
        marker.style.zIndex = '1';
      });
    },
    clearMarkers() {
      for (const marker of this.markers) {
        marker.remove();
      }

      this.markers = [];
    },
    clearArrows() {
      for (const key of Object.keys(this.arrows)) {
        this.map.removeLayer(`arrow-layer_${key}`);
        this.map.removeLayer(`line-layer_${key}`);
        this.map.removeSource(`arrow-src_${key}`);
        this.map.removeSource(`line-src_${key}`);
      }
      this.lines = {};
      this.arrows = {};
    },
    initializeMap() {
      this.map = new maplibregl.Map({
        container: 'map',
        style: `https://api.maptiler.com/maps/streets/style.json?key=${process.env.VUE_APP_MAPTILER}`,
        center: [12.8, 63.11],
        zoom: 4
      });

      this.map.addControl(
        new maplibregl.NavigationControl({
          showCompass: false
        }),
        'top-right'
      );

      const that = this;

      this.map.on('movestart', () => that.clearTooltips());

      this.map.on('load', () => {
        that.map.loadImage(arrow, function (err, image) {
          if (err) {
            console.error('err image', err);
            return;
          }

          that.map.addImage('arrow', image);
          that.drawArrows();
        });
      });
    },
    drawArrows() {
      this.parts.forEach(part => {
        const start = [part.coordinate.lng, part.coordinate.lat];
        part.targetLocations.forEach(targetCoordinate => {
          const stop = [targetCoordinate.lng, targetCoordinate.lat];
          const currentKey = `${start[0]}__${start[1]}_${stop[0]}__${stop[1]}`;
          const line = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [start, stop]
                }
              }
            ]
          };

          const arrow = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  bearing: bearing(point(start), point(stop))
                },
                geometry: {
                  type: 'Point',
                  coordinates: stop
                }
              }
            ]
          };

          const cordDistance = distance(start, stop, 'kilometers');
          const newCoords = [];

          for (let i = 0; i < cordDistance; i += cordDistance / this.frames) {
            const segment = along(line.features[0], i, 'kilometers');
            newCoords.push(segment.geometry.coordinates);
          }

          line.features[0].geometry.coordinates = newCoords;

          this.map.addSource(`line-src_${currentKey}`, {
            type: 'geojson',
            data: line
          });

          this.map.addSource(`arrow-src_${currentKey}`, {
            type: 'geojson',
            data: arrow
          });

          this.lines[currentKey] = line;
          this.arrows[currentKey] = arrow;
          //this.counters[currentKey] = 0;

          this.map.addLayer({
            id: `line-layer_${currentKey}`,
            source: `line-src_${currentKey}`,
            type: 'line',
            paint: {
              'line-color': 'rgba(255, 56, 67, 0.7)',
              'line-width': 3
            }
          });

          this.map.addLayer({
            id: `arrow-layer_${currentKey}`,
            source: `arrow-src_${currentKey}`,
            type: 'symbol',
            layout: {
              'icon-image': 'arrow',
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true
            }
          });
          const offset = 30;
          this.arrows[currentKey].features[0].geometry.coordinates =
            this.lines[currentKey].features[0].geometry.coordinates[
              this.frames - offset
            ];
          this.map
            .getSource(`arrow-src_${currentKey}`)
            .setData(this.arrows[currentKey]);
        });
      });
    }
  }
};
</script>

<style>
#map-container {
  position: relative;
}
#map {
  width: 760px;
  height: 560px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  position: relative;
}

#map.ready {
  visibility: visible;
  opacity: 1;
}

#map-loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#map-error-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#map-error-container p {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}
#map-error-container button {
  font-size: 1.8rem;
}

@keyframes dropIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.marker {
  opacity: 0;
  width: 28px;
  height: 39px;
  cursor: pointer;
  pointer-events: none;
  animation: dropIn 0.6s ease forwards;
}

.mapboxgl-marker:nth-of-type(1) .marker {
  animation-delay: 0.4s;
}
.mapboxgl-marker:nth-of-type(2) .marker {
  animation-delay: 0.8s;
}
.mapboxgl-marker:nth-of-type(3) .marker {
  animation-delay: 1.2s;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.map-tooltip {
  padding: 1rem;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  top: -1rem;
  transform: translate(-50%, -100%);
  width: 200px;
  left: 50%;
  background-color: var(--color-blue);
}

.active .map-tooltip {
  display: flex;
}

.map-tooltip-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.map-tooltip-title {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 2px;
}

.map-tooltip-text {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 100%;
}

.map-tooltip-arrow {
  position: absolute;
  display: block;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 1px;
  margin-top: -1px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid var(--color-blue);
}
</style>
