// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svgs/icons/administrator.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.user-icon-wrapper[data-v-63e265d8] {\n  display: inline-flex;\n  align-items: center;\n}\n.fullname[data-v-63e265d8] {\n  padding: 0;\n  margin: 0;\n  margin-left: 10px;\n}\n.user-icon[data-v-63e265d8] {\n  font-weight: 600;\n  font-size: 10px;\n\n  width: 2.4rem;\n  height: 2.4rem;\n  background: #19cccc;\n  border-radius: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n}\n.user-icon.icon[data-v-63e265d8] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
