<template>
  <div class="green-checkbox">
    <input
      v-bind="$attrs"
      :id="id"
      :name="id"
      :checked="checked"
      type="checkbox"
      :disabled="isDisabled"
      class="no-pointer-events"
      @change="$emit('change', $event)"
    />
    <label id="checkbox" :for="id">
      <svg viewBox="0 0 100 100">
        <path
          class="box"
          d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"
        />
        <polyline class="check" points="25.5,53.5 39.5,67.5 72.5,34.5 " />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    checked: {
      type: Boolean,
      required: false
    },

    name: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    };
  },

  created() {
    if (this.name) {
      this.id = this.name;
    }
  }
};
</script>

<style scoped>
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label {
  cursor: pointer;
  font-size: 0.8em;
  display: grid;
  grid-template-columns: auto 3fr;
}
input[type='checkbox'] + label svg {
  width: 2.4em;
  fill: #fff;
}
input[type='checkbox'] + label svg path {
  stroke-width: 6px;
}
input[type='checkbox'] + label svg polyline {
  stroke-width: 10px;
}
input[type='checkbox'] + label svg .box {
  fill: white;
  stroke: lightgray;
}
input[type='checkbox'] + label svg .check {
  stroke: #40c07b;
  stroke-dasharray: 70;
  stroke-dashoffset: 70;
  fill: none;
  transition: stroke-dashoffset 0.3s linear;
}
input[type='checkbox']:checked + label .check {
  stroke-dashoffset: 0;
}
input[type='checkbox'][disabled] + label {
  cursor: not-allowed;
}
</style>
