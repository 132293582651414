<template>
  <div class="tooltip-container">
    <slot />
    <div
      v-if="tooltipExists"
      ref="tooltip"
      :class="[
        'tooltip',
        { 'show-tooltip': showTooltip },
        { top: position === 'top' },
        { bottom: position === 'bottom' },
        { 'bottom-left': position === 'bottom-left' },
        { right: position === 'right' }
      ]"
    >
      <div class="tooltip-header">
        <BaseImage :url="svgs.ICONS.TIP" class="tooltip-header-image" />

        <p v-for="(t, idx) in getCurrentTooltipTitle()" :key="idx">{{ t }}</p>
      </div>
      <p v-for="(c, idx) in getCurrentTooltip()" :key="idx">
        {{ c }}
      </p>

      <div class="tooltip-footer">
        <BaseButton
          class="next-tip-btn"
          :is-rounded="true"
          :is-small="true"
          :is-default="true"
          type="submit"
          @click="handleMarkTooltipAsSeen({ reference: step })"
        >
          <span class="tooltip-button-text">{{ btnText }}</span>
          <BaseIconText
            :icon-url="showArrow ? svgs.ICONS.ARROW_RIGHT : ''"
            class="invert"
          >
          </BaseIconText>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';

export default {
  components: { BaseButton, BaseIconText, BaseImage },
  props: {
    position: {
      type: String,
      default: 'bottom'
    },
    step: {
      type: String,
      default: null
    },
    btnText: {
      type: String,
      default: 'Gå till nästa'
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    ...mapGetters({
      tooltips: 'app/tooltips'
    }),

    showTooltip() {
      const tooltip =
        this.currentTooltipStep < this.maxTooltipSteps &&
        this.tooltips[this.currentTooltipStep]?.reference === this.step &&
        (this.seenToolTip === false || !this.seenToolTip) &&
        this.tooltips.includes(this.tooltips[this.currentTooltipStep]);

      return tooltip;
    },

    seenToolTip() {
      return this.tooltips.some(tip => tip.reference === this.step && tip.seen);
    },

    currentTooltipStep() {
      let firstTooltipNotSeen = this.tooltips.findIndex(
        tip => tip.seen === false
      );
      firstTooltipNotSeen =
        firstTooltipNotSeen === -1 ? 0 : firstTooltipNotSeen;
      return firstTooltipNotSeen;
    },

    maxTooltipSteps() {
      return this.tooltips.length;
    },

    tooltipExists() {
      return this.tooltips.some(tip => {
        if (tip.reference !== this.step) {
          return false;
        }

        return true;
      });
    }
  },
  methods: {
    ...mapActions({
      markTooltipAsSeen: 'app/markTooltipAsSeen'
    }),
    getCurrentTooltip() {
      if (this.currentTooltipStep >= this.maxTooltipSteps) {
        return null;
      }

      return this.tooltips[this.currentTooltipStep].content;
    },
    getCurrentTooltipTitle() {
      if (this.currentTooltipStep >= this.maxTooltipSteps) {
        return null;
      }

      return this.tooltips[this.currentTooltipStep].title;
    },
    async handleMarkTooltipAsSeen({ reference }) {
      await this.markTooltipAsSeen({ reference });
    }
  }
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
}
.tooltip {
  --time: 0.3s;
  --tooltip-color: #7b52ea;
  visibility: hidden;
  height: 0;
  opacity: 0;
  font-size: 16px;
  font-weight: 600;
  background-color: #7b52ea;
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  width: 0;
  max-width: 420px;
  margin-top: 15px;
  white-space: normal;
  position: absolute;
  z-index: 10;
  font-family: Averta Std, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  background: var(--tooltip-color);
}
@media (max-width: 767px) {
  .tooltip {
    margin-left: -50px;
  }
}
.tooltip > p {
  margin-bottom: 20px;
}
.tooltip.show-tooltip {
  opacity: 1;
  height: auto;
  visibility: visible;
  width: 380px;
}
.tooltip.top.show-tooltip {
  top: 8.5%;
  left: -34rem;
  animation: slide-left var(--time) cubic-bezier(0.5, 1.8, 0.5, 0.8);
}
.tooltip.bottom.show-tooltip {
  bottom: 30px;
  left: 8px;
  animation: slide-left var(--time) cubic-bezier(0.5, 1.8, 0.5, 0.8);
}
.tooltip.bottom-left.show-tooltip {
  bottom: 50px;
  left: 15px;
  animation: slide-left var(--time) cubic-bezier(0.5, 1.8, 0.5, 0.8);
}
.tooltip.right.show-tooltip {
  top: 50%;
  left: 100%;
  animation: slide-left-center var(--time) cubic-bezier(0.5, 1.8, 0.5, 0.8);
  transform: translateY(-50%);
  margin: 0;
}
.tooltip-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.tooltip-header .tooltip-header-image {
  height: 20px;
  width: 20px;
  margin-right: 12px;
}
.tooltip::after {
  content: ' ';
  position: absolute;
  border-style: solid;
}
@keyframes slide-left {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left-center {
  0% {
    transform: translateX(-20px) translateY(-50%);
  }
  50% {
    transform: translateX(10px) translateY(-50%);
  }
  100% {
    transform: translateX(0) translateY(-50%);
  }
}
@keyframes slide-top {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.top::after {
  bottom: 99.5%;
  left: 18%;
  margin-left: 10px;
  border-width: 10px;
  border-color: transparent transparent var(--tooltip-color) transparent;
  transform: translateX(-50%);
}
.bottom::after,
.bottom-left::after {
  top: 99.5%;
  left: 10%;
  margin-left: 10px;
  border-width: 10px;
  border-color: transparent transparent var(--tooltip-color) transparent;
  transform: rotate(180deg);
}
.right::after {
  top: 50%;
  right: 100%;
  border-width: 10px;
  border-color: transparent transparent var(--tooltip-color) transparent;
  transform: translateY(-50%) rotate(270deg);
}
.tooltip-footer :deep(.is-default) {
  border: none;
}
.tooltip-footer :deep(.inner-wrapper) span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}
.tooltip-footer :deep(.text-icon) {
  display: unset;
  align-items: unset;
}
.tooltip-footer .invert :deep(.icon) {
  filter: invert(100%);
}
.tooltip-footer .invert :deep(.is-default) {
  border: none;
}
.tooltip-footer .tooltip-button-text {
  color: #000;
  font-size: 14px;
}
</style>
