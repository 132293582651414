var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    _vm._g(_vm._b({}, "BaseModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c("div", { staticClass: "comment-modal" }, [
        _c("h2", [_vm._v(_vm._s(_vm.name))]),
        _c("div", { staticClass: "subject" }, [
          _vm._v(
            " Angående " +
              _vm._s(_vm.docTypeUtils.getDocTypeStr(_vm.documentType)) +
              " "
          ),
        ]),
        _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }