var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "tag-button",
      on: {
        click: function ($event) {
          return _vm.toggleTagModal(_vm.applicationId)
        },
      },
    },
    [_c("img", { attrs: { src: _vm.svgs.ICONS.ADD_ICON } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }