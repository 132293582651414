<template>
  <div class="default-wrapper">
    <div class="default-box" :class="{ large: isLarge }">
      <div v-if="icon" class="left">
        <span class="default-icon-wrapper">
          <BaseImage :url="icon" :classes="['default-left-icon']"
        /></span>
      </div>
      <span v-if="icon" class="default-icon-separator"></span>

      <BaseInput
        class="default-input"
        :value="value"
        :name="name"
        v-bind="$attrs"
        @input="$emit('input', $event)"
        v-on="$listeners"
      />

      <div class="right">
        <transition name="fade" mode="out-in">
          <div v-if="isLoading" class="spinner">
            <BaseSpinner />
          </div>

          <div
            v-if="!isLoading && value && value.length > 0"
            class="clear-default"
            @click="$emit('clear')"
          />
        </transition>
      </div>
      <slot v-if="!isLoading"></slot>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import BaseImage from '@/components/BaseImage.vue';

export default {
  components: {
    BaseInput,
    BaseSpinner,
    BaseImage
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: '',
      required: false
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: { type: String, required: false, default: '' },

    isLarge: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.default-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.default-box {
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d6dae9;
}

.default-input {
  background: transparent;
  font-size: 14px;
  font-weight: normal;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 12px 10px 10px;
}

.default-icon {
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
}

.clear-default {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  background-image: url('@/assets/svgs/icons/close.svg');
  background-position: center;
  background-size: contain;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

#spinner {
  border-color: rgba(98, 163, 248, 0.3) !important;
  border-width: 2px !important;
}

.default-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.default-left-icon {
  display: inline-flex;
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
}

.default-icon-separator {
  margin: 0 15px;
  height: 15px;
  border-left: 1px solid #e8e8e8;
}

.right {
  width: 3rem;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.large {
  height: 60px;
  padding: 12px 20px 10px 12px;
}
</style>
