// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svgs/icons/close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.default-wrapper[data-v-2962b9b9] {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n.default-box[data-v-2962b9b9] {\n  overflow: hidden;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  border-radius: 10px;\n  border: 1px solid #d6dae9;\n}\n.default-input[data-v-2962b9b9] {\n  background: transparent;\n  font-size: 14px;\n  font-weight: normal;\n  border: none;\n  outline: none;\n  width: 100%;\n  padding: 10px 12px 10px 10px;\n}\n.default-icon[data-v-2962b9b9] {\n  height: 16px;\n  width: 16px;\n  background-position: center;\n  background-size: contain;\n}\n.clear-default[data-v-2962b9b9] {\n  cursor: pointer;\n  height: 1.5rem;\n  width: 1.5rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: contain;\n}\n.spinner[data-v-2962b9b9] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 2rem;\n  width: 2rem;\n}\n#spinner[data-v-2962b9b9] {\n  border-color: rgba(98, 163, 248, 0.3) !important;\n  border-width: 2px !important;\n}\n.default-icon-wrapper[data-v-2962b9b9] {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n}\n.default-left-icon[data-v-2962b9b9] {\n  display: inline-flex;\n  height: 18px;\n  width: 18px;\n  background-position: center;\n  background-size: contain;\n}\n.default-icon-separator[data-v-2962b9b9] {\n  margin: 0 15px;\n  height: 15px;\n  border-left: 1px solid #e8e8e8;\n}\n.right[data-v-2962b9b9] {\n  width: 3rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.large[data-v-2962b9b9] {\n  height: 60px;\n  padding: 12px 20px 10px 12px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
