var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swap-tree-image-wrapper" },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loading-wrapper" },
            [
              _c("BaseLoadingSpinner", {
                attrs: { "with-overlay": false, size: 60 },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.svg
        ? _c("div", {
            staticClass: "image",
            domProps: { innerHTML: _vm._s(_vm.svg) },
          })
        : _c("transition", { attrs: { name: "shuffle", appear: "" } }, [
            _c("div", {
              staticClass: "image",
              domProps: { innerHTML: _vm._s(_vm.svg) },
            }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }