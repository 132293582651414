var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      [
        _c(
          "DefaultModal",
          { attrs: { "blur-bg": true, "show-close-btn": false } },
          [
            _vm.success
              ? _c("template", { slot: "body" }, [
                  _c("div", { staticClass: "message-wrapper" }, [
                    _c("h4", [_vm._v("Lösenordet är ändrat")]),
                    _c("p", [
                      _c(
                        "a",
                        { staticClass: "link", attrs: { href: "/logga-in" } },
                        [_vm._v("Klicka här för att logga in.")]
                      ),
                    ]),
                  ]),
                ])
              : _vm.error
              ? _c("template", { slot: "body" }, [
                  _c("div", { staticClass: "message-wrapper" }, [
                    _c("h4", [_vm._v("Oops!")]),
                    _c("p", [
                      _vm._v(
                        " Återställningslänken är antingen redan använd, eller så är den felaktig. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Vänligen kontakta er kontaktperson hos oss för att få en ny återställningslänk, "
                      ),
                      _c(
                        "a",
                        { staticClass: "link", attrs: { href: "/logga-in" } },
                        [_vm._v("eller gå till inloggningen.")]
                      ),
                    ]),
                  ]),
                ])
              : _c("template", { slot: "body" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.changePassword()
                        },
                      },
                    },
                    [
                      _c("h4", [_vm._v("Välj nytt lösenord")]),
                      _c(
                        "div",
                        { staticClass: "login-wrapper" },
                        [
                          _c("DefaultInput", {
                            attrs: {
                              name: "password",
                              disabled: _vm.changingPassword,
                              placeholder: "Nytt lösenord",
                              type: "password",
                            },
                            on: { clear: _vm.clearPassword },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _c("p", { staticClass: "msg" }, [
                            _vm.password
                              ? _c("span", [
                                  _vm.passwordErrors.includes("uppercase")
                                    ? _c("span", [
                                        _vm._v(
                                          "Lösenordet måste innehålla minst en stor bokstav. "
                                        ),
                                      ])
                                    : _vm.passwordErrors.includes("lowercase")
                                    ? _c("span", [
                                        _vm._v(
                                          "Lösenordet måste innehålla minst en liten bokstav. "
                                        ),
                                      ])
                                    : _vm.passwordErrors.includes("digits")
                                    ? _c("span", [
                                        _vm._v(
                                          "Lösenordet måste innehålla minst siffra. "
                                        ),
                                      ])
                                    : _vm.passwordErrors.includes("min")
                                    ? _c("span", [
                                        _vm._v(
                                          "Lösenordet måste minst 8 tecken. "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                          _c("DefaultInput", {
                            attrs: {
                              name: "passwordRepeat",
                              disabled: _vm.changingPassword,
                              placeholder: "Repetera nytt lösenord",
                              type: "password",
                            },
                            on: { clear: _vm.clearPasswordRepeat },
                            model: {
                              value: _vm.passwordRepeat,
                              callback: function ($$v) {
                                _vm.passwordRepeat = $$v
                              },
                              expression: "passwordRepeat",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "msg" }, [
                        _vm.errorMessage
                          ? _c("span", { staticClass: "text-error" }, [
                              _vm._v(_vm._s(_vm.errorMessage)),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "login-button" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                type: "submit",
                                "is-loading": _vm.changingPassword,
                                "is-disabled": _vm.changingPassword,
                                "is-fullwidth": false,
                                "is-rounded": true,
                              },
                            },
                            [_vm._v("Ändra lösenord")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }