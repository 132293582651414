import http from '@/services/http';

async function getTags(ctx) {
  const tags = await http.getAvailableTags();
  ctx.commit('setTags', tags);
  ctx.commit('applications/setFilterTags', tags, { root: true });
}

async function addTag(ctx, tagId) {
  await http.addTag(ctx.state.activeApplicationId, tagId);
  ctx.commit(
    'applications/addApplicationTag',
    { applicationId: ctx.state.activeApplicationId, tagId },
    { root: true }
  );
}

async function removeTag(ctx, tagId) {
  await http.removeTag(ctx.state.activeApplicationId, tagId);
  ctx.commit(
    'applications/removeApplicationTag',
    { applicationId: ctx.state.activeApplicationId, tagId },
    { root: true }
  );
}

export default {
  getTags,
  addTag,
  removeTag
};
