<template>
  <div
    class="applications-row"
    :class="{ 'applications-row-2-columns': twoColumns }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    twoColumns: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.applications-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  margin: 0 auto;
}
.applications-row-2-columns {
  width: 680px;
}
</style>
