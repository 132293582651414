var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultPage",
    [
      _c(
        "FilterMain",
        {
          attrs: { value: _vm.filterQuery },
          on: { input: _vm.debouncedSearch, clear: _vm.clearInputSearch },
          model: {
            value: _vm.filterQuery,
            callback: function ($$v) {
              _vm.filterQuery = $$v
            },
            expression: "filterQuery",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "filter-btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.filterModalVisible = true
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/svgs/icons/sliders.svg"),
                  alt: "Sliders icon",
                },
              }),
              _c("p", [_vm._v("Filtrera")]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "applications-view-wrapper" },
        [
          _vm.isLoadingApps
            ? _c(
                "div",
                { staticClass: "loading-wrapper" },
                [
                  _c("BaseLoadingSpinner", {
                    attrs: { "with-overlay": false, size: 60 },
                  }),
                ],
                1
              )
            : [
                _c(
                  "section",
                  { staticClass: "applications-wrapper" },
                  [
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Inkommande",
                        status: "new",
                        applications: _vm.applicationsNew,
                        "container-style": {
                          opacity: 0.5,
                        },
                      },
                    }),
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Fatta beslut",
                        status: "waiting",
                        applications: _vm.applicationsWaiting,
                      },
                    }),
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Pågående",
                        status: "ongoing",
                        applications: _vm.applicationsOngoing,
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c("router-view"),
          _vm.filterModalVisible
            ? _c("FilterModal", {
                attrs: { apps: _vm.filteredAhApps, "show-swap-filter": false },
                on: {
                  close: function ($event) {
                    _vm.filterModalVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }