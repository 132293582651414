var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    { on: { close: _vm.closeModal } },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Uppladdade filer")])],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.deleteFileError
            ? _c("ErrorModal", {
                on: {
                  close: function ($event) {
                    _vm.deleteFileError = false
                  },
                },
              })
            : !_vm.isUploadingFiles && _vm.failedUploads.length > 0
            ? _c("ErrorModal", {
                attrs: {
                  title: "Notera",
                  message: `Det gick inte att ladda upp ${_vm.readableFileNames(
                    _vm.failedUploads
                  )}.`,
                },
                on: {
                  close: function ($event) {
                    _vm.failedUploads = []
                  },
                },
              })
            : _vm.fileToBeDeleted
            ? _c("DeleteFileModal", {
                attrs: {
                  "file-name": _vm.fileToBeDeleted.fileName,
                  "is-uploader":
                    _vm.fileToBeDeleted.uploadedBy === _vm.account.userId,
                },
                on: {
                  confirm: function ($event) {
                    return _vm.deleteFile(_vm.fileToBeDeleted.fileName)
                  },
                  close: function ($event) {
                    _vm.fileToBeDeleted = null
                  },
                },
              })
            : _vm.notAcceptedModalVisible
            ? _c("FilesNotAcceptedModal", {
                attrs: { files: _vm.acceptedFiles },
                on: {
                  close: function ($event) {
                    _vm.notAcceptedModalVisible = false
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "wrapper" }, [
            _vm.isUploadingFiles
              ? _c("div", { staticClass: "spinner-container" }, [
                  _c("div", { staticClass: "spinner-box" }, [
                    _c(
                      "div",
                      { staticClass: "spinner" },
                      [_c("BaseSpinner")],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "Laddar upp " + _vm._s(_vm.uploadedFilesInfo) + " filer"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("section", { staticClass: "upload-container" }, [
              _c("input", {
                ref: "fileInput",
                attrs: {
                  id: "file",
                  multiple: "",
                  hidden: "",
                  disabled: _vm.isUploadingFiles,
                  accept: _vm.acceptedFiles,
                  type: "file",
                },
                on: {
                  change: function ($event) {
                    return _vm.checkFilesBeforeUpload($event.target.files)
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "upload-area",
                  class: {
                    dragging: _vm.dragging,
                    disabled: _vm.isUploadingFiles,
                  },
                  attrs: { for: "file", draggable: "" },
                  on: {
                    dragover: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = true
                    },
                    dragleave: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = false
                    },
                    drop: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = false
                      _vm.checkFilesBeforeUpload($event.dataTransfer.files)
                    },
                  },
                },
                [
                  _c("BaseImage", {
                    staticClass: "upload-image no-pointer-events",
                    attrs: { url: _vm.svgs.ICONS.ADD_DOCUMENT },
                  }),
                  _c("p", { staticClass: "upload-text no-pointer-events" }, [
                    _vm._v(" Släpp filen här eller klicka för att ladda upp "),
                    _c("span", { staticClass: "text-light" }, [
                      _vm._v("(" + _vm._s(_vm.readableAcceptedFiles) + ")"),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _vm.filesFromId.length
              ? _c(
                  "div",
                  {
                    staticClass: "file-browser-wrapper",
                    class: { "is-loading": _vm.isUploadingFiles },
                  },
                  [
                    _c(
                      "div",
                      _vm._l(_vm.filesFromId, function (file) {
                        return _c(
                          "transition-group",
                          {
                            key: file.fileName,
                            attrs: { tag: "div", name: "shuffle", appear: "" },
                          },
                          [
                            _c(
                              "section",
                              {
                                key: file.fileName,
                                staticClass: "folder-row",
                                class: {
                                  isRemoving: _vm.filesBeingRemoved.includes(
                                    file.fileName
                                  ),
                                },
                              },
                              [
                                _c("BaseImage", {
                                  staticClass: "icon",
                                  attrs: {
                                    url: _vm.docTypeUtils.getFileIcon(
                                      file.fileName
                                    ),
                                  },
                                }),
                                _c("div", { staticClass: "folder-info" }, [
                                  _c("div", { staticClass: "folder-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.docTypeUtils.getFilename(
                                            file.fileName
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "file-meta" }, [
                                    _c("span", { staticClass: "file-type" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.docTypeUtils.getFileExtension(
                                            file.fileName
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      ", " +
                                        _vm._s(
                                          _vm.docTypeUtils.getHumanFileSize(
                                            file.fileSize
                                          )
                                        ) +
                                        " / "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "file-link delete-link",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.filesBeingRemoved.includes(
                                              file.fileName
                                            ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.fileToBeDeleted = file
                                          },
                                        },
                                      },
                                      [_vm._v(" Radera ")]
                                    ),
                                    _vm._v(" / "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "file-link download-link",
                                        attrs: {
                                          href: _vm.getDocumentLink(
                                            file.fileName
                                          ),
                                          target: "_blank",
                                          disabled:
                                            _vm.filesBeingRemoved.includes(
                                              file.fileName
                                            ),
                                        },
                                      },
                                      [_vm._v("Ladda ner")]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("p", { staticClass: "info-text" }, [
            _vm._v(
              " OBS! Filer som laddas upp är endast synliga för denna hyresvärd. "
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: { click: _vm.closeModal },
            },
            [_vm._v("Tillbaka")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }