<template>
  <div class="person">
    <div class="person-info-wrapper">
      <div>
        <h3 class="name capitalize-first">
          {{ name }}
        </h3>
        <small v-if="currentIndex === 0" class="name-small">
          (Förstahandshyresgäst)</small
        >
        <small v-else class="name-small"> (Medhyresgäst)</small>
        <p class="address">
          {{ address }}
        </p>
      </div>
    </div>
    <div class="pointer">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      default: -1
    }
  }
};
</script>

<style scoped>
.person {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.person a:hover {
  cursor: pointer;
}
.person > h3 {
  display: block;
  font-size: 18px;
  color: var(--color-dark-blue);
}
.person .pointer {
  cursor: pointer;
  position: relative;
}
.person .pointer .icon {
  display: inline-flex;
  align-items: flex-end;
  height: 50px;
  width: 50px;
}
.person .pointer .lb-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: 1;
  background-size: 16px 16px;
}
.person-info-wrapper {
  display: inline-flex;
  flex-grow: 1;
}
</style>
