var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "text-icon" }, [
    _vm.iconUrl
      ? _c(
          "span",
          { staticClass: "icon-wrapper", class: [_vm.size] },
          [
            _c("BaseImage", {
              staticClass: "icon",
              attrs: { url: _vm.iconUrl },
            }),
            _vm._t("icon"),
          ],
          2
        )
      : _vm._e(),
    _c("span", { staticClass: "text" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }