var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application" },
    [
      _vm._t("top-content"),
      _c(
        "div",
        { staticClass: "application-card", class: { "flex-row": _vm.flex } },
        [_c("div", { staticClass: "card-content" }, [_vm._t("default")], 2)]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }