<template>
  <DefaultModal @close="closeModal">
    <template slot="header">
      <DefaultModalTitle>Hantera taggar</DefaultModalTitle>
    </template>

    <template slot="body">
      <ul class="tag-list">
        <li v-for="tag in tags" :key="tag.tagId">
          <BaseButton
            class="tag-button"
            :is-disabled="isSaving"
            :is-rounded="true"
            :is-default="!applicationTags.includes(tag.tagId)"
            :is-dark="true"
            @click="handleTagClick(tag.tagId)"
            >{{ tag.tagName }}</BaseButton
          >
        </li>
      </ul>
    </template>

    <template slot="footer">
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="closeModal"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import svgs from '@/utils/icon-constants';

import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'TagsModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  data() {
    return {
      svgs,
      isSaving: false
    };
  },

  computed: {
    ...mapState({
      tags: state => state.tags.tags,
      activeApplicationId: state => state.tags.activeApplicationId,
      applications: state => state.applications.apps
    }),
    applicationTags() {
      return (
        this.applications.find(app => app.id === this.activeApplicationId)
          ?.tags || []
      );
    }
  },

  methods: {
    ...mapMutations({
      toggleTagModal: 'tags/toggleTagModal'
    }),
    ...mapActions({
      addTag: 'tags/addTag',
      removeTag: 'tags/removeTag'
    }),
    closeModal() {
      this.toggleTagModal('');
    },
    async removeOtherTags(tags) {
      await Promise.all(tags.map(async tagId => this.removeTag(tagId)));
    },
    async handleTagClick(tagId) {
      if (this.applicationTags.includes(tagId)) {
        await this.removeTag(tagId);
      } else {
        const oldTags = this.applicationTags;
        await this.removeOtherTags(oldTags);
        await this.addTag(tagId);
      }
    }
  }
};
</script>

<style scoped>
.tag-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.tag-button {
  border-width: 2px;
  border-style: solid;
}
</style>
