var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-wrapper" },
    [
      _vm.showTagButton
        ? _c("TagButton", { attrs: { "application-id": _vm.applicationId } })
        : _vm._e(),
      _c(
        "router-link",
        {
          staticClass: "application-wrapper-link",
          class: {
            approved: _vm.status === _vm.statusTypes.APPROVED,
            rejected: _vm.status === _vm.statusTypes.REJECTED,
            aborted: _vm.status === _vm.statusTypes.ABORTED,
            new: _vm.isNew,
          },
          attrs: { to: _vm.to },
        },
        [
          _c("div", [
            _c("h3", { staticClass: "application-title" }, [
              _vm._v(" " + _vm._s(_vm.type) + " "),
              _vm.subtitle
                ? _c("span", { class: _vm.subtitleClass }, [
                    _vm._v(_vm._s(_vm.subtitle)),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "info-wrapper" }, [_vm._t("body")], 2),
          ]),
          _c(
            "section",
            { staticClass: "application-footer light" },
            [
              _c(
                "div",
                { staticClass: "application-footer-info" },
                [
                  _vm._t("footer"),
                  _vm.statusDate
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.getStatusText(_vm.status)) + " "
                        ),
                        _c("b", [_vm._v(" " + _vm._s(_vm.statusDate) + " ")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "UserIcon",
                {
                  staticClass: "user-icon",
                  attrs: {
                    "first-name": _vm.fullName,
                    "last-name": _vm.lastName,
                    "show-icon": !_vm.fullName && !_vm.lastName,
                  },
                },
                [_vm.unreadNotes > 0 ? _c("NotificationSymbol") : _vm._e()],
                1
              ),
            ],
            1
          ),
          _c("TagsContainer", { attrs: { tags: _vm.applicationTags } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }