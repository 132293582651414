<template>
  <div class="input-wrapper">
    <input
      v-bind="$attrs"
      :name="label"
      :type="$attrs.type || 'text'"
      :value="strValue"
      :disabled="$attrs.disabled"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    strValue() {
      // Autoconverts numbers to strs, use this instead of value prop
      // since .length checks wont work on numbers and validation fails
      if (this.value === null) {
        return '';
      }
      return `${this.value}`;
    }
  }
};
</script>

<style scoped>
.input-wrapper {
  margin-top: auto;
  position: relative;
  border-radius: 10px;
  width: 100%;
  color: var(--color-dark-blue);
  font-size: 1.4rem;
  line-height: 1.5;
}

input {
  border-radius: 10px;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  outline: 0px;
  appearance: none;
  width: 100%;
  padding: 0.6rem 1.8rem;
  border: 2px solid transparent;
  background-color: var(--color-light-grey);
}

input:focus {
  background-color: #fff;
  border-color: var(--color-blue);
}
input[disabled] {
  cursor: not-allowed;
  color: var(--color-disabled);
}

/* Fixes iOS input[type="date"] height collapsing */
input::-webkit-date-and-time-value {
  height: 1.5em;
}

::placeholder {
  color: var(--color-dark-blue);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--color-dark-blue);
}

::-ms-input-placeholder {
  color: var(--color-dark-blue);
}

input[disabled]::placeholder {
  color: var(--color-disabled);
}
input[disabled] ::-ms-input-placeholder {
  color: var(--color-disabled);
}
input[disabled] :-ms-input-placeholder {
  color: var(--color-disabled);
}

/* placeholder date specific */
input[type='date']::-webkit-datetime-edit-text,
input[type='date']::-webkit-datetime-edit-month-field,
input[type='date']::-webkit-datetime-edit-day-field,
input[type='date']::-webkit-datetime-edit-year-field {
  color: var(--color-dark-blue);
}
/* date highlight */
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: var(--color-blue);
  color: white;
}
</style>
