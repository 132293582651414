var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.apartmentInfoModal
        ? _c(
            "DefaultModal",
            {
              on: {
                close: function ($event) {
                  _vm.apartmentInfoModal = ""
                },
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [_c("DefaultModalTitle", [_vm._v("Annat fastighetsinnehav")])],
                1
              ),
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "apartmentInfo-content" }, [
                  _vm._v(_vm._s(_vm.apartmentInfoModal)),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("ul", [
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "has-error": !_vm.hasResided(_vm.part),
                  "checkbox-name": "ACCOMONDATION_TIME",
                  "hide-icon": _vm.hideIcon,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "ACCOMONDATION_TIME",
                      checked: $event,
                    })
                  },
                },
              },
              [_vm._v(" Bosatt: " + _vm._s(_vm.getResidedString(_vm.part)))]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "has-error": !_vm.hasAccomondations(
                    _vm.part.currentAccommodation
                  ),
                  "checkbox-name": "ACCOMONDATION_CURRENT",
                  "hide-icon": _vm.hideIcon,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "ACCOMONDATION_CURRENT",
                      checked: $event,
                    })
                  },
                },
              },
              [
                _vm._v(
                  " Boende idag: " +
                    _vm._s(
                      _vm.getAccomondationStr(_vm.part.currentAccommodation)
                    )
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "MIN_UC",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "MIN_UC",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "MIN_UC")
                  },
                },
              },
              [_vm._v(" UC ")]
            ),
            _vm.showTooltip
              ? _c("Tooltip", {
                  attrs: { step: "openFiles", position: "right" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "RENTAL_BASE",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "RENTAL_BASE",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "RENTAL_BASE")
                  },
                },
              },
              [_vm._v(" Hyra ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "RENTAL_AGREEMENT",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "RENTAL_AGREEMENT",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "RENTAL_AGREEMENT")
                  },
                },
              },
              [_vm._v(" Hyresavtal ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "ID_PROOF",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "ID_PROOF",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "ID_PROOF")
                  },
                },
              },
              [_vm._v(" Familjebevis ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "INCOME_BASE",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "INCOME_BASE",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "INCOME_BASE")
                  },
                },
              },
              [_vm._v(" Intyg på inkomst ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "has-error": !_vm.part.members[0].swapReason,
                  "checkbox-name": "REASON",
                  "hide-icon": _vm.hideIcon,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "REASON",
                      checked: $event,
                    })
                  },
                },
              },
              [_vm._v(" Bytesskäl ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "list-items" },
          [
            _c(
              "SwapApplicationListCheck",
              {
                attrs: {
                  part: _vm.part,
                  "is-disabled": _vm.isDisabled,
                  "is-commentable": true,
                  "checkbox-name": "OTHER_DOCUMENTS",
                  "hide-icon": _vm.hideIcon,
                  documents:
                    _vm.documentsCollections[
                      _vm.getFullIdPath(_vm.part, _vm.documentsCollections)
                    ],
                  "application-status": _vm.applicationStatus,
                },
                on: {
                  checksChange: function ($event) {
                    return _vm.$emit("checksChange", {
                      checkboxType: "OTHER_DOCUMENTS",
                      checked: $event,
                    })
                  },
                  selectedComment: function ($event) {
                    return _vm.$emit("selectedComment", $event)
                  },
                  openFile: function ($event) {
                    return _vm.$emit("openFile", "OTHER_DOCUMENTS")
                  },
                },
              },
              [_vm._v(" Övriga dokument ")]
            ),
          ],
          1
        ),
        _vm.part.members[_vm.selectedMemberIdx]?.otherApartmentInfo
          ? _c("hr", { staticClass: "divider" })
          : _vm._e(),
        _vm.part.members[_vm.selectedMemberIdx]?.otherApartmentInfo
          ? _c(
              "li",
              { staticClass: "list-items" },
              [
                _c(
                  "SwapApplicationListCheck",
                  {
                    staticClass: "other-apartment-checkbox",
                    class: { disabled: _vm.isDisabled },
                    attrs: {
                      part: _vm.part,
                      "is-disabled": _vm.isDisabled,
                      "custom-icon": _vm.svgs.ICONS.INFO,
                      "disable-tooltip": true,
                      "checkbox-name": "OTHER_APARTMENT_INFO",
                      "hide-icon": _vm.hideIcon,
                    },
                    on: {
                      checksChange: function ($event) {
                        return _vm.$emit("checksChange", {
                          checkboxType: "OTHER_APARTMENT_INFO",
                          checked: $event,
                        })
                      },
                      selectedComment: function ($event) {
                        return _vm.$emit("selectedComment", $event)
                      },
                      didClick: function ($event) {
                        return _vm.openApartmentInfoModal(
                          _vm.part.members[_vm.selectedMemberIdx]
                            ?.otherApartmentInfo
                        )
                      },
                    },
                  },
                  [_vm._v(" Annat fastighetsinnehav ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }