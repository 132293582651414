<template>
  <BaseIconText :icon-url="!hideIcon ? iconUrl : ''" :title="iconTitle">
    <BaseCheckbox
      class="checkbox"
      :checked="isChecked"
      :is-disabled="isDisabled"
      @change="$emit('checked', !isChecked)"
    ></BaseCheckbox>

    <span
      :class="{
        'has-files': !isDisabled && hasFiles
      }"
      @click="handleClick"
      ><slot></slot
    ></span>

    <button
      v-if="comment"
      class="comment-icon"
      @click="$emit('selectedComment', comment)"
    >
      <BaseImage :url="svgs.ICONS.COMMENT"></BaseImage>
    </button>
  </BaseIconText>
</template>

<script>
import svgs from '@/utils/icon-constants';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'ApplicationListCheck',
  components: {
    BaseIconText,
    BaseImage,
    BaseCheckbox
  },

  props: {
    hideIcon: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    isChecked: {
      type: Boolean,
      required: true
    },
    iconUrl: {
      type: String,
      required: true
    },
    comment: {
      type: Object,
      default: null
    },
    hasFiles: {
      type: Boolean,
      required: true
    },
    iconTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svgs
    };
  },
  methods: {
    handleClick() {
      if (this.isDisabled) {
        return;
      }

      this.$emit('didClick');
      if (!this.hasFiles) {
        return;
      }
      this.$emit('openFile');
    }
  }
};
</script>

<style scoped>
.checkbox {
  margin-right: 0.5rem;
  font-size: 10px;
}

.comment-icon {
  display: inline-block;
  width: 2rem;
  margin-left: 1rem;
  height: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.comment-icon > * {
  height: 100%;
  width: 100%;
}

.has-files {
  text-decoration: underline;
}

.has-files:hover {
  cursor: pointer;
  color: var(--color-blue);
}
</style>
