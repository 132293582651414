<template>
  <DefaultBarChart :data="data" :loading="loading" />
</template>
<script>
import DefaultBarChart from '@/components/DefaultBarChart.vue';

export default {
  components: {
    DefaultBarChart
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    hadYearsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    data() {
      return {
        datasets: [
          {
            label: 'Antal personer',
            backgroundColor: '#c040ac',
            data: this.hadYearsList
          }
        ]
      };
    }
  }
};
</script>
