<template>
  <div v-if="ready" id="app" class="app-wrapper">
    <div v-if="devEnvironment" class="env-info">DEV</div>
    <div v-if="isAuthAndNotLogin" class="left">
      <Menu />
    </div>
    <ReleaseNotesModal
      v-if="shouldShowReleaseNotesModal"
      :release-notes-array="notes"
      @close="closeModal"
    />
    <TagsModal v-if="activeApplicationId" />
    <div :class="{ right: isAuthAndNotLogin }">
      <router-view />
      <portal-target name="modal" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ReleaseNotesModal from '@/components/ReleaseNotesModal.vue';
import TagsModal from '@/components/TagsModal.vue';
import notes from '@/releaseNotes/notes';
import Menu from '@/components/Menu.vue';

const RELEASE_NOTES_COOKIE_NAME = 'lastSeenReleaseNoteVersion';

export default {
  name: 'App',
  components: {
    ReleaseNotesModal,
    Menu,
    TagsModal
  },

  data() {
    return {
      notes,
      releaseNotesModalVisible: !localStorage.getItem(RELEASE_NOTES_COOKIE_NAME)
    };
  },

  computed: {
    ...mapState({
      authenticated: state => state.app.authenticated,
      ready: state => state.app.ready,
      activeApplicationId: state => state.tags.activeApplicationId
    }),
    devEnvironment() {
      if (process.env.NODE_ENV === 'production') {
        return false;
      }
      return true;
    },
    shouldShowReleaseNotesModal() {
      const lastVersion = this.lastInNotesArray();
      const hasSeenLastVersion =
        localStorage.getItem(RELEASE_NOTES_COOKIE_NAME) ===
        lastVersion.toString();
      return (
        this.releaseNotesModalVisible &&
        this.authenticated &&
        this.isAuthAndNotLogin &&
        !hasSeenLastVersion
      );
    },
    isAuthAndNotLogin() {
      return this.authenticated && this.$route.name !== 'login';
    }
  },

  async created() {
    await this.initiate();
    await this.getTags();
    this.checkIfUserHasSeenLastVersion();
  },

  methods: {
    ...mapActions({
      initiate: 'app/initiate',
      logout: 'app/logout',
      getTags: 'tags/getTags'
    }),
    lastInNotesArray() {
      return this.notes[this.notes.length - 1].version;
    },
    checkIfUserHasSeenLastVersion() {
      const lastVersion = this.lastInNotesArray();
      const hasSeenLastVersion =
        localStorage.getItem(RELEASE_NOTES_COOKIE_NAME) ===
        lastVersion.toString();
      if (!hasSeenLastVersion) {
        this.releaseNotesModalVisible = true;
      }
    },

    closeModal() {
      this.releaseNotesModalVisible = false;
      const lastVersion = this.lastInNotesArray();
      localStorage.setItem(RELEASE_NOTES_COOKIE_NAME, lastVersion.toString());
    }
  }
};
</script>

<style scoped>
.left {
  width: 25rem;
  background: #fff;
  min-height: 100vh;
}

.right {
  width: calc(90vw - 25rem);
}

.app-wrapper {
  display: flex;
}

.env-info {
  left: 0;
  background: red;
  font-size: 20px;
  z-index: 9999999999;
  position: fixed;
  bottom: 0;
  text-align: center;
  color: #fff;
  padding: 7px 17px 0px 5px;
  font-weight: 600;
  text-decoration: none;
  border-top-right-radius: 25px;
}

@media (max-width: 767px) {
  .env-info {
    font-size: 12px;
    padding: 6px 12px 0px 3px;
  }
}
</style>
