import svgs from './icon-constants';

export default {
  getDocTypeStr(typeEnum) {
    return (
      {
        MIN_UC: 'Min UC',
        INCOME_BASE: 'Inkomstunderlag',
        RENTAL_AGREEMENT: 'Hyresavtal',
        RENTAL_BASE: 'Hyresbevis',
        ID_PROOF: 'Familjebevis',
        OTHER_DOCUMENTS: 'Övriga dokument',
        other: 'Övrigt',
        anställningsbevis: 'Anställningsbevis',
        fullmakt: 'Fullmakt',
        resehandling: 'Resehandling',
        andrahandshyresavtal: 'Andrahandshyresavtal',
        'csn-intyg': 'CSN-intyg',
        onskat_datum: 'Önskar hyra ut',
        familjebevis: 'Familjebevis',
        köpekontrakt: 'Köpekontrakt',
        medlemsregister: 'Medlemsregister',
        registreringsintyg: 'Registreringsintyg',
        kontrakt: 'Kontrakt'
      }[typeEnum] || typeEnum
    );
  },

  getFileIcon(fileName) {
    if (fileName.includes('.pdf')) {
      return svgs.ICONS.PDF;
    }

    if (fileName.includes('.jpg') || fileName.includes('.jpeg')) {
      return svgs.ICONS.JPG;
    }

    return svgs.ICONS.JPG;
  },

  getFilename(name) {
    if (!name) {
      return 'undefined';
    }

    return decodeURIComponent(name.split('%26%23')[0]);
  },

  getFileExtension(name) {
    if (!name) {
      return 'undefined';
    }

    return name.split('%26%23')[0].split('.').slice(-1)[0];
  },

  getHumanFileSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB'];
    let i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(0)) + ' ' + sizes[i];
  }
};
