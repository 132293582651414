var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "outside-click",
          rawName: "v-outside-click",
          value: _vm.handleClose,
          expression: "handleClose",
        },
      ],
      staticClass: "items-list-outer-wrapper",
    },
    [
      _vm.listVisible
        ? _c("section", { staticClass: "items-list-wrapper" }, [
            _c(
              "ul",
              _vm._l(_vm.items, function (listItem, index) {
                return _c("li", { key: index, staticClass: "list-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "simple-button",
                      on: {
                        click: function ($event) {
                          _vm.$emit("click", listItem)
                          _vm.handleClose()
                        },
                      },
                    },
                    [
                      _vm.showContact
                        ? _c("UserIcon", {
                            staticClass: "mr-10",
                            attrs: {
                              "first-name": listItem.firstName,
                              "last-name": listItem.lastName,
                              "show-icon": !listItem.id,
                              "full-name-visible": true,
                            },
                          })
                        : _vm._e(),
                      _vm.textFunc
                        ? _c("span", [_vm._v(_vm._s(_vm.textFunc(listItem)))])
                        : !_vm.showContact
                        ? _c("span", [_vm._v(_vm._s(listItem))])
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "simple-button",
          on: {
            click: function ($event) {
              _vm.listVisible = !_vm.listVisible
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }