<template>
  <p>Loggar ut...</p>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  async created() {
    try {
      await this.logout();
    } catch {
      //do nothing
    }
  },

  methods: {
    ...mapActions({ logout: 'app/logout' })
  }
};
</script>
