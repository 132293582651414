var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      staticClass: "release-notes-modal-wrapper",
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Nyheter")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "body-wrapper" },
          _vm._l([..._vm.releaseNotesArray].reverse(), function (note, index) {
            return _c("section", { key: index, staticClass: "body-section" }, [
              _c("div", { staticClass: "modal-date-container" }, [
                _c("b", [_vm._v(" " + _vm._s(note.version) + " ")]),
              ]),
              _c("section", { staticClass: "modal-bullets-container" }, [
                note.introText
                  ? _c("p", { staticClass: "text text--intro" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(note.introText) },
                      }),
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "modal-bullet-list" },
                  _vm._l(note.bullets, function (bullets, i) {
                    return _c("li", { key: i }, [
                      _c("span", { domProps: { innerHTML: _vm._s(bullets) } }),
                    ])
                  }),
                  0
                ),
                note.subBullets
                  ? _c("span", [
                      _c(
                        "ul",
                        { staticClass: "sub-bullet-list" },
                        _vm._l(note.subBullets, function (subBullet, subIndex) {
                          return _c("li", {
                            key: subIndex,
                            domProps: { innerHTML: _vm._s(subBullet) },
                          })
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
              note.text
                ? _c("p", { staticClass: "text" }, [_vm._v(_vm._s(note.text))])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Stäng")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }