import { APP_NAMES } from '@/utils/constants';

const skandiaLandlordIdArr = [169290, 169296, 169297];

export default {
  // isMobile: state => state.screenSize === 'MOBILE',
  // isSmallDesktop: state => state.screenSize === 'DESKTOP_SMALL',
  // isDesktop: state => state.screenSize === 'DESKTOP',
  tooltips: state => state.tooltips,
  ahAccess: state => state.account?.ahAccess || false,
  baAccess: state => state.account?.baAccess || false,
  brf: state => state.account?.brf || false,
  activeMenu: state => {
    if (!state.account?.ahAccess) {
      return state.baMenu;
    }
    if (state.activeApp === APP_NAMES.ANDRAHAND) {
      return state.ahMenu;
    }
    return state.baMenu;
  },
  isSkandia(state) {
    const skandiaLandlordIds = skandiaLandlordIdArr;

    if (skandiaLandlordIds.includes(state.account.landlordId)) {
      return true;
    }
    return false;
  }
};
