var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LineBase", {
    staticClass: "chart",
    attrs: { options: _vm.chartOptions, data: _vm.data },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }