<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>
        <template v-if="currentModal !== 'TENANT'">Filer</template>
        <template v-else>Filer / {{ applicantName }}</template>
      </DefaultModalTitle>
    </template>

    <template slot="body">
      <GeneratePdfModal
        v-if="generatePdfVisible && !!documentsCollections"
        :application-id="applicationId"
        :documents-collections="documentsCollections"
        :landlord-files="filesFromId"
        :has-scrive-document="hasScriveDocument"
        :is-ah="true"
        @close="generatePdfVisible = false"
      ></GeneratePdfModal>
      <AdditionalFilesModal
        v-if="additionalFilesModalVisible"
        :application-id="applicationId"
        @close="additionalFilesModalVisible = false"
      ></AdditionalFilesModal>
      <div class="file-browser-wrapper">
        <div class="file-browser-scroll">
          <a
            v-if="
              currentModal !== 'TENANT' && hasScriveDocument && !selectedType
            "
            :href="scriveLink"
            class="folder-name"
            target="_blank"
          >
            <div class="folder-row">
              <BaseImage :url="svgs.ICONS.PDF" class="icon"></BaseImage>
              <div class="folder-info">Signerad ansökan</div>
            </div>
          </a>
          <div v-if="selectedApplicantId !== null">
            <transition-group
              v-for="(file, idx) in documentsByApplicantId"
              :key="idx"
              name="shuffle"
              appear
            >
              <button
                :key="file.fileName"
                class="folder-row"
                @click="handleOpenFile(file.uuid)"
              >
                <BaseImage
                  :url="docTypeUtils.getFileIcon(file.fileName)"
                  class="icon"
                ></BaseImage>
                <div class="folder-info">
                  <div class="folder-name">
                    {{ docTypeUtils.getFilename(file.fileName) }}
                  </div>
                  <div class="file-meta">
                    {{ docTypeUtils.getDocTypeStr(file.type) }} /
                    <span class="file-type">{{
                      docTypeUtils.getFileExtension(file.fileName)
                    }}</span
                    >,
                    {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                    /
                    <span class="fake-link">Ladda ner</span>
                  </div>
                </div>
              </button>
            </transition-group>
          </div>
          <div v-else-if="currentModal === 'TENANT'">
            <transition-group
              v-for="(file, idx) in files"
              :key="idx"
              name="shuffle"
              appear
            >
              <button
                :key="file.fileName"
                target="_blank"
                class="folder-row"
                :disabled="downloadingIds.includes(file.uuid)"
                @click="handleOpenFile(file.uuid)"
              >
                <BaseImage
                  :url="docTypeUtils.getFileIcon(file.fileName)"
                  class="icon"
                ></BaseImage>
                <div class="folder-info">
                  <div class="folder-name">
                    {{ docTypeUtils.getFilename(file.fileName) }}
                  </div>
                  <div
                    v-if="!downloadingIds.includes(file.uuid)"
                    class="file-meta"
                  >
                    {{ docTypeUtils.getDocTypeStr(file.type) }} /
                    <span class="file-type">{{
                      docTypeUtils.getFileExtension(file.fileName)
                    }}</span
                    >,
                    {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                    /
                    <span class="fake-link">Ladda ner</span>
                  </div>
                  <div v-else class="folder-meta">Laddar ner...</div>
                </div>
              </button>
            </transition-group>
          </div>
          <template v-else>
            <button
              class="folder-row"
              type="button"
              @click.stop.prevent="additionalFilesModalVisible = true"
            >
              <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
              <div class="folder-info">
                <div class="folder-name">Uppladdade filer</div>
                <div class="file-meta">
                  {{ filesFromId.length }} uppladdade filer /
                  <span class="upload-files">Ladda upp</span>
                </div>
              </div>
            </button>
            <button
              type="button"
              class="folder-row"
              :disabled="!files?.length"
              @click.stop.prevent="currentModal = 'TENANT'"
            >
              <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
              <div class="folder-info">
                <div class="folder-name">{{ applicantName }}</div>
                <div class="file-meta">
                  {{ files?.length || 0 }} uppladdade filer
                </div>
              </div>
            </button>
          </template>
        </div>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        v-if="currentModal !== 'TENANT' && !selectedType"
        :is-rounded="true"
        :icon="svgs.ICONS.UPLOAD"
        @click.stop.prevent="additionalFilesModalVisible = true"
      >
        Ladda upp filer
      </BaseButton>

      <div
        v-if="currentModal !== 'TENANT' && !selectedType"
        class="popupModal-container"
      >
        <PopupModal
          v-if="popupModalVisible"
          :items="itemsInPopupModal"
          @close="popupModalVisible = false"
          @click="handlePopupClick"
        />
        <BaseButton
          :is-rounded="true"
          :icon="svgs.ICONS.DOWNLOAD"
          @click="popupModalVisible = !popupModalVisible"
        >
          Ladda ner filer
        </BaseButton>
      </div>

      <a v-if="currentModal === 'TENANT' && !selectedType" :href="getZipFile()">
        <BaseButton :is-rounded="true" :icon="svgs.ICONS.DOWNLOAD">
          Ladda ner som ZIP
        </BaseButton>
      </a>

      <BaseButton
        v-if="currentModal === 'TENANT' && !selectedType"
        :is-rounded="true"
        :is-default="true"
        @click.stop.prevent="currentModal = ''"
        >Tillbaka</BaseButton
      >

      <BaseButton
        v-if="currentModal !== 'TENANT'"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import docTypeUtils from '@/utils/docs-utils';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import GeneratePdfModal from '@/components/GeneratePdfModal.vue';
import AdditionalFilesModal from '@/components/AdditionalFilesModal.vue';
import PopupModal from '@/components/PopupModal.vue';
import { mapState } from 'vuex';
// import churnService from '@/utils/churn360';
// import { events, features } from '@/utils/trackingEvents';

export default {
  name: 'SubletDocumentsModal',
  components: {
    DefaultModal,
    BaseButton,
    BaseImage,
    GeneratePdfModal,
    AdditionalFilesModal,
    PopupModal,
    DefaultModalTitle
  },

  props: {
    applicantName: {
      type: String,
      default: ''
    },
    applicationId: {
      type: String,
      default: null
    },

    hasScriveDocument: {
      type: Boolean,
      default: false
    },

    selectedType: {
      type: String,
      default: null
    },
    selectedApplicantId: {
      type: String,
      default: null
    },

    files: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      svgs,
      docTypeUtils,
      additionalFilesModalVisible: false,
      popupModalVisible: false,
      generatePdfVisible: false,
      itemsInPopupModal: [
        {
          title: 'Ladda ner som ZIP',
          icon: svgs.ICONS.DOWNLOAD_GREY,
          content: '',
          cta: 'Klicka här för att ladda ner alla filer',
          type: 'DOWNLOAD_ZIP'
        },
        {
          title: 'Generera PDF',
          icon: svgs.ICONS.PDF_ALT_GREY,
          content: '',
          cta: 'Klicka här för att generera PDF',
          type: 'GENERATE_PDF'
        }
      ],
      downloadingIds: [],
      currentModal: ''
    };
  },

  computed: {
    ...mapState({
      landlordFiles: state => state.landlordFiles.files
    }),
    filesFromId() {
      return this.landlordFiles[this.applicationId] || [];
    },
    scriveLink() {
      return `${this.$http.apiEndpoint}/andrahand/portal/documents/scrive/${this.applicationId}`;
    },
    documentsByApplicantId() {
      return (
        this.files.filter(
          file => !this.selectedType || this.selectedType == file.type
        ) || []
      );
    },
    documentsCollections() {
      return {
        [this.applicantName]: this.documentsByApplicantId.map(doc => ({
          fileName: doc.fileName,
          fileSize: doc.fileSize,
          folder: doc.folder,
          type: doc.type,
          id: doc.uuid
        }))
      };
    }
  },

  methods: {
    trackFileDownload() {
      //TODO: Track?
      //churnService.track(events.FILES, features.FILES.DOWNLOAD_FILE);
    },
    trackAllFileDownload() {
      //TODO: Track?
      //churnService.track(events.FILES, features.FILES.DOWNLOAD_ALL_FILES);
    },

    async handleOpenFile(uuid) {
      const currentId = uuid;
      this.addDownloadingId(currentId);
      const file = await this.$http.getFile(this.applicationId, currentId);
      const buffer = file?.buffer;
      const type = file?.type || '';

      if (!buffer) {
        this.removeDownloadingId(currentId);
        return;
      }

      const uint8 = new Uint8Array(buffer.data);
      const blob = new Blob([uint8], { type });
      const fileURL = URL.createObjectURL(blob);
      this.trackFileDownload();
      window.open(fileURL);

      this.removeDownloadingId(currentId);
    },
    removeDownloadingId(id) {
      this.downloadingIds = this.downloadingIds.filter(x => x !== id);
    },
    addDownloadingId(id) {
      this.downloadingIds.push(id);
    },

    getZipFile(applicantId) {
      if (!applicantId) {
        return `${this.$http.apiEndpoint}/andrahand/portal/documents/zip/${this.applicationId}`;
      }

      return `${this.$http.apiEndpoint}/andrahand/portal/documents/zip/${this.applicationId}/${applicantId}`;
    },
    handlePopupClick(type) {
      switch (type) {
        case 'DOWNLOAD_ZIP':
          if (this.currentModal === '') {
            const zipFileUrl = this.getZipFile(null);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = zipFileUrl;
            anchor.download = 'file.zip';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            this.trackAllFileDownload();
          }

          break;
        case 'GENERATE_PDF':
          this.generatePdfVisible = true;
          break;
        default:
      }

      this.popupModalVisible = false;
    }
  }
};
</script>

<style scoped>
.file-browser-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}
.file-browser-scroll {
  max-height: 50vh;
  overflow-y: auto;
}
.icon {
  display: inline-flex;
  height: 36px;
  width: 45px;
  margin-right: 12px;
}
.folder-row {
  padding: 1.3rem 3rem;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: 0.2s;
}
.folder-row[disabled] {
  opacity: 0.6;
  cursor: default;
}
.folder-row:not([disabled]):hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.folder-info {
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
}
.folder-name {
  display: block;
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}
.file-meta {
  display: block;
  font-size: 1.4rem;
  color: #878b9d;
  font-weight: 600;
  line-height: 17px;
}
.file-meta .upload-files {
  color: var(--color-blue);
  text-decoration: underline;
  font-weight: 600;
}
.file-meta .file-type {
  text-transform: capitalize;
}
.file-meta .fake-link {
  color: var(--color-blue);
  text-decoration: underline;
}
.popupModal-container {
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
}
</style>
