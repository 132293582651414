<template>
  <div v-outside-click="handleClose" class="items-list-outer-wrapper">
    <section v-if="listVisible" class="items-list-wrapper">
      <ul>
        <li v-for="(listItem, index) of items" :key="index" class="list-item">
          <button
            class="simple-button"
            @click="
              $emit('click', listItem);
              handleClose();
            "
          >
            <UserIcon
              v-if="showContact"
              :first-name="listItem.firstName"
              :last-name="listItem.lastName"
              :show-icon="!listItem.id"
              :full-name-visible="true"
              class="mr-10"
            ></UserIcon>
            <span v-if="textFunc">{{ textFunc(listItem) }}</span>
            <span v-else-if="!showContact">{{ listItem }}</span>
          </button>
        </li>
      </ul>
    </section>

    <button class="simple-button" @click="listVisible = !listVisible">
      <slot></slot>
    </button>
  </div>
</template>

<script>
import UserIcon from '@/components/UserIcon.vue';
import outsideClick from '@/directives/outside-click';

export default {
  directives: { outsideClick },

  components: {
    UserIcon
  },

  props: {
    items: {
      type: Array,
      default: null
    },

    textFunc: {
      type: Function,
      default: null
    },

    showContact: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      listVisible: false
    };
  },

  methods: {
    handleClose() {
      this.listVisible = false;
    }
  }
};
</script>

<style scoped>
.items-list-wrapper {
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px 10px;
  min-width: 230px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.1);
}

.list-item {
  padding: 5px 10px;
}

.items-list-outer-wrapper {
  position: relative;
}

.mr-10 {
  margin-right: 10px;
}
</style>
