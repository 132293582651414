<template>
  <DefaultPieChart :data="data" :loading="loading" />
</template>
<script>
import DefaultPieChart from '@/components/DefaultPieChart.vue';

export default {
  components: {
    DefaultPieChart
  },
  props: {
    swapsWithOtherLandlordsAmount: {
      type: Number,
      default: 0
    },
    swapsWithinLandlordAmount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data() {
      return {
        labels: ['Flyttar till samma hyresvärd', 'Byter hyresvärd vid flytt'],
        datasets: [
          {
            backgroundColor: ['#40c07b', '#FF3843'],
            data: [
              this.swapsWithinLandlordAmount,
              this.swapsWithOtherLandlordsAmount
            ]
          }
        ]
      };
    }
  }
};
</script>
