<template>
  <DefaultLineChart :data="data" :loading="loading" />
</template>
<script>
import DefaultLineChart from '@/components/DefaultLineChart.vue';

export default {
  components: {
    DefaultLineChart
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    sentToHost: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sentToHostMapped() {
      if (!this.sentToHost?.length) {
        return {};
      }

      return {
        label: 'Antal som kommit in i fatta beslut',
        fill: false,
        backgroundColor: '#409fff',
        borderColor: '#409fff',
        pointHoverBorderColor: '#409fff',
        pointHoverBorderWidth: 14,
        pointBorderWidth: 7,
        pointStyle: 'rectRounded',
        borderWidth: 5,
        data: this.sentToHost
      };
    },
    data() {
      return {
        datasets: [this.sentToHostMapped]
      };
    }
  }
};
</script>
