<template>
  <ApplicationCard
    :type="type"
    :to="`/byten/alla/${application.id}`"
    :status="application.status"
    :subtitle="subtitle"
    :subtitle-class="subtitleClass"
    :is-new="isNew"
    :full-name="fullName"
    :last-name="lastName"
    :status-date="statusDate"
    :unread-notes="application?.unreadNotes"
    :closed-statuses="closedStatuses"
    :application-id="application.id"
    :application-tags="application.tags"
    :show-tag-button="true"
  >
    <template slot="body">
      <div class="application-card-info">
        <ul class="info-wrapper">
          <template v-for="applicant of parts">
            <li
              v-for="(location, i) of applicant"
              :key="`${location.id}-${i}`"
              class="address"
            >
              <div class="address-text">
                <span class="capitalize-first">
                  {{ memberNameOrFallback(location.members[0]) }}
                </span>
                <span class="light">
                  {{ location.address || '(Adress saknas)' }}</span
                >
                <p class="landlord">
                  ({{
                    location.landlord.name.length > 0
                      ? location.landlord.name
                      : 'Hyresvärd ej angivet'
                  }})
                </p>
              </div>
              <BaseImage
                v-if="location.members[0]?.comments.length"
                :title="`${memberNameOrFallback(
                  location.members[0]
                )} har en eller flera kommentarer`"
                :url="svgs.ICONS.COMMENT"
                class="has-comment-icon"
              ></BaseImage>
            </li>
          </template>
        </ul>
      </div>
    </template>

    <template slot="footer">
      <p>
        Vill byta:
        <b style="text-transform: capitalize">{{ swapDate }}</b>
      </p>
    </template>
  </ApplicationCard>
</template>

<script>
import BaseImage from '@/components/BaseImage.vue';
import ApplicationCard from '@/components/ApplicationCard.vue';

import svgs from '@/utils/icon-constants';

export default {
  components: {
    BaseImage,
    ApplicationCard
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    subtitleClass: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fullName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    swapDate: {
      type: String,
      default: ''
    },
    statusDate: {
      type: String,
      default: ''
    },
    parts: {
      type: Array,
      default: () => []
    },
    closedStatuses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      svgs
    };
  },
  methods: {
    memberNameOrFallback(member) {
      return member?.name ? member?.name : '(Namn saknas)';
    }
  }
};
</script>

<style scoped>
.application-card-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.2rem;
  color: var(--color-dark-blue);
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
  font-weight: 600;
}
.info-wrapper {
  padding-right: 2.4rem;
  backface-visibility: hidden;
}
.light {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 600;
}

.has-comment-icon {
  height: 2rem;
  width: 2rem;
  background-size: contain;
  margin-right: 1rem;
}

.landlord {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 400;
}
</style>
