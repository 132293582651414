<template>
  <ApplicationListCheck
    :hide-icon="hideIcon"
    :is-disabled="isDisabled"
    :is-checked="isChecked"
    :icon-url="customIcon || iconUrl"
    :comment="commentExists ? comment : null"
    :has-files="isCommentable && !missingDocuments && !isIncoming"
    :icon-title="iconTitle"
    @checked="$emit('checksChange', $event)"
    @selectedComment="$emit('selectedComment', $event)"
    @openFile="$emit('openFile')"
    @didClick="$emit('didClick')"
    ><slot />
  </ApplicationListCheck>
</template>

<script>
import svgs from '@/utils/icon-constants';
import ApplicationListCheck from '@/components/ApplicationListCheck.vue';

export default {
  name: 'SwapApplicationListCheck',
  components: {
    ApplicationListCheck
  },

  props: {
    part: {
      type: Object,
      default: null
    },
    isCommentable: {
      type: Boolean,
      default: false
    },
    checkboxName: {
      type: String,
      required: true
    },
    documents: {
      type: [Array, null],
      default: undefined
    },
    applicationStatus: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    disableTooltip: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    customIcon: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs
    };
  },

  computed: {
    editableType() {
      if (!this.isCommentable) {
        return null;
      }
      return this.checkboxName;
    },
    comment() {
      if (!this.member.comments) {
        return undefined;
      }

      return this.member.comments.find(
        doc => doc.documentType === this.editableType
      );
    },
    member() {
      if (!this.part) {
        return {};
      }

      return this.part.members[this.memberIndex];
    },

    missingDocuments() {
      if (this.documents === undefined) {
        return false;
      }

      if (!this.documents) {
        return true;
      }

      return !this.documents.filter(doc => {
        if (
          doc.type === this.editableType &&
          (doc.memberIndex === null || doc.memberIndex === this.partIndex)
        ) {
          return true;
        }
        return false;
      }).length;
    },

    isIncoming() {
      return this.applicationStatus === 'INCOMING';
    },

    iconTitle() {
      if (this.disableTooltip) {
        return '';
      }
      if (this.hasError || this.missingDocuments) {
        return 'Uppgifter har inte skickats in';
      } else if (this.editableType && this.commentExists) {
        return 'Det finns en kommentar angående dessa uppgifter';
      }

      return 'Uppgifter inskickade';
    },

    iconUrl() {
      if (this.hasError || this.missingDocuments) {
        return this.svgs.ICONS.YELLOW_CLOCK;
      } else if (this.editableType && this.commentExists) {
        return this.svgs.ICONS.CHECK_NOTE;
      }

      return this.svgs.ICONS.CHECK;
    },

    memberIndex() {
      return !this.part.activeMember ? 0 : this.part.activeMember;
    },

    partIndex() {
      const memberIdSplit = this.part.members[0].id.split('-');
      const partMemberIndex = Number(memberIdSplit[memberIdSplit.length - 1]);
      //If partMemberIndex 1 then it's two apartments on same applicant
      if (partMemberIndex === 1) {
        return partMemberIndex;
      }
      return this.memberIndex;
    },

    isChecked() {
      if (!this.member) {
        return false;
      }

      return this.member.checks.some(check => check === this.checkboxName);
    },

    commentExists() {
      if (!this.editableType) {
        return false;
      }

      return this.comment !== undefined;
    }
  }
};
</script>
