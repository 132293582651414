import http from './http';

async function getUserTutorialsSeen() {
  return await http.getTutorial('/tutorial');
}

async function addSeenTutorialStep(tutorialId) {
  return await http.putTutorial(`/tutorial`, { tutorialId });
}

export default {
  getUserTutorialsSeen,
  addSeenTutorialStep
};
