<template>
  <DefaultModal
    v-if="showRejectReasonModal"
    :show-close-btn="false"
    @close="rejectReasonModalVisible = false"
  >
    <template slot="header">
      <DefaultModalTitle>Nekat byte</DefaultModalTitle>
    </template>

    <template slot="body">
      <div class="reject-reason">
        <p class="explanation">Förklaring:</p>

        <span v-if="reason">"{{ reason }}"</span>
        <span v-else>(Förklaring saknas)</span>
        <p v-if="name">- {{ name }}</p>
        <p v-else>(Namn saknas)</p>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        :is-rounded="true"
        :is-default="true"
        @click="rejectReasonModalVisible = false"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import statusTypes from '@/utils/statusTypes';

export default {
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      reason: '',
      name: '',
      rejectReasonModalVisible: true
    };
  },
  computed: {
    showRejectReasonModal() {
      return (
        this.applicationStatus === statusTypes.REJECTED &&
        this.rejectReasonModalVisible
      );
    }
  },
  async mounted() {
    try {
      let notes = await this.$http.getNotes(this.applicationId, ['REJECTION']);
      const latestRejectNote = notes[notes.length - 1];
      if (latestRejectNote) {
        this.reason = latestRejectNote.text;
        this.name = `${latestRejectNote.firstName} ${latestRejectNote.lastName}, ${latestRejectNote.landlordName}`;
      } else {
        this.rejectReasonModalVisible = false;
      }
    } catch (error) {
      console.error(error);
      console.error('Something went wrong');
    }
  }
};
</script>

<style scoped>
.reject-reason {
  font-size: 1.8rem;
  font-weight: 600;
  width: 500px;
  max-width: 100%;
}

.reject-reason .explanation {
  color: var(--color-dark-blue);
}
</style>
