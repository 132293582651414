<template>
  <BaseModal v-bind="$attrs" v-on="$listeners">
    <div class="comment-modal">
      <h2>{{ name }}</h2>
      <div class="subject">
        Angående
        {{ docTypeUtils.getDocTypeStr(documentType) }}
      </div>
      <div class="content">{{ content }}</div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import docTypeUtils from '@/utils/docs-utils';

export default {
  name: 'CommentModal',
  components: {
    BaseModal
  },

  props: {
    name: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    documentType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      docTypeUtils
    };
  }
};
</script>

<style scoped>
.comment-modal {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: white;
  min-width: 500px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  padding: 20px 25px;
}

.comment-modal h2 {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.comment-modal .subject {
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
}

.comment-modal .content {
  margin: 15px 0px;
  font-size: 14px;
}
</style>
