export default {
  bind: function (el, binding, vnode) {
    el.escEvent = function (event) {
      let isEscape = false;
      if ('key' in event) {
        isEscape = event.key === 'Escape' || event.key === 'Esc';
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        el.dispatchEvent(new Event('esc'));
        if (binding.expression) vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('keydown', el.escEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('keydown', el.escEvent);
  }
};
