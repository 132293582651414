var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      directives: [
        {
          name: "outside-click",
          rawName: "v-outside-click",
          value: _vm.close,
          expression: "close",
        },
      ],
    },
    _vm._l(_vm.items, function (item) {
      return _c("li", { key: item.title, class: [item.theme] }, [
        _c("div", { staticClass: "icon-text-container" }, [
          _c("span", {
            staticClass: "icon-container",
            domProps: { innerHTML: _vm._s(item.icon) },
          }),
          _c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.title))]),
        ]),
        _c("p", { staticClass: "content" }, [_vm._v(_vm._s(item.content))]),
        _c(
          "button",
          {
            staticClass: "cta",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("click", item.type)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.cta) + " ")]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }