var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationCard",
    {
      attrs: {
        type: _vm.reasonTypeTitle,
        to: _vm.getPath,
        status: _vm.application.status,
        subtitle: _vm.subtitle,
        "subtitle-class": _vm.subtitleClass,
        "is-new": _vm.isNew,
        "full-name": _vm.fullName,
        "last-name": _vm.lastName,
        "status-date": _vm.statusDate,
        "unread-notes": _vm.application?.unreadNotes,
        "closed-statuses": _vm.closedStatuses,
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "application-card-info-container" }, [
          _c("div", { staticClass: "application-card-info" }, [
            _c("p", [
              _c("span", { staticClass: "capitalize-first" }, [
                _vm._v(_vm._s(_vm.currentTenantsNameGroup)),
              ]),
              _c("span", { staticClass: "light" }, [
                _vm._v(_vm._s(_vm.application.apartment?.address)),
              ]),
            ]),
            _vm.application.secondaryTenants[0]
              ? _c("p", [
                  _c("span", { staticClass: "capitalize-first" }, [
                    _vm._v(_vm._s(_vm.application.secondaryTenants[0].name)),
                  ]),
                  _c("span", { staticClass: "light" }, [
                    _vm._v(_vm._s(_vm.application.secondaryTenants[0].address)),
                  ]),
                ])
              : _vm._e(),
            _vm.application.secondaryTenants[1]
              ? _c("p", [
                  _c("span", { staticClass: "capitalize-first" }, [
                    _vm._v(_vm._s(_vm.application.secondaryTenants[1].name)),
                  ]),
                  _c("span", { staticClass: "light" }, [
                    _vm._v(_vm._s(_vm.application.secondaryTenants[1].address)),
                  ]),
                ])
              : _vm._e(),
            _c("p", [
              _vm._v(
                "Hyra: " + _vm._s(_vm.application.apartment?.rent) + " kr"
              ),
            ]),
            _c("p", [
              _vm._v(
                "Lägenhetsnr: " +
                  _vm._s(_vm.application.apartment?.apartmentNumber)
              ),
            ]),
            _c("p", [
              _vm._v(
                " Objektnr: " +
                  _vm._s(_vm.application.apartment?.objectNumber) +
                  " "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm.validDate(_vm.application.reason.rentFrom) &&
      _vm.validDate(_vm.application.reason.rentTo)
        ? _c("template", { slot: "footer" }, [
            _vm.application.landlordStatus === "APPROVED"
              ? _c("p", [
                  _vm._v(" Uthyrningsperiod: "),
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        new Date(_vm.application?.startDate).toLocaleDateString(
                          "sv-SE"
                        )
                      ) + " "
                    ),
                  ]),
                  _vm._v(" - "),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(_vm.application?.endDate).toLocaleDateString(
                            "sv-SE"
                          )
                        )
                    ),
                  ]),
                  _c("br"),
                  _vm._v(" ("),
                  _c("span", { staticClass: "days-left" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.daysLeftStr))]),
                    _vm._v(_vm._s(_vm.daysLeftMsg)),
                  ]),
                  _vm._v(") "),
                ])
              : _c("p", [
                  _vm._v(" Önskar hyra ut: "),
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        new Date(
                          _vm.application?.reason.rentFrom
                        ).toLocaleDateString("sv-SE")
                      ) + " "
                    ),
                  ]),
                  _vm._v(" - "),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(
                            _vm.application?.reason.rentTo
                          ).toLocaleDateString("sv-SE")
                        )
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }