<template>
  <DefaultModal class="release-notes-modal-wrapper" @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Nyheter</DefaultModalTitle>
    </template>

    <template slot="body">
      <div class="body-wrapper">
        <section
          v-for="(note, index) in [...releaseNotesArray].reverse()"
          :key="index"
          class="body-section"
        >
          <div class="modal-date-container">
            <b>
              {{ note.version }}
            </b>
          </div>
          <section class="modal-bullets-container">
            <p v-if="note.introText" class="text text--intro">
              <span v-html="note.introText" />
            </p>
            <ul class="modal-bullet-list">
              <li v-for="(bullets, i) in note.bullets" :key="i">
                <span v-html="bullets" />
              </li>
            </ul>
            <span v-if="note.subBullets">
              <ul class="sub-bullet-list">
                <li
                  v-for="(subBullet, subIndex) in note.subBullets"
                  :key="subIndex"
                  v-html="subBullet"
                />
              </ul>
            </span>
          </section>
          <p v-if="note.text" class="text">{{ note.text }}</p>
        </section>
      </div>
    </template>

    <template slot="footer">
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import outsideClick from '@/directives/outside-click';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'ReleaseNotesModal',
  directives: { outsideClick },
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton
  },
  props: {
    releaseNotesArray: {
      type: Array,
      default: () => {},
      required: true
    }
  }
};
</script>

<style scoped>
.release-notes-modal-wrapper :deep(.footer) {
  padding: 15px 35px;
}
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 250px;
  max-height: 40vh;
  max-width: 100%;
  width: 600px;
}
.body-wrapper .body-section .modal-date-container {
  width: 100%;
  font-size: 1.8rem;
  padding: 0 0 0.8rem 0;
}
.body-wrapper .body-section .modal-bullets-container {
  width: 100%;
}
.body-wrapper .body-section .modal-bullets-container .modal-bullet-list {
  font-size: 1.5rem;
  padding-left: 1.7rem;
  line-height: 1.5;
}
.body-wrapper .body-section .modal-bullets-container .modal-bullet-list > li {
  list-style: disc;
  margin-bottom: 0.8rem;
}
.body-wrapper
  .body-section
  .modal-bullets-container
  .modal-bullet-list
  > li:nth-last-child(1) {
  margin-bottom: 0;
}
.body-wrapper .body-section .modal-bullets-container .sub-bullet-list {
  font-size: 1.5rem;
  padding-left: 3.3rem;
  line-height: 1.5;
}
.body-wrapper .body-section .modal-bullets-container .sub-bullet-list > li {
  list-style: decimal;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}
.body-wrapper .body-section .text {
  font-size: 1.5rem;
  padding-left: 1.7rem;
  line-height: 1.5;
}
.body-wrapper .body-section .text--intro {
  margin-bottom: 0.8rem;
}
.body-wrapper .modal-button {
  padding: 2rem;
}
</style>
