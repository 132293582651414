var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "map-container" } }, [
    _vm.isLoading
      ? _c(
          "div",
          { attrs: { id: "map-loading-wrapper" } },
          [
            _c("BaseLoadingSpinner", {
              attrs: { "with-overlay": false, size: 60 },
            }),
          ],
          1
        )
      : _vm.hasError
      ? _c("div", { attrs: { id: "map-error-container" } }, [
          _c("p", [
            _vm._v(
              "Kunde inte visa kartan, en/flera av adresserna gick inte att hitta"
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", {
      class: {
        ready: _vm.mapIsReady,
      },
      attrs: { id: "map" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }