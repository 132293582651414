<template>
  <div ref="image" :class="classes" :style="spreadedStyles" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    url: {
      type: String,
      required: false,
      default: null
    },

    classes: {
      type: Array,
      default: () => [],
      required: false
    },

    styles: {
      type: Array,
      default: () => [],
      required: false
    }
  },

  computed: {
    spreadedStyles() {
      return [...this.styles];
    }
  },

  watch: {
    url(newUrl, oldUrl) {
      if (newUrl !== oldUrl && newUrl !== null) this.load();
    }
  },

  mounted() {
    // With browser cache, the image will only be downloaded once

    this.load();
  },

  methods: {
    load() {
      this.$nextTick(() => {
        const image = new Image();

        image.onload = () => {
          if (this.$refs.image && this.$refs.image.style) {
            this.$refs.image.style.backgroundImage = `url(${image.src})`;
            this.$refs.image.style.backgroundRepeat = 'no-repeat';
            this.$refs.image.style.backgroundPosition = 'center';
          }

          // Notify that the image is now visible in dom
          this.$emit('done');
        };

        image.src = this.url || '';
      });
    }
  }
};
</script>
