var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "app-wrapper", attrs: { id: "app" } },
        [
          _vm.devEnvironment
            ? _c("div", { staticClass: "env-info" }, [_vm._v("DEV")])
            : _vm._e(),
          _vm.isAuthAndNotLogin
            ? _c("div", { staticClass: "left" }, [_c("Menu")], 1)
            : _vm._e(),
          _vm.shouldShowReleaseNotesModal
            ? _c("ReleaseNotesModal", {
                attrs: { "release-notes-array": _vm.notes },
                on: { close: _vm.closeModal },
              })
            : _vm._e(),
          _vm.activeApplicationId ? _c("TagsModal") : _vm._e(),
          _c(
            "div",
            { class: { right: _vm.isAuthAndNotLogin } },
            [
              _c("router-view"),
              _c("portal-target", { attrs: { name: "modal" } }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }