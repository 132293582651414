var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 94.000000 92.000000",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform:
              "translate(0.000000,92.000000) scale(0.100000,-0.100000)",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M415 548 c-91 -35 -105 -47 -87 -76 10 -14 167 -72 197 -72 34 0 178\n55 188 71 6 9 8 23 4 31 -7 19 -159 78 -195 77 -15 -1 -63 -15 -107 -31z m173\n-17 c26 -10 59 -21 72 -23 45 -6 26 -29 -45 -53 -86 -30 -93 -30 -180 -1 -77\n26 -90 46 -35 57 19 4 51 15 70 23 46 20 60 20 118 -3z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M332 401 c-21 -12 4 -26 100 -58 l98 -31 90 32 c50 18 96 38 102 44\n22 22 -37 13 -117 -18 l-77 -29 -93 33 c-51 19 -98 31 -103 27z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M326 311 c-3 -5 0 -13 7 -18 18 -14 169 -63 194 -63 25 0 182 55 196\n69 25 25 -26 19 -107 -12 l-88 -34 -90 33 c-99 37 -104 38 -112 25z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }