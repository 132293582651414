<template>
  <span class="text-icon">
    <span v-if="iconUrl" class="icon-wrapper" :class="[size]">
      <BaseImage :url="iconUrl" class="icon"></BaseImage>
      <slot name="icon"></slot>
    </span>
    <span class="text"><slot></slot></span>
  </span>
</template>

<script>
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'BaseIconText',
  components: { BaseImage },
  props: {
    iconUrl: {
      type: [String, null],
      default: null
    },

    size: {
      type: [String, null],
      default: null
    }
  }
};
</script>

<style scoped>
.icon-wrapper {
  position: relative;
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper ~ .text {
  margin-left: 0.7rem;
}

.icon {
  height: 100%;
  width: 100%;
}

.text-icon {
  display: flex;
  align-items: center;
}

.larger {
  height: 2.4rem;
  width: 2.4rem;
}

.text {
  display: inline-flex;
  align-items: center;
}
</style>
