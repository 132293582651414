export const APP_NAMES = {
  ANDRAHAND: 'andrahand',
  BYTESANSOKAN: 'bytesansokan'
};
export const APP_PATHS = {
  ANDRAHAND: 'andrahand',
  BYTESANSOKAN: 'byten'
};

export default {
  APP_NAMES,
  APP_PATHS
};
