<template>
  <button
    :class="[
      'button',
      { 'is-fullwidth': isFullwidth },
      { 'is-disabled': isDisabled },
      { 'is-rounded': isRounded },
      { 'is-default': isDefault },
      { 'is-dark': isDark },
      { 'is-warning': isWarning },
      { 'is-loading': isLoading },
      ...classes
    ]"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <div class="inner-wrapper">
      <div v-if="isLoading" class="spinner">
        <BaseSpinner />
      </div>
      <BaseImage v-if="!!icon && !isLoading" :url="icon" class="icon" />
      <span :class="{ hidden: isLoading }"><slot /></span>
    </div>
  </button>
</template>

<script>
import BaseSpinner from '@/components/BaseSpinner.vue';
import BaseImage from '@/components/BaseImage.vue';

export default {
  name: 'BaseButton',
  components: {
    BaseSpinner,
    BaseImage
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    isDark: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
</script>

<style scoped>
.spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.button {
  background-color: var(--color-blue);
  -webkit-appearance: none;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  height: 4rem;
  padding: 0 2rem;
  outline: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.button:active {
  outline: none;
}
.button.is-loading {
  padding: 0 1rem;
}
.button.hover:hover {
  opacity: 0.8;
}

.is-fullwidth {
  width: 100%;
}

.is-secondary {
  background-color: #34c375;
}

.is-warning {
  background-color: #ff3843;
}

.is-dark {
  background-color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);
}

.is-default {
  background-color: #fff;
  border: 2px solid #878b9d;
  color: #878b9d;
}

.is-rounded {
  border-radius: 25px;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.icon-close {
  height: 12px;
  width: 12px;
  background-position: center;
  background-size: contain;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.inner-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 0;
}

.hidden {
  visibility: hidden;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
}
</style>
