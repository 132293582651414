<template>
  <span
    :style="{
      'background-color': themeColor || generateBgColor(tagName)
    }"
    >{{ tagName }}</span
  >
</template>

<script>
import { mapState } from 'vuex';
import generateHSL from '@/utils/generateHSL';

export default {
  props: {
    tagId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      availableTags: state => state.tags.tags
    }),
    tag() {
      return this.availableTags.find(tag => tag.tagId === this.tagId);
    },
    tagName() {
      return this.tag?.tagName || '';
    },
    themeColor() {
      return this.tag?.themeColor || '';
    }
  },
  methods: {
    generateBgColor(name) {
      return generateHSL(name, {
        hRange: [0, 400],
        sRange: [60, 100],
        lRange: [30, 60]
      });
    }
  }
};
</script>

<style scoped>
span {
  background-color: var(--color-dark-blue);
  border-radius: 5px;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  padding: 0.3rem 0.6rem 0.4rem;
  outline: none;
  text-align: center;
}
</style>
