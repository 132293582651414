function getQueryParams() {
  // Proxy solution from this so post https://stackoverflow.com/a/901144
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
}

function getLocalStorage(name) {
  const storage = localStorage.getItem(name);
  if (!storage || storage === 'null') {
    return null;
  }

  return storage;
}

export { getQueryParams, getLocalStorage };
