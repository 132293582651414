<template>
  <Bar :options="chartOptions" :data="data" class="chart" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement);

export default {
  components: {
    Bar
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          tooltip: {
            padding: 20,
            boxPadding: 5
          }
        },
        ...this.options
      };
    }
  }
};
</script>
<style scoped>
.chart {
  width: 100% !important;
  height: 100% !important;
}
</style>
