<template>
  <ErrorModal v-if="!isLoading && errorModalVisible" @close="$emit('close')" />
  <div v-else-if="isLoading" class="loading-wrapper">
    <BaseLoadingSpinner :with-overlay="false" />
  </div>
  <ApplicationContainerModal
    v-else-if="application && documentsCollections"
    v-esc-click="handleEscClick"
    v-on="$listeners"
  >
    <template slot="modals">
      <NotesModal
        v-if="currentModal === 'NOTES'"
        :unread-notes="application.unreadNotes"
        :application-id="application.id"
        :read-only-mode="hasRemovalTypeStatus"
        @close="currentModal = null"
        @activeTabChange="handleTabClick"
      />

      <DocumentsModal
        v-if="currentModal === 'DOCUMENTS'"
        :application-id="application.id"
        :parts="application.parts"
        :documents-collections="documentsCollections"
        :is-loading="!documentsCollections"
        :selected-applicant-id="selectedApplicantId"
        :selected-type="selectedDocumentType"
        :has-scrive-document="!!application.scriveId"
        :initial-member-index="initialMemberIndex"
        @close="
          currentModal = null;
          selectedApplicantId = null;
          selectedDocumentType = null;
          initialMemberIndex = -1;
        "
      ></DocumentsModal>
      <RejectModal
        v-if="currentModal === 'REJECT'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRejected="leaveApplication"
      />
      <AbortModal
        v-if="currentModal === 'ABORT'"
        :application-id="application.id"
        @close="currentModal = null"
        @onAborted="leaveApplication"
      />
      <RestoreModal
        v-if="currentModal === 'RESTORE'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRestored="leaveApplication"
      />

      <CancelRemovalModal
        v-if="currentModal === 'CANCEL_REMOVAL'"
        :application-id="application.id"
        @close="currentModal = null"
        @onCancelledDeletion="leaveApplication"
      />

      <NotAssignedModal
        v-if="currentModal === 'USER_NOT_ASSIGNED'"
        @close="currentModal = null"
        @confirm="currentModal = 'REMOVE'"
      />

      <RemoveModal
        v-if="currentModal === 'REMOVE'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRemoved="leaveApplication"
      ></RemoveModal>

      <ChangeToRejectModal
        v-if="currentModal === 'CHANGE_TO_REJECT'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRejected="leaveApplication"
      />

      <ApprovalModal
        v-if="currentModal === 'APPROVE'"
        :application-id="application.id"
        :parts="application.parts"
        @close="currentModal = null"
        @onApproved="leaveApplication"
      ></ApprovalModal>

      <ChangeToApprovalModal
        v-if="currentModal === 'CHANGE_TO_APPROVE'"
        :application-id="application.id"
        :parts="application.parts"
        @close="currentModal = null"
        @onApproved="leaveApplication"
      ></ChangeToApprovalModal>

      <CommentModal
        v-if="selectedComment !== null"
        :name="selectedComment.name"
        :document-type="selectedComment.comment.documentType"
        :content="selectedComment.comment.content"
        @close="selectedComment = null"
      >
      </CommentModal>

      <PartInfoModal
        v-if="selectedPartInfo !== null"
        :position="selectedPartInfoModalPosition"
        :content="selectedPartInfo"
        :application-id="application.id"
        @close="selectedPartInfo = null"
      ></PartInfoModal>
    </template>
    <template slot="header">
      <TabHeader
        :all-disabled="isAnimatingModalSize"
        :title="application.type"
        :subtitle="tabHeaderSubtitle"
        :application-status="application.status"
        :show-help-button="showHelpButton"
        :tabs="[
          {
            title: 'Bytesparter',
            active: activeTab === 1,
            tabLink: 1
          },
          {
            title: 'Visa bytesträd',
            active: activeTab === 2,
            disabled: isIncoming || isRemoved,
            tabLink: 2
          },
          {
            title: 'Visa karta',
            active: activeTab === 3,
            disabled: isIncoming || isRemoved,
            tabLink: 3
          }
        ]"
        @helpButtonClicked="handleHelpButtonClicked"
        @tabClick="setTab"
      />
    </template>
    <template slot="content">
      <div
        v-if="activeTab === 1"
        class="applications-row"
        :class="{
          'applications-row-2-columns': application.parts.length === 2
        }"
      >
        <div
          v-for="(part, idx) in application.parts"
          :key="idx"
          class="application"
        >
          <div class="person">
            <div class="person-info-wrapper">
              <div>
                <h3 class="name capitalize-first">
                  {{ part.members[0].name }}
                </h3>
                <p class="address">
                  {{ buildAddressStr(part) }}
                </p>
              </div>
            </div>
            <div v-if="!isIncoming && !isRemoved" class="pointer">
              <BaseImage
                :url="svgs.ICONS.CONTACT"
                class="icon"
                @click="
                  e => {
                    const x = e.clientX;
                    const y = e.clientY + 20;
                    openPartInfoModal(x, y, part);
                  }
                "
              ></BaseImage>
              <BaseImage
                v-if="isFromLb(application)"
                :key="`${application.id}-lb`"
                :url="svgs.ICONS.LB_LOGO"
                class="lb-icon"
              ></BaseImage>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === 1"
        class="applications-row"
        :class="{
          'applications-row-2-columns': application.parts.length === 2
        }"
      >
        <div
          v-for="(part, idx) in application.parts"
          :key="idx"
          class="application"
        >
          <div v-if="part.members.length > 1" class="member-selector">
            <a
              v-for="(member, memberIdx) in part.members"
              :key="memberIdx"
              :class="{
                selected: getSelectedMemberIndex(part) === memberIdx
              }"
              @click="selectMemberIndex(part, memberIdx)"
            >
              {{ getShortName(member.name) }}
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === 1"
        class="applications-row"
        :class="{
          'applications-row-2-columns': application.parts.length === 2
        }"
      >
        <div
          v-for="(part, idx) in application.parts"
          :key="idx"
          class="application"
        >
          <div class="application-card">
            <div class="card-content">
              <SwapCardContent
                :part="part"
                :show-tooltip="activeTab === 1 && idx === 0"
                :application-status="application.status"
                :documents-collections="documentsCollections"
                :selected-member-idx="getSelectedMemberIndex(part)"
                :is-disabled="hasRemovalTypeStatus"
                @checksChange="
                  updateCheckedMember(idx, $event.checkboxType, $event.checked)
                "
                @selectedComment="
                  handleSelectedComment({
                    comment: $event,
                    name: part.members[getSelectedMemberIndex(part)].name
                  })
                "
                @openFile="openDocumentModal(part, $event)"
              />
            </div>
            <div v-if="!isIncoming && !isRemoved" class="card-content">
              <h3>Scenario</h3>
              <p v-html="formatScenario(part.members[0].scenario)"></p>
            </div>

            <div v-if="!isIncoming && !isRemoved" class="card-content">
              <h3>Beaktansvärt skäl</h3>
              <p>
                {{ part.members[0].swapReason || '-' }}
              </p>
            </div>

            <div class="card-content">
              <h3 class="landlord-title" title="Ansluten hyresvärd">
                {{ part.landlord.name }}

                <svg
                  v-if="part.landlord.id"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  viewBox="0 0 24 24"
                  class="landlord-badge"
                >
                  <path
                    d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
                    fill="#1da1f2"
                  />
                </svg>
              </h3>
              <p>
                <span v-if="part.landlord.phone"
                  >{{ part.landlord.phone }} <br
                /></span>
                <span v-if="part.landlord.email">
                  {{ part.landlord.email }}
                </span>
              </p>
            </div>

            <a
              v-if="
                part.members[getSelectedMemberIndex(part)].landlordDocuments
                  .length > 0
              "
              :href="`${
                $http.apiEndpoint
              }/documents/landlord/${currentApplicationId}/${
                part.members[getSelectedMemberIndex(part)].landlordDocuments[0]
                  .id
              }`"
              class="pdf-link"
            >
              Sammanfattning - PDF</a
            >
          </div>
          <div class="inspectionApproved-container">
            <SwapApplicationListCheck
              :part="part"
              :is-disabled="hasRemovalTypeStatus"
              :hide-icon="true"
              :disable-tooltip="true"
              checkbox-name="INSPECTION_APPROVED"
              :documents="
                documentsCollections[getFullIdPath(part, documentsCollections)]
              "
              :application-status="application.status"
              @checksChange="
                updateCheckedMember(idx, 'INSPECTION_APPROVED', $event)
              "
              @selectedComment="
                handleSelectedComment({
                  comment: $event,
                  name: part.members[getSelectedMemberIndex(part)].name
                })
              "
              @openFile="openDocumentModal(part, 'INSPECTION_APPROVED')"
            >
              Besiktning godkänd
            </SwapApplicationListCheck>
          </div>
        </div>
      </div>

      <SwapTree
        v-else-if="activeTab === 2"
        :application-id="application.id"
      ></SwapTree>
      <Map v-else :application-id="application.id" />
    </template>
    <template slot="footer">
      <div class="left">
        <Tooltip
          v-if="activeTab === 1"
          step="assignCaseManager"
          position="bottom-left"
        ></Tooltip>

        <div class="assign-landlord-users-container">
          <BaseItemsList
            :class="{
              'no-pointer-events': hasRemovalTypeStatus
            }"
            :items="landlordUsersWithUnAssign.filter(x => !x.inactivatedAt)"
            :show-contact="true"
            @click="assignLandlordUser($event)"
          >
            <BaseIconText
              v-if="!application.assignedLandlordUser"
              :icon-url="svgs.ICONS.ADMINISTRATOR"
              size="larger"
              class="assign-button"
            >
              {{
                hasRemovalTypeStatus ? 'Ej tilldelad' : 'Tilldela handläggare 1'
              }}
            </BaseIconText>

            <UserIcon
              v-else
              :first-name="application.assignedLandlordUser.firstName"
              :last-name="application.assignedLandlordUser.lastName"
              :full-name-visible="true"
            ></UserIcon>
          </BaseItemsList>
          <BaseItemsList
            :class="{
              'no-pointer-events': hasRemovalTypeStatus
            }"
            :items="
              secondaryLandlordUsersWithUnAssign.filter(x => !x.inactivatedAt)
            "
            :show-contact="true"
            @click="assignSecondaryLandlordUser($event)"
          >
            <BaseIconText
              v-if="!application.secondaryAssignedLandlordUser"
              :icon-url="svgs.ICONS.ADMINISTRATOR"
              size="larger"
              class="assign-button"
            >
              {{
                hasRemovalTypeStatus ? 'Ej tilldelad' : 'Tilldela handläggare 2'
              }}
            </BaseIconText>

            <UserIcon
              v-else
              :first-name="application.secondaryAssignedLandlordUser.firstName"
              :last-name="application.secondaryAssignedLandlordUser.lastName"
              :full-name-visible="true"
            ></UserIcon>
          </BaseItemsList>
        </div>

        <div>
          <button
            v-if="!isIncoming"
            class="simple-button notes-button"
            @click="currentModal = 'NOTES'"
          >
            <BaseIconText :icon-url="svgs.ICONS.NOTEBOOK" size="larger">
              <NotificationSymbol
                v-if="anyUnreadNotes"
                slot="icon"
                border-color="#f1f2f6"
              />{{
                isSkandia ? 'Chatt och logg' : 'Chatt och noteringar'
              }}</BaseIconText
            >
          </button>
          <Tooltip
            v-if="activeTab === 1"
            step="notes"
            position="bottom-left"
            btn-text="Tack, jag förstår"
            :show-arrow="false"
          ></Tooltip>
        </div>

        <button
          v-if="!isIncoming && !hasRemovalTypeStatus"
          class="simple-button files-button"
          @click="currentModal = 'DOCUMENTS'"
        >
          <BaseIconText :icon-url="svgs.ICONS.ATTACHMENT" size="larger"
            >Visa {{ documentCount }} filer
          </BaseIconText>
        </button>
      </div>
      <div class="right">
        <div v-if="isPendingRemoval">
          <BaseButton
            class="mr-15"
            :is-rounded="true"
            :is-dark="true"
            @click="currentModal = 'CANCEL_REMOVAL'"
          >
            Avbryt radering
          </BaseButton>
        </div>
        <div
          v-if="!isIncoming && !hasRemovalTypeStatus"
          class="popupModal-container mr-15"
        >
          <PopupModal
            v-if="popupModalVisible"
            :items="itemsInPopupModal"
            @close="popupModalVisible = false"
            @click="handlePopupClick"
          />
          <button
            class="simple-button notes-button"
            @click="popupModalVisible = !popupModalVisible"
          >
            <BaseIconText :icon-url="svgs.ICONS.MORE"> Fler val</BaseIconText>
          </button>
        </div>
        <template v-if="application.status === statusTypes.READY">
          <BaseButton
            class="mr-15"
            :is-warning="true"
            :is-rounded="true"
            :icon="svgs.ICONS.ERROR_WHITE"
            @click="currentModal = 'REJECT'"
          >
            Neka byte
          </BaseButton>

          <BaseButton
            :is-rounded="true"
            :icon="svgs.ICONS.CHECK_WHITE"
            @click="currentModal = 'APPROVE'"
          >
            Godkänn byte
          </BaseButton>
        </template>
        <template v-else>
          <BaseButton
            v-if="
              application.status === statusTypes.APPROVED ||
              application.status === statusTypes.ABORTED
            "
            class="mr-15"
            :is-warning="true"
            :is-rounded="true"
            :icon="svgs.ICONS.ERROR_WHITE"
            @click="currentModal = 'CHANGE_TO_REJECT'"
          >
            Ändra till nekad
          </BaseButton>
          <BaseButton
            v-if="
              application.status === statusTypes.REJECTED ||
              application.status === statusTypes.ABORTED
            "
            :is-rounded="true"
            :icon="svgs.ICONS.CHECK_WHITE"
            @click="currentModal = 'CHANGE_TO_APPROVE'"
          >
            Ändra till godkänd
          </BaseButton>
        </template>

        <RejectReasonModal
          :application-id="currentApplicationId"
          :application-status="application.status"
        />
      </div>
    </template>
  </ApplicationContainerModal>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import svgs from '@/utils/icon-constants';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseImage from '@/components/BaseImage.vue';
import BaseButton from '@/components/BaseButton.vue';
import NotesModal from '@/components/NotesModal.vue';
import RejectModal from '@/components/RejectModal.vue';
import RemoveModal from '@/components/RemoveModal.vue';
import ChangeToRejectModal from '@/components/ChangeToRejectModal.vue';
import ApprovalModal from '@/components/ApprovalModal.vue';
import AbortModal from '@/components/AbortModal.vue';
import CancelRemovalModal from '@/components/CancelRemovalModal.vue';
import ChangeToApprovalModal from '@/components/ChangeToApprovalModal.vue';
import DocumentsModal from '@/components/DocumentsModal.vue';
import CommentModal from '@/components/CommentModal.vue';
import PartInfoModal from '@/components/PartInfoModal.vue';
import RejectReasonModal from '@/components/RejectReasonModal.vue';
import BaseItemsList from '@/components/BaseItemsList.vue';
import SwapTree from '@/components/SwapTree.vue';
import TabHeader from '@/components/TabHeader.vue';
import UserIcon from '@/components/UserIcon.vue';
import SwapApplicationListCheck from '@/components/SwapApplicationListCheck.vue';
import NotificationSymbol from '@/components/NotificationSymbol.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import Map from '@/components/Map.vue';
import escClick from '@/directives/esc-click';
import statusTypes from '@/utils/statusTypes';
import Tooltip from '@/components/Tooltip.vue';
import PopupModal from '@/components/PopupModal.vue';
import SwapCardContent from '@/components/SwapCardContent.vue';
import NotAssignedModal from '@/components/NotAssignedModal.vue';
import ApplicationContainerModal from '@/components/ApplicationContainerModal.vue';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';
import { getFullIdPath, getSelectedMemberIndex } from '@/utils/misc';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import RestoreModal from '@/components/RestoreModal.vue';

export default {
  name: 'ApplicationView',
  directives: { escClick },
  components: {
    BaseItemsList,
    BaseIconText,
    BaseButton,
    NotesModal,
    RejectModal,
    RemoveModal,
    ChangeToRejectModal,
    DocumentsModal,
    ApprovalModal,
    AbortModal,
    ChangeToApprovalModal,
    CommentModal,
    PartInfoModal,
    RejectReasonModal,
    SwapTree,
    BaseImage,
    TabHeader,
    SwapApplicationListCheck,
    UserIcon,
    NotificationSymbol,
    ErrorModal,
    Map,
    Tooltip,
    PopupModal,
    NotAssignedModal,
    CancelRemovalModal,
    SwapCardContent,
    ApplicationContainerModal,
    BaseLoadingSpinner,
    RestoreModal
  },

  data() {
    return {
      svgs,
      statusTypes,
      application: null,
      isLoading: true,
      currentModal: null,
      selectedComment: null,
      selectedPartInfo: null,
      selectedPartInfoModalPosition: {
        x: 0,
        y: 0
      },
      landlordUsers: [],
      documentsCollections: null,
      selectedDocumentType: null,
      selectedApplicantId: null,
      errorModalVisible: false,
      isAnimatingModalSize: false,
      popupModalVisible: false,
      initialMemberIndex: -1,
      getFullIdPath,
      getSelectedMemberIndex
    };
  },
  computed: {
    ...mapGetters({
      tooltips: 'app/tooltips',
      isSkandia: 'app/isSkandia'
    }),
    ...mapState({
      landlordFiles: state => state.landlordFiles.files,
      account: state => state.app.account
    }),
    showHelpButton() {
      if (this.isIncoming) {
        return false;
      }
      if (this.hasRemovalTypeStatus) {
        return false;
      }
      return (
        this.application.status === statusTypes.READY && this.activeTab === 1
      );
    },
    landlordFilesFromId() {
      return this.landlordFiles[this.currentApplicationId] || [];
    },
    landlordUsersWithUnAssign() {
      // Add "unassign" button if there is an assigned landlordUser
      if (
        this.application.assignedLandlordUser &&
        this.landlordUsers[0].id !== null
      ) {
        return [
          {
            firstName: 'Ej',
            lastName: 'Tilldelad',
            id: null
          },
          ...this.landlordUsers
        ];
      }
      return this.landlordUsers;
    },
    secondaryLandlordUsersWithUnAssign() {
      // Add "unassign" button if there is an secondary assigned landlordUser
      if (
        this.application.secondaryAssignedLandlordUser &&
        this.landlordUsers[0].id !== null
      ) {
        return [
          {
            firstName: 'Ej',
            lastName: 'Tilldelad',
            id: null
          },
          ...this.landlordUsers
        ];
      }
      return this.landlordUsers;
    },
    tabHeaderSubtitle() {
      if (this.application.status === statusTypes.APPROVED) {
        return `(godkänd)`;
      }
      if (this.application.status === statusTypes.REJECTED) {
        return `(nekad)`;
      }
      if (this.application.status === statusTypes.CLOSED) {
        return `(avslutad)`;
      }
      return null;
    },
    anyUnreadNotes() {
      if (this.application.unreadNotes) {
        return !!Object.values(this.application.unreadNotes).find(
          note => note > 0
        );
      }
      return false;
    },
    currentApplicationId() {
      const { id } = this.$route.params;
      return id;
    },
    activeTab() {
      return this.$route.query.tab ? parseInt(this.$route.query.tab) : 1;
    },
    documentCount() {
      if (!this.documentsCollections && !this.landlordFilesFromId) {
        return 0;
      }

      let count = 0;
      if (this.documentsCollections) {
        Object.keys(this.documentsCollections).forEach(key => {
          if (this.documentsCollections[key]) {
            count += this.documentsCollections[key].length;
          }
        });
      }

      if (this.landlordFilesFromId) {
        count += this.landlordFilesFromId.length;
      }

      if (this.application && this.application.scriveId) {
        count++;
      }

      return count;
    },
    isIncoming() {
      return this.application.status === statusTypes.INCOMING;
    },
    isRemoved() {
      return this.application.status === statusTypes.REMOVED;
    },
    isPendingRemoval() {
      return this.application.status === statusTypes.PENDING_REMOVE;
    },
    hasRemovalTypeStatus() {
      return this.isRemoved || this.isPendingRemoval;
    },
    currentUserIsAssigned() {
      return this.application.assignedLandlordUser?.id === this.account.userId;
    },
    currentSecondaryUserIsAssigned() {
      return (
        this.application.secondaryAssignedLandlordUser?.id ===
        this.account.userId
      );
    },
    itemsInPopupModal() {
      const items = [
        {
          title: 'Avbryt bytet',
          icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12 14C11.7528 14 11.5111 14.0733 11.3055 14.2107C11.1 14.348 10.9398 14.5432 10.8452 14.7716C10.7505 15.0001 10.7258 15.2514 10.774 15.4939C10.8223 15.7363 10.9413 15.9591 11.1161 16.1339C11.2909 16.3087 11.5137 16.4277 11.7561 16.476C11.9986 16.5242 12.25 16.4995 12.4784 16.4049C12.7068 16.3102 12.902 16.15 13.0393 15.9445C13.1767 15.7389 13.25 15.4972 13.25 15.25C13.25 14.9185 13.1183 14.6005 12.8839 14.3661C12.6495 14.1317 12.3315 14 12 14ZM12 12.5C12.2652 12.5 12.5196 12.3946 12.7071 12.2071C12.8946 12.0196 13 11.7652 13 11.5V8.5C13 8.23478 12.8946 7.98043 12.7071 7.79289C12.5196 7.60536 12.2652 7.5 12 7.5C11.7348 7.5 11.4804 7.60536 11.2929 7.79289C11.1054 7.98043 11 8.23478 11 8.5V11.5C11 11.7652 11.1054 12.0196 11.2929 12.2071C11.4804 12.3946 11.7348 12.5 12 12.5ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34873 20.9426 6.80688 19.0679 4.93215C17.1931 3.05742 14.6513 2.00291 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C19.9974 14.1209 19.1537 16.1542 17.6539 17.6539C16.1542 19.1536 14.1209 19.9974 12 20Z"
                  fill="#878b9d" />
          </svg>`,
          content:
            'Om en hyresgäst inte längre vill gå vidare med bytet, så flyttas ärendet till avslutat men markeras som avbrytet.',
          cta: 'Avbryt bytet',
          type: 'ABORT',
          theme: 'yellow'
        },
        {
          title: 'Radera bytet',
          icon: `<svg width="19" height="19" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.88232 16C8.14754 16 8.40189 15.8946 8.58943 15.7071C8.77697 15.5196 8.88232 15.2652 8.88232 15V9C8.88232 8.73478 8.77697 8.48043 8.58943 8.29289C8.40189 8.10536 8.14754 8 7.88232 8C7.61711 8 7.36275 8.10536 7.17522 8.29289C6.98768 8.48043 6.88232 8.73478 6.88232 9V15C6.88232 15.2652 6.98768 15.5196 7.17522 15.7071C7.36275 15.8946 7.61711 16 7.88232 16ZM17.8823 4H13.8823V3C13.8823 2.20435 13.5663 1.44129 13.0036 0.87868C12.441 0.316071 11.678 0 10.8823 0H8.88232C8.08667 0 7.32361 0.316071 6.761 0.87868C6.19839 1.44129 5.88232 2.20435 5.88232 3V4H1.88232C1.61711 4 1.36275 4.10536 1.17522 4.29289C0.987681 4.48043 0.882324 4.73478 0.882324 5C0.882324 5.26522 0.987681 5.51957 1.17522 5.70711C1.36275 5.89464 1.61711 6 1.88232 6H2.88232V17C2.88232 17.7956 3.19839 18.5587 3.761 19.1213C4.32361 19.6839 5.08667 20 5.88232 20H13.8823C14.678 20 15.441 19.6839 16.0036 19.1213C16.5663 18.5587 16.8823 17.7956 16.8823 17V6H17.8823C18.1475 6 18.4019 5.89464 18.5894 5.70711C18.777 5.51957 18.8823 5.26522 18.8823 5C18.8823 4.73478 18.777 4.48043 18.5894 4.29289C18.4019 4.10536 18.1475 4 17.8823 4ZM7.88232 3C7.88232 2.73478 7.98768 2.48043 8.17522 2.29289C8.36275 2.10536 8.61711 2 8.88232 2H10.8823C11.1475 2 11.4019 2.10536 11.5894 2.29289C11.777 2.48043 11.8823 2.73478 11.8823 3V4H7.88232V3ZM14.8823 17C14.8823 17.2652 14.777 17.5196 14.5894 17.7071C14.4019 17.8946 14.1475 18 13.8823 18H5.88232C5.61711 18 5.36275 17.8946 5.17522 17.7071C4.98768 17.5196 4.88232 17.2652 4.88232 17V6H14.8823V17ZM11.8823 16C12.1475 16 12.4019 15.8946 12.5894 15.7071C12.777 15.5196 12.8823 15.2652 12.8823 15V9C12.8823 8.73478 12.777 8.48043 12.5894 8.29289C12.4019 8.10536 12.1475 8 11.8823 8C11.6171 8 11.3628 8.10536 11.1752 8.29289C10.9877 8.48043 10.8823 8.73478 10.8823 9V15C10.8823 15.2652 10.9877 15.5196 11.1752 15.7071C11.3628 15.8946 11.6171 16 11.8823 16Z" fill="#878b9d" />
        </svg>`,
          content:
            'Vill du ta bort hela bytet och dess information, från tjänsten helt och hållet?',
          cta: 'Radera bytet',
          type: 'REMOVE',
          theme: 'red'
        }
      ];
      if (this.application.status !== 'READY' && !this.hasRemovalTypeStatus) {
        items.push({
          title: 'Återställ bytet',
          icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.6863 15.3137 6 12 6C8.68629 6 6 8.6863 6 12V14.5" stroke="#878b9d"
                  stroke-width="2" stroke-linecap="round" />
              <path d="M6 15L3 12" stroke="#878b9d" stroke-width="2" stroke-linecap="round" />
              <path d="M6 15L9 12" stroke="#878b9d" stroke-width="2" stroke-linecap="round" />
          </svg>`,
          content: 'Vill du flytta tillbaka bytet till "Fatta beslut"?',
          cta: 'Återställ bytet',
          theme: 'blue',
          type: 'RESTORE'
        });
      }
      return items;
    }
  },
  async created() {
    this.isLoading = true;
    try {
      const applicationId = this.currentApplicationId;
      await this.getApplication();
      await this.getFiles(applicationId);

      const documents = await this.$http.getDocuments(applicationId);

      this.documentsCollections = documents;
      this.landlordUsers = await this.$http.getLandlordUsers();
    } catch {
      this.showErrorModal();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setApplication: 'applications/setApplication',
      setApplicationUnreadNotes: 'applications/setApplicationUnreadNotes',
      resetSecondPartOfTooltipsArray: 'app/resetSecondPartOfTooltipsArray'
    }),
    ...mapActions({
      getFiles: 'landlordFiles/getFiles'
    }),
    handleSelectedComment(selected) {
      this.selectedComment = selected;
    },
    getTrackingType(type) {
      switch (type) {
        case 'ACCOMONDATION_TIME':
          return 'Bosatt';
        case 'ACCOMONDATION_CURRENT':
          return 'Boende idag';
        case 'MIN_UC':
          return 'UC';
        case 'RENTAL_BASE':
          return 'Hyra';
        case 'RENTAL_AGREEMENT':
          return 'Hyresavtal';
        case 'ID_PROOF':
          return 'Familjebevis';
        case 'INCOME_BASE':
          return 'Intyg på inkomst';
        case 'REASON':
          return 'Bytesskäl';
        case 'OTHER_APARTMENT_INFO':
          return 'Annat fastighetsinnehav';
        case 'INSPECTION_APPROVED':
          return 'Besiktning godkänd';
        case 'OTHER_DOCUMENTS':
          return 'Övriga dokument';
        default:
          return '';
      }
    },
    handleEscClick() {
      if (!this.currentModal) {
        this.$emit('close');
      }
    },
    formatScenario(scenario) {
      // Replace RegEx with <br>
      return scenario ? scenario.replace(/\n/g, '<br>') : '-';
    },
    openDocumentModal(part, type) {
      this.selectedApplicantId = this.getFullIdPath(
        part,
        this.documentsCollections
      );
      const documents = this.documentsCollections[this.selectedApplicantId];
      const docOfType = documents
        ? documents.find(doc => doc.type === type)
        : null;
      if (docOfType?.memberIndex === null) {
        // Shared document
        this.initialMemberIndex = -1;
      } else {
        // Personal document
        const memberIdSplit = part.members[0].id.split('-');
        const partMemberIndex = Number(memberIdSplit[memberIdSplit.length - 1]);
        //If partMemberIndex 1 then it's two apartments on same applicant
        const index =
          partMemberIndex === 1
            ? partMemberIndex
            : this.getSelectedMemberIndex(part);
        this.initialMemberIndex = index;
      }

      this.selectedDocumentType = type;
      this.currentModal = 'DOCUMENTS';
    },
    async updateCheckedMember(partIdx, checkboxType, checked) {
      const { id } = this.$route.params;
      const selectedMemberIndex = this.getSelectedMemberIndex(
        this.application.parts[partIdx]
      );
      const memberId =
        this.application.parts[partIdx].members[selectedMemberIndex].id;
      const trackingType = this.getTrackingType(checkboxType);

      try {
        if (checked) {
          await this.$http.addCheck(id, memberId, checkboxType);
          const addedChecks = [
            ...this.application.parts[partIdx].members[selectedMemberIndex]
              .checks,
            checkboxType
          ];

          this.$set(
            this.application.parts[partIdx].members[selectedMemberIndex],
            'checks',
            addedChecks
          );
          churnService.track(
            events.CHANGE_SWAP_CHECKLIST,
            features.CHANGE_SWAP_CHECKLIST.UNCHECK(trackingType)
          );
        } else {
          await this.$http.removeCheck(id, memberId, checkboxType);
          const removedChecks = this.application.parts[partIdx].members[
            selectedMemberIndex
          ].checks.filter(check => check !== checkboxType);

          this.$set(
            this.application.parts[partIdx].members[selectedMemberIndex],
            'checks',
            removedChecks
          );

          churnService.track(
            events.CHANGE_SWAP_CHECKLIST,
            features.CHANGE_SWAP_CHECKLIST.CHECK(trackingType)
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async assignLandlordUser(newUser) {
      if (newUser.id === null) {
        this.unassignLandlordUser();
        return;
      }

      this.application.assignedLandlordUser = newUser;

      await this.$http.assignLandlordUser(
        this.currentApplicationId,
        newUser.id
      );
      churnService.track(events.UPDATE_MANAGER, features.UPDATE_MANAGER.ASSIGN);

      this.setApplication({
        id: this.currentApplicationId,
        assignedLandlordUser: newUser
      });
    },
    async assignSecondaryLandlordUser(newUser) {
      if (newUser.id === null) {
        this.unassignSecondaryLandlordUser();
        return;
      }

      this.application.secondaryAssignedLandlordUser = newUser;

      await this.$http.assignSecondaryLandlordUser(
        this.currentApplicationId,
        newUser.id
      );
      churnService.track(
        events.UPDATE_SECONDARY_MANAGER,
        features.UPDATE_SECONDARY_MANAGER.ASSIGN
      );

      this.setApplication({
        id: this.currentApplicationId,
        secondaryAssignedLandlordUser: newUser
      });
    },
    async unassignLandlordUser() {
      this.application.assignedLandlordUser = null;

      await this.$http.unassignLandlordUser(this.currentApplicationId);
      churnService.track(
        events.UPDATE_MANAGER,
        features.UPDATE_MANAGER.UNASSIGN
      );

      this.setApplication({
        id: this.currentApplicationId,
        assignedLandlordUser: null
      });
    },
    async unassignSecondaryLandlordUser() {
      this.application.secondaryAssignedLandlordUser = null;

      await this.$http.unassignSecondaryLandlordUser(this.currentApplicationId);
      churnService.track(
        events.UPDATE_SECONDARY_MANAGER,
        features.UPDATE_SECONDARY_MANAGER.UNASSIGN
      );

      this.setApplication({
        id: this.currentApplicationId,
        secondaryAssignedLandlordUser: null
      });
    },
    async getApplication() {
      const res = await this.$http.getApplication(this.currentApplicationId);
      this.application = res;
    },
    setTab(tabNumber) {
      this.$router.push({ query: { tab: tabNumber } });
    },
    isFromLb(application) {
      return !!application.parts.find(part => part.propositionId);
    },

    getShortName(name) {
      if (!name || !name.trim() || name.trim() === 'null') {
        return '-';
      }

      const maxLength = 10;
      const shortName = name.split(' ')[0];
      if (shortName.length > maxLength) {
        return shortName.substr(0, maxLength) + '...';
      }
      return shortName;
    },
    selectMemberIndex(part, memberIdx) {
      this.$set(part, 'activeMember', memberIdx);
    },
    leaveApplication() {
      this.$router.push({ path: '/byten/alla' });
    },
    openPartInfoModal(x, y, part) {
      this.selectedPartInfoModalPosition = {
        x,
        y
      };
      this.selectedPartInfo = part;
    },
    handleTabClick(tabId) {
      if (this.application.unreadNotes) {
        const unreadNote = this.application.unreadNotes[tabId];
        if (unreadNote && unreadNote > 0) {
          this.application.unreadNotes[tabId] = 0;
          this.setApplicationUnreadNotes({
            applicationId: this.application.id,
            unreadNotes: 0
          });
        }
      }
    },
    buildAddressStr(part) {
      let arr = [];

      if (part.address) {
        arr.push(part.address);
      }
      if (part.city) {
        arr.push(part.city);
      }
      if (part.rooms) {
        arr.push(`${part.rooms} rok`);
      }

      return arr.join(', ');
    },
    showErrorModal() {
      this.errorModalVisible = true;
    },
    handleHelpButtonClicked() {
      this.resetSecondPartOfTooltipsArray();
    },
    handlePopupClick(type) {
      switch (type) {
        case 'REMOVE':
          if (
            this.currentUserIsAssigned ||
            this.currentSecondaryUserIsAssigned
          ) {
            this.currentModal = 'REMOVE';
          } else {
            this.currentModal = 'USER_NOT_ASSIGNED';
          }
          break;
        case 'ABORT':
          this.currentModal = 'ABORT';
          break;
        case 'RESTORE':
          this.currentModal = 'RESTORE';
          break;
        default:
          this.currentModal = null;
      }
      this.popupModalVisible = false;
    }
  }
};
</script>

<style scoped>
.base-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
}
.body {
  position: relative;
  background: #fff;
  padding: 25px 35px 50px 35px;
  width: 100%;
  overflow: auto;
  max-height: 70vh;
}
.body > .applications-row {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.body--animating {
  overflow: hidden;
}
.body--animating > .applications-row {
  opacity: 0;
  transition: none;
}
.applications-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}
.applications-row-2-columns {
  width: 680px;
}
.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 22px 35px 22px 35px;
  background: #f1f2f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footer > .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
::v-deep .left .text {
  margin-left: 7px;
}
.footer > .left > * {
  margin-right: 25px;
}
.assign-landlord-users-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.application {
  margin: 0px 20px;
  width: 290px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.application-card {
  width: 100%;
  min-height: 300px;
  background: #f8f8fa;
  padding: 2.2rem;
  border-radius: 10px;
  color: var(--color-dark-blue);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.person {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.person a:hover {
  cursor: pointer;
}
.person > h3 {
  display: block;
  font-size: 18px;
  color: var(--color-dark-blue);
}
.person .pointer {
  cursor: pointer;
  position: relative;
}
.person .pointer .icon {
  display: inline-flex;
  align-items: flex-end;
  height: 50px;
  width: 50px;
}
.person .pointer .lb-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #fff;
  z-index: 1;
  background-size: 16px 16px;
}
.person-info-wrapper {
  display: inline-flex;
  flex-grow: 1;
}
.card-content {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.card-content:nth-last-child(1) {
  margin-bottom: 0;
}
.card-content > h3 {
  font-weight: 700;
  margin-bottom: 5px;
}
.card-content > p {
  font-weight: 400;
}
.mr-15 {
  margin-right: 15px;
}
.member-selector {
  margin-top: 0.5rem;
}
.member-selector a {
  display: inline-block;
  width: 50%;
  text-align: center;
  padding: 8px 0px;
  font-weight: bold;
  color: #222;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
}
.member-selector a.selected {
  background: #f8f8fa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: -20px;
}
.member-selector a.selected::after {
  display: block;
  content: '';
  height: 10px;
}
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.name {
  color: var(--color-dark-blue);
  font-weight: 600;
  font-size: 1.8rem;
}
.address {
  font-weight: 600;
  color: #878b9d;
  font-size: 1.4rem;
}
.pdf-link {
  margin-top: 30px;
  font-size: 12px;
  text-decoration: underline;
}
.landlord-badge {
  height: 16px;
  margin-left: 5px;
}
.landlord-title {
  display: inline-flex;
  align-content: center;
}
.assign-button,
.notes-button,
.files-button {
  font-weight: 600;
  font-size: 1.2rem;
}
.assign-button {
  color: var(--color-dark-blue);
  font-weight: 700;
}
.modal-transition-wrapper {
  top: 0;
  transition: 0.5s cubic-bezier(0.67, -0.25, 0.35, 1.46);
}
.inspectionApproved-container {
  font-size: 1.4rem;
  margin-top: 1rem;
  color: var(--color-dark-blue);
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  z-index: 2;
  padding: 0;
  border: none;
  box-shadow: none;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border-bottom-left-radius: 10px;
  height: 40px;
  width: 40px;
}
.close-btn svg path {
  fill: #fff;
}
.cancel-button {
  background-color: var(--status-aborted);
}
.popupModal-container {
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
}
</style>
