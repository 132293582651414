<template>
  <DefaultModal @close="closeModal">
    <template slot="header">
      <DefaultModalTitle>Filter</DefaultModalTitle>
    </template>
    <template slot="body">
      <div class="filter-container">
        <p>
          {{
            activeApp === 'bytesansokan'
              ? 'Status på bytet'
              : 'Status på ansökan'
          }}
        </p>
        <div class="filter-btn-container">
          <button
            v-for="status in activeCategories"
            :key="status.id"
            type="button"
            :class="{
              'filter-btn': true,
              'is-selected': status.selected
            }"
            @click="toggleActiveCategory(status.id)"
          >
            {{ status.title }}
          </button>
        </div>
      </div>
      <div class="filter-container">
        <p>Handläggare</p>
        <div class="filter-btn-container filter-btn-container--grid">
          <button
            v-for="user in landlordUsers"
            :key="user.id"
            type="button"
            :class="{
              'filter-btn': true,
              'is-selected': user.selected
            }"
            @click="toggleSelectedUsers(user.id)"
          >
            {{ user.fullName }}
          </button>
        </div>
      </div>
      <div v-if="activeApp === 'bytesansokan'" class="filter-container">
        <p>Taggar</p>
        <div class="filter-btn-container filter-btn-container--grid">
          <button
            v-for="tag in activeTags"
            :key="tag.tagId"
            type="button"
            class="filter-btn"
            :class="{
              'is-selected': tag.selected
            }"
            @click="toggleSelectedTags(tag.id)"
          >
            {{ tag.title }}
          </button>
        </div>
      </div>
      <div class="filter-swap-dates">
        <div class="filter-container">
          <p>
            {{
              activeApp === 'bytesansokan' ? 'Bytet kom in' : 'Ansökan kom in'
            }}
          </p>
          <div class="dates-wrapper">
            <div class="dates-container">
              <p>Från</p>
              <DatePickerButton
                :active-date-picker="activeDatePicker"
                :date-picker-value="datePickerValue.newCaseFromDate"
                date-picker-type="newCaseFromDate"
                @toggle-date-picker="toggleDatePicker"
              />
              <BaseModal
                v-if="activeDatePicker === 'newCaseFromDate'"
                :show-close-btn="false"
                @close="toggleDatePicker('newCaseFromDate')"
              >
                <DatePicker
                  v-model="newCaseFromDate"
                  :lang="lang"
                  type="date"
                  :inline="true"
                  :value-format="'yyyy-MM-dd'"
                  :selection="true"
                  :disabled-date="maxDate"
                  @pick="toggleDatePicker('newCaseFromDate')"
                />
              </BaseModal>
            </div>
            <div class="dates-container">
              <p>Till</p>
              <DatePickerButton
                :active-date-picker="activeDatePicker"
                :date-picker-value="datePickerValue.newCaseToDate"
                date-picker-type="newCaseToDate"
                @toggle-date-picker="toggleDatePicker"
              />
              <BaseModal
                v-if="activeDatePicker === 'newCaseToDate'"
                :show-close-btn="false"
                @close="toggleDatePicker('newCaseToDate')"
              >
                <DatePicker
                  v-model="newCaseToDate"
                  :lang="lang"
                  type="date"
                  :inline="true"
                  :value-format="'yyyy-MM-dd'"
                  :selection="true"
                  :disabled-date="maxDate"
                  @pick="toggleDatePicker('newCaseToDate')"
                />
              </BaseModal>
            </div>
          </div>
        </div>
        <div v-if="showSwapFilter" class="filter-container">
          <p>Hyresgäster önskar byta</p>
          <div class="dates-wrapper">
            <div class="dates-container">
              <p>Från</p>
              <DatePickerButton
                :active-date-picker="activeDatePicker"
                :date-picker-value="datePickerValue.swapFromDate"
                date-picker-type="swapFromDate"
                @toggle-date-picker="toggleDatePicker"
              />
              <BaseModal
                v-if="activeDatePicker === 'swapFromDate'"
                :show-close-btn="false"
                @close="toggleDatePicker('swapFromDate')"
              >
                <DatePicker
                  v-model="swapFromDate"
                  :lang="lang"
                  type="date"
                  :inline="true"
                  :value-format="'yyyy-MM-dd'"
                  :selection="true"
                  @pick="toggleDatePicker('swapFromDate')"
                />
              </BaseModal>
            </div>
            <div class="dates-container">
              <p>Till</p>
              <DatePickerButton
                :active-date-picker="activeDatePicker"
                :date-picker-value="datePickerValue.swapToDate"
                date-picker-type="swapToDate"
                @toggle-date-picker="toggleDatePicker"
              />
              <BaseModal
                v-if="activeDatePicker === 'swapToDate'"
                :show-close-btn="false"
                @close="toggleDatePicker('swapToDate')"
              >
                <DatePicker
                  v-model="swapToDate"
                  :lang="lang"
                  type="date"
                  :inline="true"
                  :value-format="'yyyy-MM-dd'"
                  :selection="true"
                  @pick="toggleDatePicker('swapToDate')"
                />
              </BaseModal>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="footer-container">
        <button type="button" class="clear-search-btn" @click="clearFilter">
          Rensa alla filter
        </button>
        <div class="btns-container">
          <BaseButton :is-rounded="true" :is-dark="true" @click="closeModal">
            Visa {{ apps.length }}
            {{ activeApp === 'bytesansokan' ? 'byten' : 'ansökningar' }}
          </BaseButton>
        </div>
      </div>
    </template>
  </DefaultModal>
</template>

<script>
import 'vue2-datepicker/locale/sv';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import DatePicker from 'vue2-datepicker';
import DatePickerButton from './DatePickerButton.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseModal from './BaseModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import { APP_NAMES } from '@/utils/constants';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    BaseButton,
    DatePicker,
    BaseModal,
    DatePickerButton,
    DefaultModalTitle
  },
  props: {
    apps: {
      type: Array,
      required: true
    },
    showSwapFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
          months: [
            'Januari',
            'Februari',
            'Mars',
            'April',
            'Maj',
            'Juni',
            'Juli',
            'Augusti',
            'September',
            'Oktober',
            'November',
            'December'
          ]
        },
        monthBeforeYear: true,
        monthFormat: 'MMMM',
        days: ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön']
      },
      activeDatePicker: null
    };
  },
  computed: {
    ...mapState({
      activeApp: state => state.app.activeApp,
      landlordUsers: state => state.applications.landlordUsers,
      activeDates: state => state.applications.activeDates,
      datePickerValue: state => state.applications.datePickerValue
    }),
    ...mapGetters({
      activeCategories: 'applications/activeCategories',
      activeTags: 'applications/activeTags'
    }),
    newCaseFromDate: {
      get() {
        return this.datePickerValue.newCaseFromDate;
      },
      set(newVal) {
        this.$store.commit('applications/setDatePickerValue', {
          type: 'newCaseFromDate',
          value: this.newDateOrNull(
            this.datePickerValue.newCaseFromDate,
            newVal
          )
        });
      }
    },
    newCaseToDate: {
      get() {
        return this.datePickerValue.newCaseToDate;
      },
      set(newVal) {
        this.$store.commit('applications/setDatePickerValue', {
          type: 'newCaseToDate',
          value: this.newDateOrNull(this.datePickerValue.newCaseToDate, newVal)
        });
      }
    },
    swapFromDate: {
      get() {
        return this.datePickerValue.swapFromDate;
      },
      set(newVal) {
        this.$store.commit('applications/setDatePickerValue', {
          type: 'swapFromDate',
          value: this.newDateOrNull(this.datePickerValue.swapFromDate, newVal)
        });
      }
    },
    swapToDate: {
      get() {
        return this.datePickerValue.swapToDate;
      },
      set(newVal) {
        this.$store.commit('applications/setDatePickerValue', {
          type: 'swapToDate',
          value: this.newDateOrNull(this.datePickerValue.swapToDate, newVal)
        });
      }
    }
  },
  methods: {
    ...mapActions({
      clearFilter: 'applications/clearFilter'
    }),
    ...mapMutations({
      toggleSelectedCategories: 'applications/toggleSelectedCategories',
      toggleSelectedSubletCategories:
        'applications/toggleSelectedSubletCategories',
      toggleSelectedUsers: 'applications/toggleSelectedUsers',
      setDatePickerValue: 'applications/setDatePickerValue',
      toggleSelectedTags: 'applications/toggleSelectedTags'
    }),
    toggleActiveCategory(id) {
      if (this.activeApp === APP_NAMES.ANDRAHAND) {
        this.toggleSelectedSubletCategories(id);
      } else {
        this.toggleSelectedCategories(id);
      }
    },
    maxDate(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },

    newDateOrNull(oldVal, newVal) {
      const oldDate = new Date(oldVal).getTime();
      const newDate = new Date(newVal).getTime();
      return newDate === oldDate ? null : newVal;
    },
    closeModal() {
      this.$emit('close');
    },
    toggleDatePicker(selectedDate) {
      if (this.activeDatePicker === selectedDate) {
        this.activeDatePicker = null;
      } else {
        this.activeDatePicker = selectedDate;
      }
    }
  }
};
</script>

<style scoped>
.filter-btn {
  padding: 1.2rem 2rem;
  min-width: 6rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  border-color: var(--color-grey);
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.2rem;
}
.is-selected {
  color: #fff;
  background-color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);
  outline: none;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--color-dark-blue);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4.5rem;
}
.filter-swap-dates {
  display: flex;
  gap: 4.5rem;
}
.filter-swap-dates .filter-container {
  display: flex;
  flex-direction: column;
}
.filter-swap-dates .filter-container .dates-wrapper {
  display: flex;
  gap: 2rem;
}
.filter-swap-dates .filter-container .dates-wrapper .dates-container {
  display: flex;
  font-size: 10px;
  color: var(--color-grey);
  flex-direction: column;
  gap: 0.3rem;
}
.filter-btn-container {
  display: flex;
  gap: 10px;
}

.filter-btn-container--grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
}

.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.footer-container .btns-container {
  display: flex;
  gap: 2rem;
}
.footer-container .clear-search-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  font-size: 1.2rem;
  font-weight: 600;
}
.footer-container .clear-search-btn:hover {
  color: var(--color-grey);
}
</style>
