import http from '@/services/http';

async function addFile(ctx, { applicationId, file }) {
  const addedFile = await http.storeLandlordFile(applicationId, file);
  ctx.commit('add', { applicationId, file: addedFile });
}

async function removeFile(ctx, { applicationId, fileName }) {
  await http.deleteLandlordFile(applicationId, fileName);
  ctx.commit('remove', { applicationId, fileName });
}

async function getFiles(ctx, applicationId) {
  const files = await http.getLandlordFiles(applicationId);
  ctx.commit('set', { applicationId, files });
}

export default {
  addFile,
  removeFile,
  getFiles
};
