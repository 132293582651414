<template>
  <div class="swap-tree-image-wrapper">
    <div v-if="isLoading" class="loading-wrapper">
      <BaseLoadingSpinner :with-overlay="false" :size="60" />
    </div>
    <div v-if="!svg" class="image" v-html="svg"></div>
    <transition v-else name="shuffle" appear>
      <div class="image" v-html="svg"></div>
    </transition>
  </div>
</template>

<script>
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  components: {
    BaseLoadingSpinner
  },
  props: {
    applicationId: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      svg: '',
      isLoading: true
    };
  },
  async created() {
    const id = this.applicationId;
    const svgResult = await this.$http.getSwapTree(id);
    this.isLoading = false;
    if (svgResult) {
      this.svg = svgResult;
    }
  }
};
</script>

<style scoped>
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.swap-tree-image-wrapper {
  text-align: center;
}

.image {
  width: 800px;
  height: 600px;
}
</style>
