var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: ["loading loading--spinner", { overlay: _vm.withOverlay }] },
    [
      _c(
        "svg",
        {
          staticClass: "spinner",
          style: { height: `${_vm.size}px`, width: `${_vm.size}px` },
          attrs: { viewBox: "0 0 50 50" },
        },
        [
          _c("circle", {
            staticClass: "path",
            attrs: {
              cx: "25",
              cy: "25",
              r: "20",
              fill: "none",
              stroke: "#93bfec",
              "stroke-width": "5",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }