var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-btn-wrapper" }, [
    _vm.datePickerValue !== null
      ? _c(
          "button",
          { staticClass: "clear-date-btn", on: { click: _vm.clearDate } },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 16 16",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                    fill: "#2c3e50",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.activeDatePicker !== _vm.datePickerType
      ? _c(
          "button",
          {
            staticClass: "filter-btn",
            class: {
              "is-selected": _vm.datePickerValue !== null,
            },
            attrs: { type: "button" },
            on: { click: _vm.toggleDatePicker },
          },
          [_vm._v(" " + _vm._s(_vm.formatDate(_vm.datePickerValue)) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }