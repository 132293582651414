<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>{{
        isSkandia ? 'Neka byte' : 'Neka byte på grund av'
      }}</DefaultModalTitle>
    </template>

    <template slot="body">
      <transition name="reject-list-toggle" appear>
        <section
          v-if="rejectOptionsListVisible && !textAreaVisible"
          class="reject-options-container"
        >
          <div class="text-info-container">
            <h3>Välj ett alternativ för att neka bytet:</h3>
            <p>
              <i
                >OBS! Endast anslutna hyresvärdar får ta del av denna
                information.</i
              >
            </p>
          </div>
          <div class="options-scroll-container">
            <div
              v-for="opt in skandiaRejectOptionsList"
              :key="opt.id"
              class="reject-options"
            >
              <button
                class="reject-options-btn"
                @click="selectRejectOption(opt)"
              >
                <p class="reject-option">{{ opt.label }}</p>
              </button>
            </div>
          </div>
          <OptionsModal
            v-if="rejectOptionModalVisible"
            :option-to-send="note"
            :is-reject-reason="true"
            @send="sendRejectOption"
            @close="rejectOptionModalVisible = false"
          />
        </section>
        <section v-else>
          <div class="display-reject-options-container">
            <button
              class="display-reject-options-btn"
              @click="handleRejectOptionsListVisible"
            >
              <p class="display-reject-options-text">
                Färdiga alternativ för att neka byte
              </p>
            </button>
          </div>
        </section>
      </transition>
    </template>

    <template slot="footer">
      <div class="footer-wrapper">
        <transition
          v-if="textAreaVisible && !isSkandia"
          name="textarea-toggle"
          appear
        >
          <div class="textarea-wrapper">
            <textarea
              :value="note"
              class="area"
              :disabled="isSaving"
              cols="30"
              rows="5"
              @input="note = $event.target.value"
            ></textarea>
          </div>
        </transition>
        <div class="button-container">
          <BaseButton
            v-if="!textAreaVisible && !isSkandia"
            :is-disabled="isSaving"
            :is-rounded="true"
            :icon="svgs.ICONS.PEN_WHITE"
            @click="handleTextAreaVisible"
            >Fritext</BaseButton
          >
          <BaseButton
            v-else-if="!isSkandia"
            :is-loading="isSaving"
            :is-rounded="true"
            :is-warning="true"
            :is-disabled="note.length === 0"
            :icon="svgs.ICONS.ERROR_WHITE"
            @click="rejectApplication"
          >
            Neka byte
          </BaseButton>
          <BaseButton
            :is-disabled="isSaving"
            :is-rounded="true"
            :is-default="true"
            @click="$emit('close')"
            >Avbryt</BaseButton
          >
        </div>
      </div>
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import { mapGetters, mapActions } from 'vuex';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';
import OptionsModal from './OptionsModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'RejectModal',
  components: {
    DefaultModal,
    OptionsModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      note: '',
      rejectOptionModalVisible: false,
      textAreaVisible: false,
      rejectOptionsListVisible: true
    };
  },
  computed: {
    ...mapGetters({
      isSkandia: 'app/isSkandia'
    }),
    skandiaRejectOptions() {
      return [
        {
          id: 1,
          label: 'Nekat av annan hyresvärd',
          text: 'Nekat av annan hyresvärd'
        },
        {
          id: 2,
          label: 'Ej beaktansvärt skäl',
          text: 'Saknar / kan ej styrka beaktansvärt skäl'
        },
        {
          id: 3,
          label: 'Uppfyller inte inkomstkrav',
          text: 'Tillträdande hyresgäst uppfyller inte inkomstkrav'
        },
        {
          id: 4,
          label: 'Bristande boendereferenser',
          text: 'Tillträdande hyresgäst har bristande boendereferenser'
        },
        {
          id: 5,
          label: 'Har bott kortare tid än ett år',
          text: 'Någon av bytesparterna har bott kortare tid än ett år'
        },
        {
          id: 6,
          label: 'Oriktiga uppgifter / misstänkt skenbyte',
          text: 'Oriktiga uppgifter / misstänkt skenbyte'
        },
        {
          id: 7,
          label: 'Har tillgång till annat boende',
          text: 'Någon av bytesparterna har tillgång till annat boende'
        },
        {
          id: 8,
          label: 'Påtaglig olägenhet för hyresvärd',
          text: 'Påtaglig olägenhet för hyresvärd (trångboddhet, anpassning av bostad)'
        },
        { id: 9, label: 'Annan orsak', text: 'Annan orsak' }
      ];
    },
    skandiaRejectOptionsList() {
      if (this.isSkandia) {
        return this.skandiaRejectOptions.slice(0, -1);
      } else {
        return this.skandiaRejectOptions;
      }
    }
  },
  methods: {
    ...mapActions({
      rejectApp: 'applications/rejectApp'
    }),
    async rejectApplication() {
      this.isSaving = true;
      await this.rejectApp({ id: this.applicationId, note: this.note });
      churnService.track(
        events.CHANGE_SWAP_STATUS,
        features.CHANGE_SWAP_STATUS.REJECT
      );
      this.isSaving = false;
      this.$emit('onRejected');
    },
    async selectRejectOption(opt) {
      this.note = opt.text;
      this.rejectOptionModalVisible = true;
    },
    async sendRejectOption() {
      await this.rejectApplication();
      this.rejectOptionModalVisible = false;
    },
    handleRejectOptionsListVisible() {
      this.textAreaVisible = false;
    },
    handleTextAreaVisible() {
      this.textAreaVisible = true;
      this.note = '';
    }
  }
};
</script>

<style scoped>
.footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.footer-wrapper .button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.textarea-wrapper {
  overflow: hidden;
}
.area {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-inter);
  resize: none;
}
.area:focus {
  outline: none;
}
.reject-options-container {
  display: flex;
  flex-direction: column;
}
.reject-options-container .options-scroll-container {
  max-height: 35rem;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.reject-options-container .options-scroll-container::-webkit-scrollbar {
  width: 0.6rem;
}
.reject-options-container .options-scroll-container::-webkit-scrollbar-thumb {
  background: var(--color-light-grey);
}
.reject-options-container
  .options-scroll-container::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey);
}
.reject-options-container .text-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding-left: 1rem;
}
.reject-options-container .reject-options-btn {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  min-width: 100%;
  max-width: 100%;
}
.reject-options-container .reject-options-btn .reject-option {
  font-size: 1.2rem;
  background-color: #fff;
  color: var(--color-grey);
  padding: 1.2rem;
  transition: transform 0.2s ease;
  border-radius: 1rem;
  border: 0.2rem solid var(--color-light-grey);
}
.reject-options-container .reject-options-btn .reject-option:hover {
  transform: scale(1.05);
  color: var(--color-dark-blue);
}
.display-reject-options-container .display-reject-options-btn {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  min-width: 100%;
  max-width: 100%;
}
.display-reject-options-container
  .display-reject-options-btn
  .display-reject-options-text {
  font-size: 1.2rem;
  background-color: #fff;
  color: var(--color-grey);
  padding: 1.2rem;
  transition: transform 0.2s ease;
  border-radius: 1rem;
  border: 0.2rem solid var(--color-light-grey);
}
.display-reject-options-container
  .display-reject-options-btn
  .display-reject-options-text:hover {
  transform: scale(1.05);
  color: var(--color-dark-blue);
}
</style>
