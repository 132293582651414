<template>
  <div
    v-outside-click="closeDropdown"
    class="app-switcher-dropdown"
    :class="{ expanded: isDropdownOpen }"
  >
    <button
      class="selected-option dropdown-link"
      type="button"
      :disabled="!nonActiveBtn"
      @click="toggleDropdown"
    >
      <div class="icon-text-container">
        <BtnIcon />
        {{ selectedOption }}
      </div>
      <div v-if="nonActiveBtn" class="arrow-container">
        <div
          class="arrow"
          :class="{ up: isDropdownOpen, down: !isDropdownOpen }"
        ></div>
      </div>
    </button>
    <div v-if="nonActiveBtn" class="options-list">
      <button
        type="button"
        class="dropdown-link link"
        @click="handleClick(nonActiveBtn.id)"
      >
        <LockIcon v-if="nonActiveBtn.isLocked" class="lock-icon" />
        <BtnIcon v-else />
        {{ nonActiveBtn.label }}
      </button>
    </div>
    <SubletInfoModal
      v-if="!ahAccess && modalVisible"
      @close="
        modalVisible = false;
        isDropdownOpen = false;
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { APP_NAMES } from '@/utils/constants';
import outsideClick from '@/directives/outside-click';
import SubletInfoModal from '@/components/SubletInfoModal.vue';
import BtnIcon from './BtnIcon.vue';
import LockIcon from './LockIcon.vue';

export default {
  name: 'AppSwitcherDropdown',
  directives: { outsideClick },
  components: {
    SubletInfoModal,
    BtnIcon,
    LockIcon
  },
  data() {
    return {
      APP_NAMES,
      isDropdownOpen: false,
      modalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      ahAccess: 'app/ahAccess',
      baAccess: 'app/baAccess'
    }),
    ...mapState({
      activeApp: state => state.app.activeApp
    }),
    selectedOption() {
      if (this.activeApp === 'bytesansokan') {
        return 'Lägenhetsbyten';
      }
      return 'Andrahand';
    },
    buttons() {
      return [
        {
          label: 'Lägenhetsbyten',
          id: this.APP_NAMES.BYTESANSOKAN,
          isLocked: !this.baAccess
        },
        {
          label: 'Andrahand',
          id: this.APP_NAMES.ANDRAHAND,
          isLocked: !this.ahAccess
        }
      ];
    },
    nonActiveBtn() {
      return this.buttons.find(button => button.id !== this.activeApp);
    }
  },
  methods: {
    ...mapActions({
      updateActiveApp: 'app/updateActiveApp'
    }),
    showModal() {
      this.modalVisible = true;
    },
    handleClick(id) {
      this.closeDropdown();
      if (id === this.APP_NAMES.ANDRAHAND) {
        if (!this.ahAccess) {
          this.showModal();
          return;
        }
        this.updateActiveApp(id);
        this.$router.push('/andrahand/alla');
      }

      if (id === this.APP_NAMES.BYTESANSOKAN) {
        if (!this.baAccess) {
          return;
        }
        this.updateActiveApp(id);
        this.$router.push('/byten/alla');
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }
};
</script>

<style scoped>
.app-switcher-dropdown {
  outline: none;
  background-color: #fff;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}
.app-switcher-dropdown.expanded {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-container .arrow {
  border: solid var(--color-grey);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
}
.arrow-container .arrow.down {
  transform: rotate(45deg);
}
.arrow-container .arrow.up {
  transform: rotate(-135deg);
}
.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-grey);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 1rem;
  user-select: none;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-light-grey);
  position: relative;
  z-index: 2;
}
.expanded .selected-option {
  border-radius: 1rem 1rem 0rem 0rem;
}
.selected-option .icon-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5px;
}
.selected-option[disabled] {
  cursor: default;
}
.selected-option[disabled]:hover {
  color: var(--color-grey);
}
.selected-option[disabled]:hover :deep(svg path) {
  fill: var(--color-grey);
}
.options-list {
  background-color: var(--color-white);
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid var(--color-light-grey);
  border-top: 0;
  z-index: 1;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0;
}
.options-list .link {
  display: flex;
  align-items: center;
  color: var(--color-grey);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
  text-decoration: none;
  border-radius: 0 0 1rem 1rem;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.options-list .link .lock-icon {
  margin-right: 1px;
  padding: 3px;
  margin-left: 2px;
}
.expanded .options-list {
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
.dropdown-link :deep(svg path) {
  fill: var(--color-grey);
}
.dropdown-link:hover {
  color: var(--color-blue);
}
.dropdown-link:hover :deep(svg path) {
  fill: var(--color-blue);
}
</style>
