var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Ta bort fil")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _vm.isUploader
          ? _c("p", { staticClass: "swap-description" }, [
              _vm._v(" Vill du radera filen "),
              _c("b", [_vm._v(_vm._s(_vm.fileName))]),
              _vm._v("? "),
            ])
          : _c("p", { staticClass: "swap-description" }, [
              _vm._v(" Filen "),
              _c("b", [_vm._v(_vm._s(_vm.fileName))]),
              _vm._v(
                " laddades upp av en annan handläggare, vill du radera den ändå? "
              ),
            ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-warning": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [_vm._v(" Radera ")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Avbryt")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }