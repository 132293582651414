var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Filtypen stöds inte")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("p", { staticClass: "f-description" }, [
          _vm._v(
            " Det gick inte att ladda upp filen/filerna eftersom filtypen inte stöds. Godkända filformat är "
          ),
          _c("strong", [_vm._v(_vm._s(_vm.readableAcceptedFiles))]),
          _vm._v(". "),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Stäng")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }