<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>
        <template v-if="applicantId === null">Filer</template>
        <template v-else>Filer / {{ getActiveFolderName }}</template>
      </DefaultModalTitle>
    </template>

    <template slot="body">
      <GeneratePdfModal
        v-if="currentModal === 'GENERATE_PDF' && !!documentsCollections"
        :application-id="applicationId"
        :parts="parts"
        :documents-collections="documentsCollections"
        :landlord-files="filesFromId"
        :has-scrive-document="hasScriveDocument"
        @close="currentModal = null"
      ></GeneratePdfModal>
      <AdditionalFilesModal
        v-if="additionalFilesModalVisible"
        :application-id="applicationId"
        @close="additionalFilesModalVisible = false"
      ></AdditionalFilesModal>
      <div class="file-browser-wrapper">
        <div class="file-browser-scroll">
          <a
            v-if="!applicantId && hasScriveDocument"
            :href="scriveLink"
            class="folder-name"
            target="_blank"
          >
            <FolderRow>
              <BaseImage :url="svgs.ICONS.PDF" class="icon"></BaseImage>
              <FolderInfo name="Signerad ansökan" />
            </FolderRow>
          </a>
          <div v-if="isLoading">Hämtar dokument...</div>
          <!-- <DocumentsFolderModal
            v-else-if="applicantId !== null"
            :application-id="applicationId"
            :active-collection="documentsByApplicantId"
            :active-members="
              parts.find(part => part.id === applicantId)?.members || []
            "
          /> -->
          <template v-else-if="applicantId !== null">
            <div v-if="activeFolder">
              <FolderRow
                v-for="file in activeFolder"
                :key="file.fileName"
                :href="getDocumentLink(file.id)"
                target="_blank"
                tag="a"
                @click="trackFileDownload"
              >
                <BaseImage
                  :url="docTypeUtils.getFileIcon(file.fileName)"
                  class="icon"
                ></BaseImage>
                <FolderInfo :name="docTypeUtils.getFilename(file.fileName)">
                  {{ docTypeUtils.getDocTypeStr(file.type) }} /
                  <span class="file-type">{{
                    docTypeUtils.getFileExtension(file.fileName)
                  }}</span
                  >,
                  {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                  /
                  <span class="fake-link">Ladda ner</span>
                </FolderInfo>
              </FolderRow>
            </div>
            <div v-else>
              <FolderRow
                v-if="documentGroups.memberOne.length"
                tag="button"
                type="button"
                @click="setActiveMemberIndex(0)"
              >
                <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
                <FolderInfo :name="activeMembers[0]?.name">
                  {{ documentGroups.memberOne.length }} uppladdade filer
                </FolderInfo>
              </FolderRow>
              <FolderRow
                v-if="documentGroups.memberTwo.length"
                tag="button"
                type="button"
                @click="setActiveMemberIndex(1)"
              >
                <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
                <FolderInfo :name="activeMembers[1]?.name || 'Medsökande'">
                  {{ documentGroups.memberTwo.length }} uppladdade filer
                </FolderInfo>
              </FolderRow>
              <FolderRow
                v-for="file in documentGroups.sharedDocs"
                :key="file.fileName"
                :href="getDocumentLink(file.id)"
                target="_blank"
                tag="a"
                @click="trackFileDownload"
              >
                <BaseImage
                  :url="docTypeUtils.getFileIcon(file.fileName)"
                  class="icon"
                ></BaseImage>
                <FolderInfo :name="docTypeUtils.getFilename(file.fileName)">
                  {{ docTypeUtils.getDocTypeStr(file.type) }} /
                  <span class="file-type">{{
                    docTypeUtils.getFileExtension(file.fileName)
                  }}</span
                  >,
                  {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                  /
                  <span class="fake-link">Ladda ner</span>
                </FolderInfo>
              </FolderRow>
            </div>
          </template>
          <template v-else>
            <FolderRow
              tag="button"
              type="button"
              @click.stop.prevent="additionalFilesModalVisible = true"
            >
              <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
              <FolderInfo>
                {{ filesFromId.length }} uppladdade filer /
                <span class="upload-files">Ladda upp</span>
              </FolderInfo>
            </FolderRow>
            <FolderRow
              v-for="(collection, id) in documentsCollections"
              :key="id"
              tag="button"
              type="button"
              :disabled="!collection?.length"
              @click.stop.prevent="applicantId = id"
            >
              <BaseImage :url="svgs.ICONS.FOLDER" class="icon"></BaseImage>
              <FolderInfo :name="getSharedFolderName(id)">
                {{ collection ? collection.length : 0 }} uppladdade filer
              </FolderInfo>
            </FolderRow>
          </template>
        </div>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        v-if="applicantId == null"
        :is-rounded="true"
        :icon="svgs.ICONS.UPLOAD"
        @click.stop.prevent="additionalFilesModalVisible = true"
      >
        Ladda upp filer
      </BaseButton>

      <div v-if="applicantId == null" class="popupModal-container">
        <PopupModal
          v-if="popupModalVisible"
          :items="itemsInPopupModal"
          @close="popupModalVisible = false"
          @click="handlePopupClick"
        />
        <BaseButton
          :is-rounded="true"
          :icon="svgs.ICONS.DOWNLOAD"
          @click="popupModalVisible = !popupModalVisible"
        >
          Ladda ner filer
        </BaseButton>
      </div>

      <a
        v-if="applicantId !== null && !selectedType"
        :href="getZipFile(applicantId)"
      >
        <BaseButton :is-rounded="true" :icon="svgs.ICONS.DOWNLOAD">
          Ladda ner som ZIP
        </BaseButton>
      </a>

      <BaseButton
        v-if="applicantId !== null && !selectedType"
        :is-rounded="true"
        :is-default="true"
        @click.stop.prevent="handleGoBack"
        >Tillbaka</BaseButton
      >

      <BaseButton
        v-if="applicantId == null"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import { mapState } from 'vuex';
import docTypeUtils from '@/utils/docs-utils';
import svgs from '@/utils/icon-constants';
import churnService from '@/utils/churn360';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import { events, features } from '@/utils/trackingEvents';

import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseImage from '@/components/BaseImage.vue';
import GeneratePdfModal from '@/components/GeneratePdfModal.vue';
import AdditionalFilesModal from '@/components/AdditionalFilesModal.vue';
import PopupModal from '@/components/PopupModal.vue';
import FolderRow from '@/components/FolderRow.vue';
import FolderInfo from '@/components/FolderInfo.vue';

export default {
  name: 'DocumentsModal',
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton,
    BaseImage,
    GeneratePdfModal,
    AdditionalFilesModal,
    PopupModal,
    FolderRow,
    FolderInfo
  },

  props: {
    applicationId: {
      type: String,
      default: null
    },

    selectedApplicantId: {
      type: String,
      default: null
    },

    hasScriveDocument: {
      type: Boolean,
      default: false
    },

    selectedType: {
      type: String,
      default: null
    },

    parts: {
      type: Array,
      default: null
    },

    documentsCollections: {
      type: [Object, null],
      default: null
    },

    isLoading: {
      type: Boolean,
      default: false
    },
    initialMemberIndex: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      svgs,
      docTypeUtils,
      applicantId: null,
      additionalFilesModalVisible: false,
      popupModalVisible: false,
      currentModal: null,
      itemsInPopupModal: [
        {
          title: 'Ladda ner som ZIP',
          icon: svgs.ICONS.DOWNLOAD_GREY,
          content: '',
          cta: 'Klicka här för att ladda ner alla filer',
          type: 'DOWNLOAD_ZIP'
        },
        {
          title: 'Generera PDF',
          icon: svgs.ICONS.PDF_ALT_GREY,
          content: '',
          cta: 'Klicka här för att generera PDF',
          type: 'GENERATE_PDF'
        }
      ],
      activeMemberIndex: -1
    };
  },

  computed: {
    ...mapState({
      landlordFiles: state => state.landlordFiles.files
    }),
    filesFromId() {
      //T
      return this.landlordFiles[this.applicationId] || [];
    },
    scriveLink() {
      return `${this.$http.apiEndpoint}/documents/scrive/${this.applicationId}`;
    },
    activeMembers() {
      return this.getPartsMembersById(this.applicantId) || [];
    },
    documentsByApplicantId() {
      const documents =
        this.documentsCollections[this.applicantId]?.filter(
          file => !this.selectedType || this.selectedType == file.type
        ) || [];

      const typeOrder = {
        MIN_UC: 0,
        ID_PROOF: 1,
        INCOME_BASE: 2,
        RENTAL_AGREEMENT: 3,
        RENTAL_BASE: 4,
        OTHER_DOCUMENTS: 5
      };

      return documents.sort((a, b) => {
        // Sort by typeOrder
        if (typeOrder[a.type] < typeOrder[b.type]) return -1;
        if (typeOrder[a.type] > typeOrder[b.type]) return 1;

        // If types are equal, sort by order
        if (a.order !== b.order) {
          return a.order - b.order;
        }

        // If orders are equal, sort by fileName
        return a.fileName.localeCompare(b.fileName);
      });
    },
    documentGroups() {
      const sharedDocs = this.documentsByApplicantId.filter(
        doc => doc.memberIndex === null
      );
      const memberOne = this.documentsByApplicantId.filter(
        doc => doc.memberIndex === 0
      );
      const memberTwo = this.documentsByApplicantId.filter(
        doc => doc.memberIndex === 1
      );
      return {
        sharedDocs,
        memberOne,
        memberTwo
      };
    },
    activeFolder() {
      if (this.activeMemberIndex === -1) {
        return null;
      }
      return this.documentsByApplicantId.filter(
        doc => doc.memberIndex === this.activeMemberIndex
      );
    },

    getActiveFolderName() {
      if (this.applicantId) {
        if (this.activeMemberIndex > -1) {
          const members = this.getPartsMembersById(this.applicantId);
          return members[this.activeMemberIndex]?.name || '';
        }
        return this.getSharedFolderName(this.applicantId);
      }
      return this.getSharedFolderName();
    }
  },

  created() {
    if (this.selectedApplicantId) {
      this.applicantId = this.selectedApplicantId;
    }
    if (this.initialMemberIndex > -1) {
      this.setActiveMemberIndex(this.initialMemberIndex);
    }
  },

  methods: {
    getPartsMembersById(applicantId) {
      if (!applicantId) {
        return [];
      }
      return this.parts
        .filter(part => part.id === applicantId)
        .map(part => part.members)
        .flat();
    },
    getSharedFolderName(applicantId) {
      if (!applicantId) {
        return '';
      }

      return this.parts
        .filter(part => part.id === applicantId)
        .map(part =>
          part.members
            .map(m => (!m.name || (m.name && m.name == 'null') ? '-' : m.name))
            .join(' & ')
        )
        .join(' & ');
    },
    handleGoBack() {
      if (this.activeMemberIndex > -1) {
        this.activeMemberIndex = -1;
      } else if (this.applicantId) {
        this.applicantId = null;
      }
    },
    setActiveMemberIndex(index) {
      this.activeMemberIndex = index;
    },
    trackFileDownload() {
      churnService.track(events.FILES, features.FILES.DOWNLOAD_FILE);
    },
    trackAllFileDownload() {
      churnService.track(events.FILES, features.FILES.DOWNLOAD_ALL_FILES);
    },

    getDocumentLink(id) {
      return `${this.$http.apiEndpoint}/documents/${this.applicationId}/${id}`;
    },

    getZipFile(applicantId) {
      if (!applicantId) {
        return `${this.$http.apiEndpoint}/documents/zip/${this.applicationId}`;
      }

      let memberIndex = null;
      switch (this.activeMemberIndex) {
        case 0:
          memberIndex = 'ONE';
          break;
        case 1:
          memberIndex = 'TWO';
          break;
        default:
          memberIndex = null;
      }

      const query = memberIndex ? `?memberIndex=${memberIndex}` : '';
      return `${this.$http.apiEndpoint}/documents/zip/${this.applicationId}/${applicantId}${query}`;
    },
    handlePopupClick(type) {
      switch (type) {
        case 'DOWNLOAD_ZIP':
          if (this.applicantId === null) {
            const zipFileUrl = this.getZipFile(null);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = zipFileUrl;
            anchor.download = 'file.zip';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            this.trackAllFileDownload();
          }

          break;
        case 'GENERATE_PDF':
          this.currentModal = 'GENERATE_PDF';
          break;
        default:
      }

      this.popupModalVisible = false;
    }
  }
};
</script>

<style scoped>
.file-browser-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}

.file-browser-scroll {
  max-height: 50vh;
  overflow-y: auto;
}

.icon {
  display: inline-flex;
  height: 36px;
  width: 45px;
  margin-right: 12px;
}

.popupModal-container {
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
}
</style>
