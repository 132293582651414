const events = {
  SENT_MESSAGE: 'skickade ett meddelande',
  CHANGE_SWAP_STATUS: 'ändrade bytets status',
  UPDATE_MANAGER: 'ändrade handläggare',
  UPDATE_SECONDARY_MANAGER: 'ändrade andra handläggare',
  CHANGE_SWAP_CHECKLIST: 'ändrade checklista för byte',
  FILES: 'filer'
};

const features = {
  SENT_MESSAGE: {
    GENERAL: 'Interna noteringar',
    CHAT: 'Chatt hyresvärd'
  },
  CHANGE_SWAP_STATUS: {
    REJECT: 'nekade byte',
    CHANGE_TO_REJECT: 'ändrade till nekat byte',
    APPROVE: 'godkände byte',
    CHANGE_TO_APPROVE: 'ändrade till godkänt byte',
    REMOVE: 'raderade byte',
    ABORT: 'avbröt byte'
  },
  UPDATE_MANAGER: {
    ASSIGN: 'tilldelade en handläggare',
    UNASSIGN: 'ändrade till "Ej tilldelad"'
  },
  UPDATE_SECONDARY_MANAGER: {
    ASSIGN: 'tilldelade en andra handläggare',
    UNASSIGN: 'ändrade till "Ej tilldelad"'
  },
  CHANGE_SWAP_CHECKLIST: {
    CHECK: type => `godkände ${type}`,
    UNCHECK: type => `tog bort godkännande av ${type}`
  },
  FILES: {
    DOWNLOAD_FILE: 'laddade ned en fil',
    DOWNLOAD_ALL_FILES: 'laddade ned alla filer',
    GENERATE: 'genererade PDF'
  }
};

export { events, features };
