<template>
  <DefaultModal class="options-modal-wrapper" @close="$emit('close')">
    <template slot="body">
      <div v-if="isChat" class="body-wrapper">
        <h2>Vill du skicka:</h2>
        <h3>'{{ optionToSend }}' <span class="light-text">i chatten?</span></h3>
      </div>
      <div v-else-if="isRejectReason" class="body-wrapper">
        <h2>Vill du välja:</h2>
        <h3>
          '{{ optionToSend }}'
          <span class="light-text"> för att neka bytet?</span>
        </h3>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        v-if="isChat"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        @click="$emit('send')"
        >Skicka</BaseButton
      >
      <BaseButton
        v-else-if="isRejectReason"
        :is-rounded="true"
        :is-warning="true"
        :icon="svgs.ICONS.ERROR_WHITE"
        @click="$emit('send')"
      >
        Neka byte
      </BaseButton>
      <BaseButton :is-rounded="true" :is-default="true" @click="$emit('close')"
        >Stäng</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import outsideClick from '@/directives/outside-click';
import svgs from '@/utils/icon-constants';

export default {
  name: 'OptionsModal',
  directives: { outsideClick },
  components: {
    DefaultModal,
    BaseButton
  },
  props: {
    optionToSend: {
      type: String,
      required: true
    },
    isChat: {
      type: Boolean,
      default: false
    },
    isRejectReason: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs
    };
  }
};
</script>

<style scoped>
.options-modal-wrapper :deep(.footer) {
  padding: 15px 35px;
}
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  width: 450px;
}
.body-wrapper h3 {
  color: var(--color-dark-blue);
}
.body-wrapper .light-text {
  color: var(--color-grey);
}
</style>
