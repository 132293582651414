<template>
  <ErrorModal v-if="errorModalVisible" @close="$emit('close')" />
  <DefaultModal v-else @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ta bort notis</DefaultModalTitle>
    </template>

    <template slot="body">
      <p>Är du säker på att du vill ta bort <b>din notis?</b></p>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isLoading"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        @click="deleteNote(applicationId, noteId)"
        >Ta bort</BaseButton
      >
      <BaseButton
        :is-disabled="isLoading"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import ErrorModal from '@/components/ErrorModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton,
    ErrorModal
  },

  props: {
    applicationId: {
      type: String,
      required: true
    },
    noteId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      svgs,
      isLoading: false,
      errorModalVisible: false
    };
  },

  methods: {
    async deleteNote() {
      this.isLoading = true;
      this.errorModalVisible = false;
      try {
        await this.$http.deleteNote(this.applicationId, this.noteId);
        this.$emit('onDeleteSuccess');
      } catch {
        this.errorModalVisible = true;
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
p {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}
p b {
  color: var(--color-dark-blue);
}
</style>
