<template>
  <ErrorModal v-if="errorModalVisible" @close="$emit('close')" />
  <DefaultModal v-else @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ändra notis</DefaultModalTitle>
    </template>

    <template slot="body">
      <textarea
        :value="note"
        class="textarea"
        :disabled="isLoading"
        cols="30"
        rows="5"
        @input="note = $event.target.value"
      ></textarea>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isLoading"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        :is-disabled="note.length === 0"
        @click="updateNote(applicationId, note, noteId)"
      >
        Uppdatera
      </BaseButton>
      <BaseButton
        :is-disabled="isLoading"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import ErrorModal from '@/components/ErrorModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton,
    ErrorModal
  },

  props: {
    originalNote: {
      type: String,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    noteId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      svgs,
      isLoading: false,
      note: '',
      errorModalVisible: false
    };
  },

  mounted() {
    this.note = this.originalNote;
  },

  methods: {
    async updateNote() {
      this.isLoading = true;
      this.errorModalVisible = false;
      try {
        await this.$http.editNote(this.applicationId, this.note, this.noteId);
        this.$emit('onEditSuccess', this.note);
      } catch {
        this.errorModalVisible = true;
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.textarea {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--font-inter);
}
</style>
