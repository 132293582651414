<template>
  <div class="card" :class="{ 'h-300': height300, 'h-500': height500 }">
    <h3 class="card__title">{{ title }}<small v-html="subtitle"></small></h3>
    <div class="card__body">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: null
    },
    height300: {
      type: Boolean,
      default: false
    },
    height500: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
.card {
  max-width: 100%;
}
.card__title {
  font-weight: 600;
  font-size: 2.6rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #2c3e50;
}
.card__title small {
  font-weight: 400;
  font-size: 1.6rem;
  display: block;
}
.card__body:not(:empty) {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}
.card__body:not(:empty):nth-last-child(1) {
  margin-bottom: 0;
}
.card__body:not(:empty) p {
  color: var(--color-dark-blue);
  margin: 0;
  line-height: 1.5;
  font-size: 2rem;
  gap: 1rem;
}
.card__body:not(:empty) p strong {
  font-weight: 600;
}
.card__body:not(:empty) p .big {
  font-size: 2.6rem;
}
.card__body:not(:empty) p .green {
  color: #40c07b;
}
.card__body:not(:empty) p .red {
  color: #ff3843;
  font-size: 2.6rem;
}
.card__body:not(:empty) p .pink {
  color: #c036c2;
}
.h-500 .card__body:not(:empty) > canvas {
  height: 500px;
}
.h-300 .card__body:not(:empty) > canvas {
  height: 300px;
}
</style>
