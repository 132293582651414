var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultPieChart", {
    attrs: { data: _vm.data, loading: _vm.loading },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }