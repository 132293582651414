<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Ändra bytet till godkänd</DefaultModalTitle>
    </template>

    <template slot="body">
      <div v-if="isFetchingNote" class="spinner-container">
        <div class="spinner"><BaseSpinner /></div>
      </div>
      <p v-else class="reject-reason">
        <template v-if="lastChangedReason">
          <p>
            (<b>{{ lastChangedReason.name }}</b> {{ lastChangedReason.text }})
          </p>
        </template>
      </p>
    </template>

    <template slot="footer">
      <template v-if="!isFetchingNote">
        <BaseButton
          :is-loading="isSaving"
          :is-rounded="true"
          :icon="svgs.ICONS.CHECK_WHITE"
          @click="approveApplication"
          >Ändra till godkänd</BaseButton
        >
        <BaseButton
          :is-disabled="isSaving"
          :is-rounded="true"
          :is-default="true"
          @click="$emit('close')"
          >Avbryt</BaseButton
        >
      </template>
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import svgs from '@/utils/icon-constants';
import { mapActions } from 'vuex';
import { /*statusReason ,*/ getLastChangedReason } from '@/utils/statusReason';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    DefaultModal,
    BaseButton,
    BaseSpinner,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false,
      isFetchingNote: false,
      lastChangedReason: null
    };
  },
  async mounted() {
    this.isFetchingNote = true;
    try {
      this.lastChangedReason = await getLastChangedReason(this.applicationId);
    } catch {
      console.error('Something went wrong');
    }
    this.isFetchingNote = false;
  },

  methods: {
    ...mapActions({
      approveApp: 'applications/approveApp'
    }),
    async approveApplication() {
      this.isSaving = true;
      await this.approveApp({ id: this.applicationId });
      churnService.track(
        events.CHANGE_SWAP_STATUS,
        features.CHANGE_SWAP_STATUS.CHANGE_TO_APPROVE
      );
      this.isSaving = false;
      this.$emit('onApproved');
    }
  }
};
</script>

<style scoped>
.reject-reason {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

.reject-reason b {
  color: var(--color-dark-blue);
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 4rem;
  width: 4rem;
}

#spinner {
  border-top-color: var(--color-blue);
}
</style>
