<template>
  <transition name="shuffle" appear>
    <BaseModal v-bind="$attrs" v-on="$listeners">
      <transition name="scale" appear>
        <div class="error-modal-wrapper">
          <section class="header flex-center border-bottom">
            <DefaultModalTitle>{{ title }}</DefaultModalTitle>
          </section>

          <section class="body">
            <p class="error-description">{{ message }}</p>
          </section>

          <section class="footer flex-center">
            <slot name="footer">
              <BaseButton
                :is-rounded="true"
                :is-default="true"
                @click="$emit('close')"
                >Stäng</BaseButton
              ></slot
            >
          </section>
        </div>
      </transition>
    </BaseModal>
  </transition>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  components: {
    BaseModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    title: {
      type: String,
      default: 'Något gick fel'
    },
    message: {
      type: String,
      default: 'Något gick fel, försök igen senare'
    }
  }
};
</script>

<style scoped>
.error-modal-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  min-width: 500px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}

.border-bottom {
  border-bottom: solid 1px #e8e8e8;
}

.header {
  color: var(--color-dark-blue);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex: 1;
}

.body {
  padding: 2rem 2rem 0px;
  max-height: 70vh;
  overflow: auto;
}

.footer {
  padding: 2rem;
}
.error-description {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
  color: var(--color-dark-blue);
  text-align: center;
}
</style>
