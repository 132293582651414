<template>
  <div class="application-wrapper">
    <TagButton v-if="showTagButton" :application-id="applicationId" />
    <router-link
      :to="to"
      class="application-wrapper-link"
      :class="{
        approved: status === statusTypes.APPROVED,
        rejected: status === statusTypes.REJECTED,
        aborted: status === statusTypes.ABORTED,
        new: isNew
      }"
    >
      <div>
        <h3 class="application-title">
          {{ type }}
          <span v-if="subtitle" :class="subtitleClass">{{ subtitle }}</span>
        </h3>

        <div class="info-wrapper">
          <slot name="body" />
        </div>
      </div>

      <section class="application-footer light">
        <div class="application-footer-info">
          <slot name="footer" />

          <p v-if="statusDate">
            {{ getStatusText(status) }}
            <b>
              {{ statusDate }}
            </b>
          </p>
        </div>
        <UserIcon
          class="user-icon"
          :first-name="fullName"
          :last-name="lastName"
          :show-icon="!fullName && !lastName"
        >
          <NotificationSymbol v-if="unreadNotes > 0" />
        </UserIcon>
      </section>
      <TagsContainer :tags="applicationTags" />
    </router-link>
  </div>
</template>

<script>
import UserIcon from '@/components/UserIcon.vue';
import NotificationSymbol from '@/components/NotificationSymbol.vue';
import TagButton from '@/components/TagButton.vue';
import TagsContainer from '@/components/TagsContainer.vue';

import statusTypes from '@/utils/statusTypes';

export default {
  components: {
    UserIcon,
    NotificationSymbol,
    TagButton,
    TagsContainer
  },
  props: {
    to: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    subtitleClass: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fullName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    statusDate: {
      type: String,
      default: ''
    },
    unreadNotes: {
      type: Number,
      default: 0
    },
    closedStatuses: {
      type: Array,
      default: () => []
    },
    applicationId: {
      type: String,
      default: ''
    },
    applicationTags: {
      type: Array,
      default: () => []
    },
    showTagButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusTypes
    };
  },
  methods: {
    getStatusText(status) {
      if (this.closedStatuses.includes(status)) {
        return 'Beslut taget:';
      }

      return 'Färdig för beslut:';
    }
  }
};
</script>

<style scoped>
.application-wrapper {
}

.application-wrapper-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 160px;
  background-color: #fff;
  min-width: 260px;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border: 3px solid transparent;
  color: inherit;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  height: 100%;
}

@media (min-width: 1100px) {
  .application-wrapper {
    transition: 0.2s linear;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
  }

  .application-wrapper:hover {
    transform: scale(1.023) translateZ(0);
  }
  .application-wrapper:hover .application-wrapper-link {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  }
}

.application-title {
  font-size: clamp(1.4rem, 0.8vw, 1.6rem);
  font-weight: 600;
  color: var(--color-gold);
  margin-bottom: 0.7rem;
}

.closed-title {
  color: var(--color-dark-blue);
}
.closedApproved-title {
  color: var(--status-closed);
}
.rejected-title {
  color: var(--status-rejected);
}
.aborted-title {
  color: var(--status-aborted);
}
.new-title {
  color: var(--status-waiting);
}

.application-footer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  color: #878b9d;
}

.application-footer-info {
  margin-top: 1rem;
}

.application-footer-info p {
  color: #878b9d;
  font-weight: 400;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  backface-visibility: hidden;
}

.application-footer-info p b {
  font-weight: 600;
}

.user-icon {
  align-self: flex-end;
}

.info-wrapper {
  padding-right: 2.4rem;
  backface-visibility: hidden;
}

.has-comment-icon {
  height: 2rem;
  width: 2rem;
  background-size: contain;
  margin-right: 1rem;
}

.address {
  color: var(--color-dark-blue);
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
  font-weight: 600;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: flex-start;
}

.address-text {
  flex: 1 1 auto;
}

.light {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 600;
}

.vector-icon {
  margin-top: 0.8rem;
  cursor: pointer;
}

.approved {
  border-color: #cef3df;
  background-color: #f5fffa;
}

.rejected {
  border-color: #fae1e0;
  background-color: #fff5f5;
}

.aborted {
  border-color: var(--status-aborted);
  background-color: #fffefa;
}

.new {
  border-color: #ced6f3;
  background-color: #f5f9ff;
}

.landlord {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 400;
}
</style>
