<template>
  <i
    class="notification-symbol"
    :style="{
      'border-color': borderColor,
      right: offsetRight ? `${offsetRight}px` : null,
      top: offsetTop ? `${offsetTop}px` : null
    }"
  >
  </i>
</template>

<script>
export default {
  props: {
    borderColor: {
      type: String,
      default: '#fff'
    },
    offsetRight: {
      type: Number,
      default: 0
    },
    offsetTop: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped>
.notification-symbol {
  width: 1rem;
  height: 1rem;
  border: 2px solid;
  display: inline-block;
  border-radius: 50%;
  background-color: var(--color-red);
  position: absolute;
  top: -2px;
  right: -2px;
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.1);
  animation: fade-border 1.7s infinite;
  animation-timing-function: linear;
}

@keyframes fade-border {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0);
  }
}
</style>
