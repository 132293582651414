var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _c(
      "div",
      { staticClass: "logo-wrapper" },
      [
        _c("BaseImage", { attrs: { url: _vm.svgs.logo } }),
        _c("img", {
          staticClass: "app-title flex-center",
          attrs: { src: _vm.svgs.LOGO },
        }),
      ],
      1
    ),
    !_vm.brf
      ? _c(
          "div",
          { staticClass: "app-switcher-wrapper" },
          [_c("AppSwitcherDropdown")],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "user-container" }, [
      _c("h3", [
        _vm._v(" Inloggad som: "),
        _c("span", { staticClass: "username" }, [
          _vm._v(
            " " +
              _vm._s(_vm.account.firstName) +
              " " +
              _vm._s(_vm.account.lastName)
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "links-wrapper" }, [
      _c(
        "ul",
        [
          _vm._l(_vm.activeMenu, function (item) {
            return _c(
              "li",
              { key: item.href },
              [
                _c(
                  "router-link",
                  { staticClass: "link", attrs: { to: item.href } },
                  [
                    item.svg
                      ? _c("div", { staticClass: "icon" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                viewBox: item.svg.viewBox,
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: item.svg.path,
                                  fill: "#878b9d",
                                  "fill-rule": item.svg.fillRule,
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.label))]),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._l(_vm.sharedMenu, function (item) {
            return _c(
              "li",
              { key: item.href },
              [
                _c(
                  "router-link",
                  { staticClass: "link", attrs: { to: item.href } },
                  [
                    item.svg
                      ? _c("div", { staticClass: "icon" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                viewBox: item.svg.viewBox,
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: item.svg.path,
                                  fill: "#878b9d",
                                  "fill-rule": item.svg.fillRule,
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.label))]),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }