var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "applicant-info-wrapper" },
          [
            _vm._l(_vm.content.members, function (member, index) {
              return _c("div", { key: index, staticClass: "member-wrapper" }, [
                _c("h3", { staticClass: "name" }, [
                  _vm._v(" " + _vm._s(member.name) + " "),
                  member.onCurrentContract
                    ? _c("span", { staticClass: "contract" }, [
                        _vm._v(" (Står på kontraktet) "),
                      ])
                    : _vm._e(),
                ]),
                _c("span", { staticClass: "address" }, [
                  _vm._v(
                    _vm._s(_vm.content.address) +
                      ", " +
                      _vm._s(_vm.content.city)
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "email" },
                  [
                    _c(
                      "BaseIconText",
                      { attrs: { "icon-url": _vm.svgs.ICONS.ENVELOPE } },
                      [_vm._v(" " + _vm._s(member.email || "-") + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "ssn" },
                  [
                    _c(
                      "BaseIconText",
                      {
                        attrs: {
                          "icon-url": _vm.svgs.ICONS.ADMINISTRATOR_GRAY,
                        },
                      },
                      [_vm._v(" " + _vm._s(member.ssn || "-") + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "phone" },
                  [
                    _c(
                      "BaseIconText",
                      { attrs: { "icon-url": _vm.svgs.ICONS.PHONE } },
                      [_vm._v(" " + _vm._s(member.phone || "-") + " ")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            _vm.content.propositionId
              ? _c(
                  "span",
                  { staticClass: "lb-connection" },
                  [
                    _c(
                      "BaseIconText",
                      {
                        attrs: {
                          "icon-url": _vm.svgs.ICONS.LB_LOGO,
                          size: "larger",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("Bytesparten kommer från Lägenhetsbyte.se"),
                        ]),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://api2.lagenhetsbyte.se/api/v2/preview",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.generatePreviewLink()
                              },
                            },
                          },
                          [_vm._v(" Visa annons på Lägenhetsbyte.se ")]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }