<template>
  <DefaultPage>
    <h1 class="page-title">Statistik</h1>
    <div class="content">
      <ChartCard title="Utfall av byten">
        <ApprovedSwapsChart
          :approved-amount="general.approved"
          :rejected-amount="general.rejected"
          :cancelled-amount="general.cancelled"
          :removed-amount="general.removed"
          :loading="loadingGeneral"
        />
      </ChartCard>
      <ChartCard title="Byte av hyresvärd">
        <SameLandlordChart
          :swaps-with-other-landlords-amount="
            userStats.movingWithinLandlord?.other
          "
          :swaps-within-landlord-amount="userStats.movingWithinLandlord?.same"
          :loading="loadingUserStats"
        />
      </ChartCard>

      <!-- <ChartCard title="Bytarnas kön" height300>
        <GenderChart />
      </ChartCard>

      <ChartCard title="Bytarnas ålder" height300>
        <GenderAgeChart />
      </ChartCard> -->

      <ChartCard
        title="Hur länge hyresgästen bott i lägenheten innan påbörjat byte"
        :subtitle="
          userStats?.avgYears
            ? `(Genomsnitt <b>${userStats.avgYears}</b> år)`.replace('.', ',')
            : ''
        "
        class="card-full-width"
        height500
      >
        <YearsBeforeChart
          :had-years-list="userStats.hadYearsList"
          :loading="loadingUserStats"
        />
      </ChartCard>
      <ChartCard
        title="Ansökningar senaste 12 månaderna"
        class="card-full-width"
        height500
      >
        <ApplicationsChart
          :sent-to-host="general.sentToHost"
          :loading="loadingGeneral"
        />
      </ChartCard>
      <ChartCard title="Typ av byte">
        <SwapTypeChart
          :swap-type-counts="swapTypes.swapTypeCounts"
          :loading="loadingSwapTypes"
        />
      </ChartCard>
      <div>
        <ChartCard title="Kuriosa">
          <BaseLoadingSpinner v-if="loadingGeneral" />
          <p v-else-if="general.avgSwapTimeDays">
            <strong class="big">
              {{ Math.round(general.avgSwapTimeDays) }}</strong
            >
            dagar är den genomsnittliga bytestiden
          </p>
          <p v-else>Ingen data tillgänglig</p>
        </ChartCard>
        <!-- <ChartCard>
          <p>
            <strong class="pink">Kvinna</strong>, <strong>32 år</strong> är den
            typiska bytaren
          </p>
        </ChartCard> -->
      </div>
    </div>
  </DefaultPage>
</template>
<script>
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';

import DefaultPage from '@/components/DefaultPage.vue';
import ChartCard from '@/components/ChartCard.vue';
// import GenderChart from '@/components/Charts/GenderChart.vue';
// import GenderAgeChart from '@/components/Charts/GenderAgeChart.vue';
import YearsBeforeChart from '@/components/Charts/YearsBeforeChart.vue';
import ApplicationsChart from '@/components/Charts/ApplicationsChart.vue';
import SameLandlordChart from '@/components/Charts/SameLandlordChart.vue';
import SwapTypeChart from '@/components/Charts/SwapTypeChart.vue';
import ApprovedSwapsChart from '@/components/Charts/ApprovedSwapsChart.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

ChartJS.register(Tooltip, Legend);
ChartJS.defaults.font.family = 'Averta Std, Helvetica, Arial, sans-serif';
ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.weight = '600';

export default {
  components: {
    ChartCard,
    SameLandlordChart,
    // GenderChart,
    // GenderAgeChart,
    YearsBeforeChart,
    ApplicationsChart,
    SwapTypeChart,
    ApprovedSwapsChart,
    BaseLoadingSpinner,
    DefaultPage
  },
  data() {
    return {
      general: {},
      swapTypes: {},
      userStats: {},
      loadingGeneral: false,
      loadingSwapTypes: false,
      loadingUserStats: false
    };
  },
  mounted() {
    this.getGeneralStatistics();
    this.swapTypesStatistics();
    this.userStatsStatistics();
  },
  methods: {
    async getGeneralStatistics() {
      this.loadingGeneral = true;
      try {
        const res = await this.$http.generalStatistics();
        this.general = res.data; //null || {}
      } catch (err) {
        console.error(err);
      }
      this.loadingGeneral = false;
    },
    async swapTypesStatistics() {
      this.loadingSwapTypes = true;
      try {
        const res = await this.$http.swapTypesStatistics();
        this.swapTypes = res.data; //null || {}
      } catch (err) {
        console.error(err);
      }
      this.loadingSwapTypes = false;
    },
    async userStatsStatistics() {
      this.loadingUserStats = true;
      try {
        const res = await this.$http.userStatsStatistics();
        this.userStats = res.data; //null || {}
      } catch (err) {
        console.error(err);
      }
      this.loadingUserStats = false;
    }
  }
};
</script>
<style scoped>
.content {
  margin-left: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  max-width: 100%;
  width: 1200px;
  padding-bottom: 20rem;
}

.card-full-width {
  grid-column: 1 / 3;
}

.page-title {
  color: var(--color-dark-blue);
  font-size: 3.2rem;
  margin: 3.2rem 3.2rem 3.2rem 4rem;
  height: 4rem;
  padding-bottom: 2rem;
}
</style>
