<template>
  <DefaultModal @close="closeModal">
    <template slot="header">
      <DefaultModalTitle>Uppladdade filer</DefaultModalTitle>
    </template>

    <template slot="body">
      <ErrorModal v-if="deleteFileError" @close="deleteFileError = false" />
      <ErrorModal
        v-else-if="!isUploadingFiles && failedUploads.length > 0"
        title="Notera"
        :message="`Det gick inte att ladda upp ${readableFileNames(
          failedUploads
        )}.`"
        @close="failedUploads = []"
      />
      <DeleteFileModal
        v-else-if="fileToBeDeleted"
        :file-name="fileToBeDeleted.fileName"
        :is-uploader="fileToBeDeleted.uploadedBy === account.userId"
        @confirm="deleteFile(fileToBeDeleted.fileName)"
        @close="fileToBeDeleted = null"
      />
      <FilesNotAcceptedModal
        v-else-if="notAcceptedModalVisible"
        :files="acceptedFiles"
        @close="notAcceptedModalVisible = false"
      />
      <div class="wrapper">
        <div v-if="isUploadingFiles" class="spinner-container">
          <div class="spinner-box">
            <div class="spinner">
              <BaseSpinner />
            </div>
            <p>Laddar upp {{ uploadedFilesInfo }} filer</p>
          </div>
        </div>
        <section class="upload-container">
          <input
            id="file"
            ref="fileInput"
            multiple
            hidden
            :disabled="isUploadingFiles"
            :accept="acceptedFiles"
            type="file"
            @change="checkFilesBeforeUpload($event.target.files)"
          />
          <label
            for="file"
            class="upload-area"
            :class="{ dragging: dragging, disabled: isUploadingFiles }"
            draggable
            @dragover.prevent="dragging = true"
            @dragleave.prevent="dragging = false"
            @drop.prevent="
              dragging = false;
              checkFilesBeforeUpload($event.dataTransfer.files);
            "
          >
            <BaseImage
              :url="svgs.ICONS.ADD_DOCUMENT"
              class="upload-image no-pointer-events"
            ></BaseImage>
            <p class="upload-text no-pointer-events">
              Släpp filen här eller klicka för att ladda upp
              <span class="text-light">({{ readableAcceptedFiles }})</span>
            </p>
          </label>
        </section>
        <div
          v-if="filesFromId.length"
          class="file-browser-wrapper"
          :class="{ 'is-loading': isUploadingFiles }"
        >
          <div>
            <transition-group
              v-for="file in filesFromId"
              :key="file.fileName"
              tag="div"
              name="shuffle"
              appear
            >
              <section
                :key="file.fileName"
                class="folder-row"
                :class="{
                  isRemoving: filesBeingRemoved.includes(file.fileName)
                }"
              >
                <BaseImage
                  :url="docTypeUtils.getFileIcon(file.fileName)"
                  class="icon"
                ></BaseImage>
                <div class="folder-info">
                  <div class="folder-name">
                    {{ docTypeUtils.getFilename(file.fileName) }}
                  </div>
                  <div class="file-meta">
                    <span class="file-type">{{
                      docTypeUtils.getFileExtension(file.fileName)
                    }}</span
                    >,
                    {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                    /
                    <button
                      class="file-link delete-link"
                      type="button"
                      :disabled="filesBeingRemoved.includes(file.fileName)"
                      @click="fileToBeDeleted = file"
                    >
                      Radera
                    </button>
                    /
                    <a
                      :href="getDocumentLink(file.fileName)"
                      target="_blank"
                      class="file-link download-link"
                      :disabled="filesBeingRemoved.includes(file.fileName)"
                      >Ladda ner</a
                    >
                  </div>
                </div>
              </section>
            </transition-group>
          </div>
        </div>
      </div>
      <p class="info-text">
        OBS! Filer som laddas upp är endast synliga för denna hyresvärd.
      </p>
    </template>

    <template slot="footer">
      <!-- <a v-if="filesFromId.length > 0" :href="getZipFile">
        <BaseButton
          v-if="filesFromId.length > 0"
          :is-rounded="true"
          :icon="svgs.ICONS.DOWNLOAD"
        >
          Ladda ner som ZIP
        </BaseButton>
      </a> -->
      <BaseButton :is-rounded="true" :is-default="true" @click="closeModal"
        >Tillbaka</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import docTypeUtils from '@/utils/docs-utils';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import DeleteFileModal from '@/components/DeleteFileModal.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import FilesNotAcceptedModal from '@/components/FilesNotAcceptedModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DefaultModal,
    BaseButton,
    BaseImage,
    BaseSpinner,
    DeleteFileModal,
    ErrorModal,
    FilesNotAcceptedModal,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      svgs,
      docTypeUtils,
      isUploadingFiles: false,
      failedUploads: [],
      fileToBeDeleted: null,
      uploadedFilesInfo: '',
      acceptedFiles: [
        'application/pdf',
        'image/jpeg',
        'image/gif',
        'image/png',
        'image/heic'
      ],
      dragging: false,
      notAcceptedModalVisible: false,
      deleteFileError: false,
      filesBeingRemoved: []
    };
  },
  computed: {
    ...mapState({
      files: state => state.landlordFiles.files,
      account: state => state.app.account
    }),
    readableAcceptedFiles() {
      return this.acceptedFiles.map(type => type.split('/')[1]).join(', ');
    },
    filesFromId() {
      return this.files[this.applicationId] || [];
    }
    // getZipFile() {
    //   return `${this.$http.apiEndpoint}/documents/zip/${this.applicationId}`;
    // }
  },

  methods: {
    ...mapActions({
      addLandlordFile: 'landlordFiles/addFile',
      removeLandlordFile: 'landlordFiles/removeFile'
    }),
    closeModal() {
      this.$emit('close');
    },
    readableFileNames(fileNames) {
      let namesStr = fileNames[0];

      for (let i = 1; i < fileNames.length; i++) {
        const currentName = fileNames[i];
        if (i === fileNames.length - 1) {
          namesStr += ` och ${currentName}`;
        } else {
          namesStr += `, ${currentName}`;
        }
      }
      return namesStr;
    },
    getDocumentLink(fileName) {
      return `${this.$http.apiEndpoint}/landlords/file/${this.applicationId}/${fileName}`;
    },

    async deleteFile(fileName) {
      this.fileToBeDeleted = null;
      this.filesBeingRemoved.push(fileName);

      try {
        await this.removeLandlordFile({
          applicationId: this.applicationId,
          fileName
        });
      } catch {
        this.deleteFileError = true;
      }

      this.filesBeingRemoved = this.filesBeingRemoved.filter(
        name => name !== fileName
      );
    },
    checkFilesBeforeUpload(files) {
      const notAccepted = [...files].some(
        file => !this.acceptedFiles.includes(file.type)
      );
      if (notAccepted) {
        this.notAcceptedModalVisible = true;
      } else {
        this.uploadFiles(files);
      }
    },
    async uploadFiles(files) {
      this.isUploadingFiles = true;

      // Upload in parallel
      await this.promisesProgress(
        [...files].map(async file => {
          try {
            await this.addLandlordFile({
              applicationId: this.applicationId,
              file
            });
          } catch {
            this.failedUploads.push(file.name);
          }
        }),
        completedAmount => {
          this.uploadedFilesInfo = `${completedAmount} / ${[...files].length}`;
        }
      );
      this.isUploadingFiles = false;
      this.uploadedFilesInfo = '';
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
    },
    promisesProgress(promises, callback) {
      let count = 0;
      callback(0);
      for (const promise of promises) {
        promise.then(() => {
          count++;
          callback(count);
        });
      }
      return Promise.all(promises);
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
  min-height: 90px;
}
.file-browser-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 2.5rem;
}
.file-browser-wrapper.is-loading {
  filter: blur(3px);
  opacity: 0.2;
}
.icon {
  display: inline-flex;
  height: 36px;
  width: 45px;
  margin-right: 12px;
}
.folder-row {
  padding: 1.3rem 3rem;
  display: flex;
  align-items: center;
  transition: 0.2s;
}
.folder-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.folder-row.isRemoving {
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}
.folder-info {
  font-weight: 600;
  width: 100%;
}
.folder-name {
  display: block;
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}
.file-meta {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 17px;
}
.file-meta .file-type {
  text-transform: capitalize;
}
.file-meta .file-link {
  text-decoration: underline;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
}
.file-meta .delete-link {
  color: var(--color-red);
}
.file-meta .download-link {
  color: var(--color-blue);
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: not-allowed;
}
.spinner-box {
  text-align: center;
}
.spinner-box p {
  margin-top: 2rem;
  color: var(--color-dark-blue);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
}
.spinner {
  height: 4rem;
  width: 4rem;
  display: inline-block;
}
#spinner {
  border-top-color: var(--color-blue);
}
.upload-image {
  height: 40px;
  width: 32px;
}
input::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  padding: 20px;
  border: 3px dashed var(--color-blue);
  text-align: center;
  line-height: 1.3;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  background-color: #e9efff;
  font-size: 14px;
}
.upload-area.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.text-light {
  color: #a0a0a0;
}
.upload-text {
  margin-top: 20px;
  width: 155px;
}
.dragging {
  background-color: #c9d8ff;
}
.info-text {
  padding-top: 5px;
}
</style>
