<template>
  <div class="splash-container">
    <ErrorModal
      v-if="entraStatus >= 400 && 499 >= entraStatus"
      :message="entraStatusMessage"
      @close="closeEntraModal"
    />
    <div class="logo-wrapper">
      <img class="app-title flex-center" :src="svgs.LOGO" />
    </div>
    <div class="banner">
      <div class="content">
        <h1>Portal</h1>
        <p>
          Bytesansökan förenklar bytesprocessen för dig som hyresvärd och alla
          bytesparter.
        </p>

        <p>
          Vi levererar ett komplett samt lättöverskådligt beslutsunderlag för
          att du som handläggare tryggt ska kunna ta ställning till ett byte.
        </p>
      </div>
      <img class="splash-image" :src="svgs.COMMUNITY" />
    </div>
    <form>
      <div v-if="!resetPasswordVisible" class="login-box">
        <div class="login-box-inner">
          <form @submit.stop.prevent="loginLocal({ username, password })">
            <h4>Logga in</h4>
            <div class="login-wrapper">
              <DefaultInput
                v-model="username"
                name="username"
                :disabled="isLoading"
                placeholder="Användarnamn"
                type="text"
                autofocus
                autocomplete="username"
                @clear="clearUsername"
              ></DefaultInput>
              <DefaultInput
                ref="passwordInput"
                v-model="password"
                name="password"
                :disabled="isLoading"
                placeholder="Lösenord"
                type="password"
                autocomplete="password"
                @clear="clearPassword"
              ></DefaultInput>
            </div>
            <p class="msg">
              <span v-if="showErrorMessage" class="error-msg"
                >Fel användarnamn och/eller lösenord</span
              >
            </p>
            <div class="login-button">
              <BaseButton
                type="submit"
                :is-loading="isLoading"
                :is-disabled="isLoading || !username"
                :is-fullwidth="false"
                :is-rounded="true"
                >Logga in</BaseButton
              >
            </div>
          </form>

          <p>
            <button
              class="simple-button"
              @click.stop.prevent="resetPasswordVisible = true"
            >
              Glömt lösenordet?
            </button>
          </p>
        </div>
        <div class="entra-box">
          <a
            :class="{
              disabled: entraIsLoading
            }"
            class="entra-login"
            :href="entraSignInUrl"
            @click="entraIsLoading = true"
            ><span class="entra-login-inner"
              >{{ entraIsLoading ? 'Loggar in...' : 'Logga in med Entra' }}
              <span v-if="entraIsLoading" class="spinner">
                <BaseSpinner /></span></span
          ></a>
        </div>
      </div>
      <DefaultModal
        v-else
        :blur-bg="true"
        @close="resetPasswordVisible = false"
      >
        <template slot="body">
          <div v-if="emailSent">
            <h4>Skickat!</h4>
            <p>Vänligen titta efter ett återställnings e-mail i din inbox.</p>
          </div>
          <form
            v-else
            @submit.stop.prevent="requestPasswordReset({ username })"
          >
            <h4>Återställ lösenord</h4>
            <p>
              Ett e-mail skickas till din e-postadress med instruktioner på hur
              du återställer ditt lösenord.
            </p>
            <div class="login-wrapper">
              <DefaultInput
                v-model="username"
                name="username"
                :disabled="isLoading"
                placeholder="E-postadress"
                type="text"
                autofocus
                @clear="clearUsername"
              ></DefaultInput>
            </div>

            <p class="msg">
              <span v-if="resetPasswordErrorVisible" class="error-msg"
                >Det gick inte att återställa lösenordet för användaren.</span
              >
            </p>

            <div class="login-button">
              <BaseButton
                type="submit"
                :is-loading="isLoading"
                :is-disabled="isLoading || !username"
                :is-fullwidth="false"
                :is-rounded="true"
                >Återställ lösenord</BaseButton
              >
            </div>
          </form>
          <p>
            <button
              class="simple-button link"
              @click.stop.prevent="resetPasswordVisible = false"
            >
              Tillbaka till inloggning
            </button>
          </p>
        </template>
      </DefaultModal>
    </form>
  </div>
</template>

<script>
import DefaultInput from '@/components/DefaultInput.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import svgs from '@/utils/icon-constants';
import http from './services/http';

export default {
  components: {
    DefaultInput,
    DefaultModal,
    BaseButton,
    BaseSpinner,
    ErrorModal
  },

  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      username: '',
      password: '',
      resetPasswordVisible: false,
      resetPasswordErrorVisible: false,
      emailSent: false,
      svgs,
      entraIsLoading: false
    };
  },
  computed: {
    entraStatusMessage() {
      if (this.entraStatus === 400) {
        return 'Vänligen se till att ditt konto har en giltig kontorsadress';
      }
      return 'Något gick fel, försök igen senare';
    },
    urlParams() {
      return new URLSearchParams(window.location.search);
    },
    entraStatus() {
      return Number(this.urlParams.get('entraStatus'));
    },
    baseUrl() {
      if (location.host.includes('localhost')) {
        return 'http://localhost:3001';
      } else if (
        location.host.includes('test') ||
        location.host.includes('demo')
      ) {
        return 'https://api.bytesansokan.se';
      }
      return 'https://api.bytesansokan.se';
    },
    entraSignInUrl() {
      return `${this.baseUrl}/entra/signin?redirectUri=${this.redirectUrl}`;
    },
    redirectUrl() {
      if (location.host.includes('localhost')) {
        return 'http://localhost:8080';
      } else if (
        location.host.includes('test') ||
        location.host.includes('demo')
      ) {
        return 'https://hyresvard-demo.bytesansokan.se';
      }
      return 'https://hyresvard.bytesansokan.se';
    }
  },
  methods: {
    async requestPasswordReset(username) {
      if (!username) {
        return;
      }

      this.resetPasswordErrorVisible = false;
      this.isLoading = true;

      try {
        await this.$http.requestPasswordReset(this.username.trim());
        this.emailSent = true;
      } catch (error) {
        console.log(error);
        this.resetPasswordErrorVisible = true;
      }

      this.isLoading = false;
    },

    async loginLocal(credentials) {
      this.isLoading = true;
      this.showErrorMessage = false;

      try {
        const res = await http.login(credentials);

        if (!res) {
          throw Error('Failed to login');
        }
        // Reload but keep params
        window.location.replace(`/${window.location.search}`);
      } catch (error) {
        this.password = '';
        this.showErrorMessage = true;

        this.$nextTick(() => {
          this.$refs.passwordInput.$el.querySelector('input').focus();
        });
      }

      this.isLoading = false;
    },
    clearUsername() {
      this.username = '';
    },
    clearPassword() {
      this.password = '';
    },
    closeEntraModal() {
      window.location.search = '';
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 1.6rem;
  margin-bottom: 14px;
}
.splash-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4em 3em;
  background-color: var(--color-blue);
  height: 50em;
  width: 100%;
  min-width: auto;
  display: block;
}
.splash-container .logo-wrapper {
  margin-bottom: 3em;
}
.splash-container .logo-wrapper img {
  height: 3.5em;
  filter: brightness(0) invert(1);
}
.splash-container .banner {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
}
.splash-container .banner .content h1 {
  color: #fff;
  font-size: 4em;
}
.splash-container .banner .content p {
  color: #fff;
  font-size: 2em;
}
.splash-container .banner .splash-image {
  max-height: 30em;
}
@media (max-width: 1050px) {
  .splash-container .banner .splash-image {
    display: none;
  }
}
.error-msg {
  display: block;
  color: #ff3843;
  padding-top: 2px;
  font-size: 1.2rem;
}
form {
  max-width: 430px;
}
.login-box {
  width: 50em;
  z-index: 10;
  margin-left: 2em;
  margin-top: -8em;
}
.login-box .login-box-inner {
  background: #fff;
  padding: 2.5em;
  border-radius: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1050px) {
  .login-box {
    margin-top: 2em;
    margin-left: 0em;
    width: 100%;
  }
}
.login-wrapper > *:not(:first-child) {
  margin-top: 10px;
}
.login-button {
  margin: 12px 0 20px 0px;
  display: flex;
  justify-content: center;
}
.simple-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 400;
  margin-bottom: 20px;
}
p {
  margin: 15px 0 15px 0;
  font-size: 14px;
}
.link {
  color: blue;
}
.msg {
  min-height: 20px;
  padding: 0;
  margin: 0;
}
.entra-box {
  margin-top: 1.5rem;
  text-align: center;
}
.entra-login {
  background-color: #409fff;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none;
  text-align: center;
  background-color: #fff;
  border: 2px solid var(--color-grey);
  color: var(--color-grey);
  padding: 1rem 2rem;
  width: 100%;
}
.entra-login .entra-login-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.entra-login .entra-login-inner span {
  margin-left: 1rem;
}
.entra-login.disabled {
  pointer-events: none;
  cursor: default;
}
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.spinner :deep(#spinner) {
  border-top-color: var(--color-blue);
}
</style>
