var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Neka ansökan på grund av")])],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c("transition", { attrs: { name: "textarea-toggle", appear: "" } }, [
            _c("div", { staticClass: "textarea-wrapper" }, [
              _c("textarea", {
                staticClass: "area",
                attrs: { disabled: _vm.isSaving, cols: "30", rows: "5" },
                domProps: { value: _vm.note },
                on: {
                  input: function ($event) {
                    _vm.note = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: {
                "is-loading": _vm.isSaving,
                "is-rounded": true,
                "is-warning": true,
                "is-disabled": _vm.note.length === 0,
                icon: _vm.svgs.ICONS.ERROR_WHITE,
              },
              on: { click: _vm.rejectAhApplication },
            },
            [_vm._v(" Neka ansökan ")]
          ),
          _c(
            "BaseButton",
            {
              attrs: {
                "is-disabled": _vm.isSaving,
                "is-rounded": true,
                "is-default": true,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Avbryt")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }