import Vue from 'vue';

function set(state, { applicationId, files }) {
  state.files[applicationId] = files;
}

function add(state, { applicationId, file }) {
  const existingIdx = state.files[applicationId].findIndex(
    existingFile => existingFile.fileName === file.fileName
  );

  if (existingIdx > -1) {
    // Existing file found, replace
    Vue.set(state.files[applicationId], existingIdx, file);
  } else {
    // Add new file
    state.files[applicationId].push(file);
  }
}

function remove(state, { applicationId, fileName }) {
  state.files = {
    ...state.files,
    [applicationId]: state.files[applicationId].filter(
      f => f.fileName !== fileName
    )
  };
}

export default {
  set,
  add,
  remove
};
