<template>
  <ApplicationsLane
    v-if="applications"
    :title="title"
    :applications="applications"
    :container-style="containerStyle"
    :status="status"
  >
    <template slot="controls">
      <SelectWrapper v-if="status === 'waiting'">
        <select v-model="activeSorting">
          <option value="sentToHost-NEW">Färdig för beslut (nyaste)</option>
          <option value="sentToHost-OLD">Färdig för beslut (äldsta)</option>
          <option value="swapDate-NEW">Önskar byta (senare inflytt)</option>
          <option value="swapDate-OLD">Önskar byta (tidigast inflytt)</option>
        </select>
      </SelectWrapper>
      <SelectWrapper v-else hidden>
        <select disabled></select>
      </SelectWrapper>
    </template>
    <template slot="body">
      <transition-group
        v-for="(groupedApplication, date, index) in groupedApplications"
        :key="date"
        name="fade-list"
        class="relative"
        :class="{ 'card-group': status === 'closed' }"
        tag="div"
        appear
      >
        <div v-if="status === 'closed'" :key="index" class="app-date">
          {{ getMonth(date) }}
        </div>
        <SwapApplicationCard
          v-for="application in groupedApplication"
          :key="application.id"
          :type="application.type"
          :application="application"
          :subtitle="subtitle(application)"
          :subtitle-class="subtitleClass(application)"
          :is-new="cardIsNew(application)"
          :full-name="fullName(application)"
          :last-name="lastName(application)"
          :swap-date="getSwapDate(application.swapDate)"
          :status-date="
            getStatusDate(
              application.status,
              application.statusUpdated,
              application.sentToHost
            )
          "
          :unread-notes="application?.unreadNotes"
          :parts="application.parts"
          :closed-statuses="closedStatuses"
        />
      </transition-group>
    </template>
  </ApplicationsLane>
</template>

<script>
import ApplicationsLane from '@/components/ApplicationsLane.vue';
import SwapApplicationCard from '@/components/SwapApplicationCard.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';

import statusTypes from '@/utils/statusTypes';

export default {
  name: 'SwapApplicationsLane',
  components: {
    SwapApplicationCard,
    ApplicationsLane,
    SelectWrapper
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: Object,
      default: null
    },
    applications: {
      type: [Array, null],
      default: null
    }
  },
  data() {
    return {
      activeSorting: 'sentToHost-NEW'
    };
  },
  computed: {
    closedStatuses() {
      return [
        statusTypes.CLOSED,
        statusTypes.REJECTED,
        statusTypes.APPROVED,
        statusTypes.ABORTED
      ];
    },
    groupedApplications() {
      const groups = {};
      const newApplications = [...this.applications];
      if (this.status === 'closed' || this.status === 'new') {
        newApplications.sort((a, b) => {
          const aDate = new Date(Number(a.created));
          const bDate = new Date(Number(b.created));
          return bDate - aDate;
        });

        newApplications.forEach(function (application) {
          let date = application.created
            ? new Date(Number(application.created)).toISOString().slice(0, 7)
            : '-';
          if (date in groups) {
            groups[date].push(application);
          } else {
            groups[date] = new Array(application);
          }
        });

        return groups;
      }

      return {
        0: newApplications.sort((a, b) => {
          if (this.activeSorting.includes('sentToHost')) {
            if (this.activeSorting.includes('NEW')) {
              return Number(b.sentToHost) - Number(a.sentToHost);
            }
            return Number(a.sentToHost) - Number(b.sentToHost);
          }
          if (this.activeSorting.includes('swapDate')) {
            if (this.activeSorting.includes('NEW')) {
              return (
                new Date(b.swapDate).getTime() - new Date(a.swapDate).getTime()
              );
            }
            return (
              new Date(a.swapDate).getTime() - new Date(b.swapDate).getTime()
            );
          }
          return true;
        })
      };
    }
  },
  methods: {
    getStatusDate(status, statusUpdated, sentToHost) {
      if (this.closedStatuses.includes(status)) {
        return statusUpdated ? statusUpdated.substring(0, 10) : '';
      }

      return sentToHost
        ? new Date(Number(sentToHost)).toISOString().slice(0, 10)
        : '';
    },
    getMonth(monthDate) {
      const month = monthDate.slice(5, 7);
      switch (month) {
        case '01':
          return 'Januari';
        case '02':
          return 'Februari';
        case '03':
          return 'Mars';
        case '04':
          return 'April';
        case '05':
          return 'Maj';
        case '06':
          return 'Juni';
        case '07':
          return 'Juli';
        case '08':
          return 'Augusti';
        case '09':
          return 'September';
        case '10':
          return 'Oktober';
        case '11':
          return 'November';
        case '12':
          return 'December';

        default:
          '';
      }
    },
    getSwapDate(date) {
      if (!date) {
        return '-';
      }
      const transformedDate = Date.parse(date);
      if (isNaN(transformedDate)) {
        return date;
      }

      return new Date(date).toLocaleDateString('sv-SE');
    },
    fullName(application) {
      return application.assignedLandlordUser?.firstName;
    },
    lastName(application) {
      return application.assignedLandlordUser?.lastName;
    },
    cardIsNew(application) {
      return application.status === statusTypes.READY && application.isNew;
    },
    subtitle(application) {
      if (this.cardIsNew(application)) {
        return 'Ny';
      }

      switch (application.status) {
        case statusTypes.CLOSED:
          return 'Avslutad';
        case statusTypes.APPROVED:
          return 'Godkänd';
        case statusTypes.REJECTED:
          return 'Nekad';
        case statusTypes.ABORTED:
          return 'Avbruten';
        default:
          return null;
      }
    },
    subtitleClass(application) {
      if (this.cardIsNew(application)) {
        return 'new-title';
      }

      switch (application.status) {
        case statusTypes.CLOSED:
          return 'closed-title';
        case statusTypes.APPROVED:
          return 'closedApproved-title';
        case statusTypes.REJECTED:
          return 'rejected-title';
        case statusTypes.ABORTED:
          return 'aborted-title';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.relative {
  position: relative;
}

.app-date {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 0.3rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-group > *:nth-last-child(1) {
  margin-bottom: 3rem;
}
</style>
