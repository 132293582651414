class ChurnService {
  usageCollector = null;

  async loadScript() {
    return new Promise((resolve, reject) => {
      const address =
        'https://app.churn360.com/usage-collector/v0.01/lib/usage-collector.min.js';
      const exists = document.querySelector(`script[src="${address}"]`);

      if (exists) {
        exists.onload = () => resolve();
      }
      const script = document.createElement('script');
      script.onload = () => resolve();
      script.async = true;
      script.src = address;
      script.onerror = () => {
        reject();
      };

      document.head.appendChild(script);
    });
  }

  async initialize(identifier, userEmail) {
    try {
      this.usageCollector = new churn360.UsageCollector();
      await this.usageCollector.initialize(
        process.env.VUE_APP_CHURN360,
        identifier,
        userEmail
      );
    } catch {
      this.usageCollector = null;
    }
  }

  async track(event, feature, additionalFields = {}) {
    if (!this.usageCollector) {
      return;
    }
    await this.usageCollector.track(event, feature, additionalFields);
  }

  async identify(traits, userId) {
    if (!this.usageCollector) {
      return;
    }
    await this.usageCollector.identify(traits, userId);
  }

  async page(additionalFields = {}) {
    if (!this.usageCollector) {
      return;
    }
    await this.usageCollector.page(additionalFields);
  }
}

const churnService = new ChurnService();

export default churnService;
