var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("BaseLoadingSpinner", { attrs: { "with-overlay": false, size: 60 } })
    : _vm.hasData
    ? _c("BaseLineChart", {
        staticClass: "warp-in",
        attrs: { options: _vm.options, data: _vm.data },
      })
    : !_vm.loading && !_vm.hasData
    ? _c("div", [_vm._v("Ingen data tillgänglig")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }