<template>
  <DefaultModal @close="$emit('close')">
    <template slot="header">
      <DefaultModalTitle>Godkänner du att</DefaultModalTitle>
    </template>

    <template slot="body">
      <p class="swap-description">
        <b>{{ currentMembers }}</b> flyttar ut från
        <b>{{ currentResidence }}</b> och att <b>{{ newMember }}</b> flyttar in?
      </p>
    </template>

    <template slot="footer">
      <BaseButton
        :is-loading="isSaving"
        :is-rounded="true"
        :icon="svgs.ICONS.CHECK_WHITE"
        @click="approveApplication"
        >Godkänn byte</BaseButton
      >
      <BaseButton
        :is-disabled="isSaving"
        :is-rounded="true"
        :is-default="true"
        @click="$emit('close')"
        >Avbryt</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import { mapState, mapActions } from 'vuex';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';

export default {
  name: 'ApprovalModal',
  components: {
    DefaultModal,
    BaseButton,
    DefaultModalTitle
  },

  props: {
    applicationId: {
      type: String,
      default: null
    },
    parts: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      svgs,
      isSaving: false
    };
  },
  computed: {
    ...mapState('app', ['ready', 'authenticated', 'account']),

    currentMembers() {
      return this.parts[0].members.map(m => m.name).join(' & ');
    },

    newMember() {
      return this.parts[this.parts.length - 1].members
        .map(m => m.name)
        .join(' & ');
    },

    currentResidence() {
      return this.parts[0].address;
    }
  },

  methods: {
    ...mapActions({
      approveApp: 'applications/approveApp'
    }),
    async approveApplication() {
      this.isSaving = true;
      await this.approveApp({ id: this.applicationId });
      churnService.track(
        events.CHANGE_SWAP_STATUS,
        features.CHANGE_SWAP_STATUS.APPROVE
      );
      this.isSaving = false;
      this.$emit('onApproved');
    }
  }
};
</script>

<style scoped>
.swap-description {
  display: block;
  width: 400px;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}

.swap-description b {
  color: var(--color-dark-blue);
}
</style>
