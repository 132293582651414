var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "outside-click",
          rawName: "v-outside-click",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "app-switcher-dropdown",
      class: { expanded: _vm.isDropdownOpen },
    },
    [
      _c(
        "button",
        {
          staticClass: "selected-option dropdown-link",
          attrs: { type: "button", disabled: !_vm.nonActiveBtn },
          on: { click: _vm.toggleDropdown },
        },
        [
          _c(
            "div",
            { staticClass: "icon-text-container" },
            [_c("BtnIcon"), _vm._v(" " + _vm._s(_vm.selectedOption) + " ")],
            1
          ),
          _vm.nonActiveBtn
            ? _c("div", { staticClass: "arrow-container" }, [
                _c("div", {
                  staticClass: "arrow",
                  class: { up: _vm.isDropdownOpen, down: !_vm.isDropdownOpen },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm.nonActiveBtn
        ? _c("div", { staticClass: "options-list" }, [
            _c(
              "button",
              {
                staticClass: "dropdown-link link",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.handleClick(_vm.nonActiveBtn.id)
                  },
                },
              },
              [
                _vm.nonActiveBtn.isLocked
                  ? _c("LockIcon", { staticClass: "lock-icon" })
                  : _c("BtnIcon"),
                _vm._v(" " + _vm._s(_vm.nonActiveBtn.label) + " "),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.ahAccess && _vm.modalVisible
        ? _c("SubletInfoModal", {
            on: {
              close: function ($event) {
                _vm.modalVisible = false
                _vm.isDropdownOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }