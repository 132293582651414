var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.errorModalVisible
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _c(
        "DefaultModal",
        {
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("DefaultModalTitle", [_vm._v("Ta bort notis")])],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("p", [
              _vm._v("Är du säker på att du vill ta bort "),
              _c("b", [_vm._v("din notis?")]),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    "is-rounded": true,
                    icon: _vm.svgs.ICONS.CHECK_WHITE,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteNote(_vm.applicationId, _vm.noteId)
                    },
                  },
                },
                [_vm._v("Ta bort")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-disabled": _vm.isLoading,
                    "is-rounded": true,
                    "is-default": true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Avbryt")]
              ),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }