<template>
  <Pie :options="chartOptions" :data="data" class="pie" />
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, ChartDataLabels);

export default {
  components: { Pie },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: '#fff',
            labels: {
              Text: {
                font: {
                  weight: 'bold',
                  size: 18
                }
              }
            },
            formatter: (value, data) => {
              const total = data.dataset.data.reduce((a, b) => a + b, 0);
              const percentage = Math.round((value / total) * 100 * 2) / 2;
              return `${percentage} %`;
            }
          },
          tooltip: {
            padding: 20,
            boxPadding: 5
          }
        },
        ...this.options
      };
    }
  }
};
</script>
<style scoped>
.pie {
  width: 100% !important;
  height: 100% !important;
}
</style>
