<template>
  <nav>
    <div class="logo-wrapper">
      <BaseImage :url="svgs.logo" />
      <img class="app-title flex-center" :src="svgs.LOGO" />
    </div>

    <div v-if="!brf" class="app-switcher-wrapper">
      <AppSwitcherDropdown />
    </div>

    <div class="user-container">
      <h3>
        Inloggad som:
        <span class="username">
          {{ account.firstName }} {{ account.lastName }}</span
        >
      </h3>
    </div>

    <div class="links-wrapper">
      <ul>
        <li v-for="item in activeMenu" :key="item.href">
          <router-link :to="item.href" class="link">
            <div v-if="item.svg" class="icon">
              <svg
                :viewBox="item.svg.viewBox"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  :d="item.svg.path"
                  fill="#878b9d"
                  :fill-rule="item.svg.fillRule"
                />
              </svg>
            </div>
            <span>{{ item.label }}</span>
          </router-link>
        </li>
        <li v-for="item in sharedMenu" :key="item.href">
          <router-link :to="item.href" class="link">
            <div v-if="item.svg" class="icon">
              <svg
                :viewBox="item.svg.viewBox"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  :d="item.svg.path"
                  fill="#878b9d"
                  :fill-rule="item.svg.fillRule"
                />
              </svg>
            </div>
            <span>{{ item.label }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import AppSwitcherDropdown from '@/components/AppSwitcherDropdown/AppSwitcherDropdown.vue';

export default {
  name: 'Menu',
  components: {
    BaseImage,
    AppSwitcherDropdown
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    ...mapState({
      account: state => state.app.account,
      sharedMenu: state => state.app.sharedMenu
    }),
    ...mapGetters({
      activeMenu: 'app/activeMenu',
      brf: 'app/brf'
    })
  }
};
</script>

<style scoped>
.logo-wrapper {
  margin: 42px 20px 0px 20px;
}

.app-switcher-wrapper {
  width: 100%;
  padding: 2rem 2rem 0 2.8rem;
}

.app-title {
  width: 100%;
  margin-top: 20px;
}

.user-container {
  margin-top: 4.2rem;
  margin-left: 3rem;
}

.user-container h3 {
  font-weight: 400;
  font-size: clamp(1rem, 0.7vw, 1.2rem);
  text-align: left;
}
.user-container .username {
  color: var(--color-dark-blue);
  font-weight: 600;
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
}

.links-wrapper {
  justify-content: center;
  margin-top: 47px;
}
.links-wrapper li {
  padding: 1rem 0;
}
.link {
  text-decoration: none;
  color: #878b9d;
  padding-left: 3rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  text-align: left;
  font-size: clamp(1.2rem, 0.8vw, 1.4rem);
  display: flex;
  align-items: center;
}
.link span {
  margin-left: 1.5rem;
}

.link svg {
  display: block;
}
.link:hover {
  color: var(--color-blue);
}

.link:hover svg path {
  fill: var(--color-blue);
}
.link.active {
  color: var(--color-blue);
  border-left: 3px solid var(--color-blue);
  padding-left: 27px;
}
.link.active svg path {
  fill: var(--color-blue);
}
.icon {
  height: 20px;
  width: 20px;
}
</style>
