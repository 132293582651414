<template>
  <DefaultModal @close="closeModal">
    <template slot="header">
      <DefaultModalTitle>
        {{ !loading ? 'Generera sammanfogad PDF' : 'Genererar PDF' }}
      </DefaultModalTitle>
    </template>

    <template slot="body">
      <ErrorModal
        v-if="hasError"
        message="Det gick inte att hämta filerna, försök igen senare"
        @close="hasError = false"
      />
      <div class="wrapper">
        <div v-if="loading" class="spinner-container">
          <div class="spinner-box">
            <div class="spinner">
              <BaseSpinner />
            </div>
            <p :class="{ visible: showLoadingInfo }">
              Det kan ta en stund att generera filen,<br />vänligen vänta
            </p>
          </div>
        </div>
        <div class="file-browser-wrapper" :class="{ 'is-loading': loading }">
          <div class="file-browser-scroll">
            <div v-if="hasScriveDocument" class="folder-name">
              <label class="folder-row">
                <BaseCheckbox
                  class="checkbox"
                  :checked="scriveChecked"
                  @change="toggleScriveChecked"
                ></BaseCheckbox>
                <BaseImage :url="svgs.ICONS.PDF" class="icon"></BaseImage>
                <div class="folder-info">Signerad ansökan</div>
              </label>
            </div>
            <div
              v-for="(collection, applicantId) in checkedDocumentCollection"
              :key="applicantId"
            >
              <div class="folder-row">
                <label class="folder-info underline">
                  <BaseCheckbox
                    class="checkbox"
                    :checked="docsCheckedFor(applicantId)"
                    @change="toggleCollectionChecked(applicantId)"
                  ></BaseCheckbox>
                  <div class="folder-label">
                    <div class="folder-name">
                      {{
                        !isAh ? getSharedFolderName(applicantId) : applicantId
                      }}
                    </div>
                    <div class="file-meta">
                      {{ collection ? collection.length : 0 }} uppladdade filer
                    </div>
                  </div>
                </label>
              </div>

              <template v-if="isAh">
                <label
                  v-for="file in collection"
                  :key="file.id"
                  class="folder-row"
                >
                  <BaseCheckbox
                    class="checkbox"
                    :checked="file?.checked === false ? false : true"
                    @change="toggleDocumentChecked(applicantId, file.id)"
                  ></BaseCheckbox>
                  <BaseImage
                    :url="docTypeUtils.getFileIcon(file.fileName)"
                    class="icon"
                  ></BaseImage>
                  <div class="folder-info">
                    <div class="folder-name">
                      {{ docTypeUtils.getFilename(file.fileName) }}
                    </div>
                    <div class="file-meta">
                      {{ docTypeUtils.getDocTypeStr(file.type) }} /
                      <span class="file-type">{{
                        docTypeUtils.getFileExtension(file.fileName)
                      }}</span
                      >,
                      {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                    </div>
                  </div>
                </label>
              </template>
              <div
                v-for="(groupCollection, currentIndex) in getCollectionGroup(
                  collection,
                  applicantId
                )"
                v-else
                :key="currentIndex"
                class="sub-group"
              >
                <div
                  v-if="groupCollection.label && groupCollection.docs?.length"
                  class="folder-row"
                >
                  <label class="folder-info underline">
                    <BaseCheckbox
                      class="checkbox"
                      :checked="
                        docsCheckedForSubgroup(
                          applicantId,
                          groupCollection.index
                        )
                      "
                      @change="
                        toggleSubGroupChecked(
                          applicantId,
                          groupCollection.index
                        )
                      "
                    ></BaseCheckbox>
                    <div class="folder-label">
                      <div class="folder-name">
                        {{ groupCollection.label }}
                      </div>
                      <div class="file-meta">
                        {{ groupCollection.docs.length }}
                        uppladdade filer
                      </div>
                    </div>
                  </label>
                </div>
                <label
                  v-for="file in groupCollection.docs"
                  :key="file.id"
                  class="folder-row"
                >
                  <BaseCheckbox
                    class="checkbox"
                    :checked="file?.checked === false ? false : true"
                    @change="toggleDocumentChecked(applicantId, file.id)"
                  ></BaseCheckbox>
                  <BaseImage
                    :url="docTypeUtils.getFileIcon(file.fileName)"
                    class="icon"
                  ></BaseImage>
                  <div class="folder-info">
                    <div class="folder-name">
                      {{ docTypeUtils.getFilename(file.fileName) }}
                    </div>
                    <div class="file-meta">
                      {{ docTypeUtils.getDocTypeStr(file.type) }} /
                      <span class="file-type">{{
                        docTypeUtils.getFileExtension(file.fileName)
                      }}</span
                      >,
                      {{ docTypeUtils.getHumanFileSize(file.fileSize) }}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        v-show="!loading"
        :is-rounded="true"
        :icon="svgs.ICONS.DOWNLOAD"
        :is-disabled="loading || customCheckedAmount === 0"
        @click="submitSelected"
      >
        {{ buttonText }}
      </BaseButton>

      <BaseButton
        v-if="loading"
        :is-rounded="true"
        :is-default="true"
        @click="cancelRequest"
        >Avbryt</BaseButton
      >

      <BaseButton
        v-else-if="customCheckedAmount === null"
        :is-rounded="true"
        :is-default="true"
        @click="closeModal"
        >Tillbaka</BaseButton
      >
      <BaseButton
        v-else
        :is-rounded="true"
        :is-default="true"
        @click="resetChecked"
        >Ångra</BaseButton
      >
    </template>
  </DefaultModal>
</template>

<script>
import docTypeUtils from '@/utils/docs-utils';
import DefaultModal from '@/components/DefaultModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import DefaultModalTitle from '@/components/DefaultModalTitle.vue';
import churnService from '@/utils/churn360';
import { events, features } from '@/utils/trackingEvents';

export default {
  components: {
    DefaultModal,
    DefaultModalTitle,
    BaseButton,
    BaseImage,
    BaseCheckbox,
    BaseSpinner,
    ErrorModal
  },

  props: {
    applicationId: {
      type: String,
      default: null
    },

    hasScriveDocument: {
      type: Boolean,
      default: false
    },

    parts: {
      type: Array,
      default: null
    },

    documentsCollections: {
      type: Object,
      default: null
    },
    isAh: {
      type: Boolean,
      default: false
    },

    landlordFiles: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      svgs,
      docTypeUtils,
      checkedDocumentCollection: {},
      scriveChecked: false,
      loading: false,
      mergeDocumentsController: null,
      hasError: false,
      showLoadingInfo: false
    };
  },
  computed: {
    customCheckedAmount() {
      let arr = [
        ...Object.keys(this.checkedDocumentCollection)
          .map(collection =>
            this.checkedDocumentCollection[collection].map(
              document => document.checked
            )
          )
          .flat()
      ];

      if (this.hasScriveDocument) {
        arr = [...arr, this.scriveChecked];
      }

      if (arr.includes(false)) {
        // Somethings not checked, return amount checked
        return arr.filter(isChecked => isChecked).length;
      }

      // All checked
      return null;
    },
    buttonText() {
      if (this.customCheckedAmount !== null) {
        if (this.customCheckedAmount === 0) {
          return 'Inga filer valda';
        } else {
          return `Ladda ner ${this.customCheckedAmount} markerade filer som PDF`;
        }
      }
      return 'Ladda ner alla filer som PDF';
    },
    allDocumentsCollection() {
      return {
        ...this.documentsCollections,
        ...(this.landlordFiles.length && {
          'Uppladdade filer': this.landlordFiles.map(file => ({
            fileName: file.fileName,
            fileSize: file.fileSize,
            uploadedBy: file.uploadedBy,
            isLandlordFile: true
          }))
        })
      };
    }
  },

  created() {
    this.mapCheckedDocs();
  },
  beforeDestroy() {
    //  Is fetching docs when destroying? Cancel req.
    this.cancelRequest();
  },
  methods: {
    getSharedFolderName(applicantId) {
      if (!applicantId) {
        return '';
      }
      const members = this.parts
        .filter(part => part.id === applicantId)
        .map(part => part.members)
        .flat();

      if (!members.length) {
        return 'Uppladdade filer';
      }
      return members
        .map(m => (!m.name || (m.name && m.name == 'null') ? '-' : m.name))
        .join(' & ');
    },
    getGroupName(applicantId, index) {
      if (index === null) {
        return this.getSharedFolderName(applicantId);
      }
      const members = this.parts
        .filter(part => part.id === applicantId)
        .map(part => part.members)
        .flat();
      return members[index]?.name || '';
    },
    getCollectionGroup(collection, collectionId) {
      if (collectionId === 'Uppladdade filer') {
        return [
          {
            label: '',
            index: null,
            docs: collection
          }
        ];
      }
      return [
        {
          label: this.getGroupName(collectionId, null),
          index: null,
          docs: collection.filter(doc => doc.memberIndex === null)
        },
        {
          label: this.getGroupName(collectionId, 0),
          index: 0,
          docs: collection.filter(doc => doc.memberIndex === 0)
        },
        {
          label: this.getGroupName(collectionId, 1),
          index: 1,
          docs: collection.filter(doc => doc.memberIndex === 1)
        }
      ];
    },
    closeModal() {
      this.$emit('close');
    },
    cancelRequest() {
      this.mergeDocumentsController?.abort();
    },
    resetChecked() {
      this.mapCheckedDocs();
    },
    mapCheckedDocs() {
      Object.keys(this.allDocumentsCollection).forEach(key => {
        const collection = this.allDocumentsCollection[key];
        if (collection) {
          this.checkedDocumentCollection[key] = collection.map(document => ({
            ...document,
            checked: true
          }));
        }
      });
      if (this.hasScriveDocument) {
        this.scriveChecked = true;
      }
    },
    docsCheckedFor(collectionId) {
      return this.checkedDocumentCollection[collectionId].every(
        document => document.checked
      );
    },
    docsCheckedForSubgroup(collectionId, memberIndex) {
      return this.checkedDocumentCollection[collectionId]
        .filter(doc => doc.memberIndex === memberIndex)
        .every(document => document.checked);
    },

    toggleScriveChecked() {
      this.scriveChecked = !this.scriveChecked;
    },

    toggleCollectionChecked(collectionId) {
      const selectedCollection = this.checkedDocumentCollection[collectionId];

      this.checkedDocumentCollection = {
        ...this.checkedDocumentCollection,
        [collectionId]: selectedCollection.map(item => ({
          ...item,
          checked: !this.docsCheckedFor(collectionId)
        }))
      };
    },

    toggleSubGroupChecked(collectionId, memberIndex) {
      const selectedCollection = this.checkedDocumentCollection[collectionId];
      const subGroupChecked = this.docsCheckedForSubgroup(
        collectionId,
        memberIndex
      );
      this.checkedDocumentCollection = {
        ...this.checkedDocumentCollection,
        [collectionId]: selectedCollection.map(doc => {
          if (doc.memberIndex === memberIndex) {
            return {
              ...doc,
              checked: !subGroupChecked
            };
          }
          return doc;
        })
      };
    },

    toggleDocumentChecked(collectionId, documentId) {
      const selectedCollection = this.checkedDocumentCollection[collectionId];
      const idx = selectedCollection.findIndex(
        document => document.id === documentId
      );

      selectedCollection[idx].checked = !selectedCollection[idx].checked;

      this.checkedDocumentCollection = {
        ...this.checkedDocumentCollection,
        [collectionId]: selectedCollection
      };
    },
    async submitSelected() {
      const selectedDocs = [];
      const selectedLandlordDocs = [];
      Object.keys(this.checkedDocumentCollection).forEach(collection => {
        this.checkedDocumentCollection[collection].forEach(document => {
          if (document.checked) {
            if (document.isLandlordFile) {
              selectedLandlordDocs.push(document.fileName);
            } else {
              selectedDocs.push(document.id);
            }
          }
        });
      });
      this.loading = true;
      this.mergeDocumentsController = new AbortController();
      const timeout = setTimeout(() => (this.showLoadingInfo = true), 2500);

      try {
        let base64;
        if (this.isAh) {
          base64 = await this.$http.mergeAhDocuments({
            applicationId: this.applicationId,
            scrive: this.hasScriveDocument && this.scriveChecked ? true : false,
            documentIds: selectedDocs,
            landlordDocs: selectedLandlordDocs,
            abortController: this.mergeDocumentsController
          });
        } else {
          base64 = await this.$http.mergeDocuments({
            applicationId: this.applicationId,
            scrive: this.hasScriveDocument && this.scriveChecked ? true : false,
            documentIds: selectedDocs,
            landlordDocs: selectedLandlordDocs,
            abortController: this.mergeDocumentsController
          });
        }

        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${base64}`;
        link.download = `${this.applicationId}.pdf`;
        link.click();
        churnService.track(events.FILES, features.FILES.GENERATE);
      } catch (e) {
        if (e?.message !== 'canceled') {
          this.hasError = true;
        }
      }

      clearTimeout(timeout);
      this.showLoadingInfo = false;
      this.loading = false;
      this.mergeDocumentsController = null;
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
.checkbox {
  font-size: 10px;
  margin-right: 10px;
}
.underline {
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
}
.file-browser-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.file-browser-wrapper.is-loading {
  filter: blur(3px);
  opacity: 0.2;
}
.file-browser-scroll {
  max-height: 50vh;
  overflow-y: auto;
}
.icon {
  display: inline-flex;
  height: 36px;
  width: 45px;
  margin-right: 12px;
}
.folder-row {
  padding: 1.3rem 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}
.folder-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.folder-info {
  font-weight: 600;
  width: 100%;
  cursor: pointer;
}
.folder-label {
  margin-left: 2px;
}
.folder-name {
  display: block;
  color: var(--color-dark-blue);
  font-size: 1.8rem;
  font-weight: 600;
}
.file-meta {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 17px;
}
.file-meta .file-type {
  text-transform: capitalize;
}
.file-meta .fake-link {
  color: var(--color-blue);
  text-decoration: underline;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
  cursor: not-allowed;
}
.spinner-box {
  text-align: center;
}
.spinner-box p {
  margin-top: 2rem;
  color: var(--color-dark-blue);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  transform: translateY(-6px);
}
.spinner-box p.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.spinner {
  height: 4rem;
  width: 4rem;
  display: inline-block;
}
#spinner {
  border-top-color: var(--color-blue);
}
.sub-group {
  padding-left: 2rem;
}
.sub-group :deep(.folder-name) {
  font-size: 1.6rem;
}
.sub-group :deep(.file-meta) {
  font-size: 1.2rem;
}
</style>
