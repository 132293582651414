var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseIconText",
    {
      attrs: {
        "icon-url": !_vm.hideIcon ? _vm.iconUrl : "",
        title: _vm.iconTitle,
      },
    },
    [
      _c("BaseCheckbox", {
        staticClass: "checkbox",
        attrs: { checked: _vm.isChecked, "is-disabled": _vm.isDisabled },
        on: {
          change: function ($event) {
            return _vm.$emit("checked", !_vm.isChecked)
          },
        },
      }),
      _c(
        "span",
        {
          class: {
            "has-files": !_vm.isDisabled && _vm.hasFiles,
          },
          on: { click: _vm.handleClick },
        },
        [_vm._t("default")],
        2
      ),
      _vm.comment
        ? _c(
            "button",
            {
              staticClass: "comment-icon",
              on: {
                click: function ($event) {
                  return _vm.$emit("selectedComment", _vm.comment)
                },
              },
            },
            [_c("BaseImage", { attrs: { url: _vm.svgs.ICONS.COMMENT } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }