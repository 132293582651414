var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      style: {
        "background-color": _vm.themeColor || _vm.generateBgColor(_vm.tagName),
      },
    },
    [_vm._v(_vm._s(_vm.tagName))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }