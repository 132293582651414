import http from '../services/http';

export async function getLastChangedReason(applicationId) {
  const notes = await http.getNotes(applicationId, [
    'APPROVAL',
    'REJECTION',
    'CLOSE',
    'ABORT'
  ]);
  const mostRecentNote = notes[notes.length - 1];
  return statusReason(mostRecentNote);
}

function statusReason(note, isLog) {
  if (!note) {
    return '';
  }

  let reason = '';
  let name = `${note.firstName} ${note.lastName}, ${note.landlordName}`;
  const pasteSence = !isLog ? 'tidigare' : '';

  switch (note.type) {
    case 'APPROVAL':
      reason = `har ${pasteSence} godkänt bytet från sin sida`;
      break;
    case 'REJECTION':
      if (note.text) {
        reason = `har ${pasteSence} nekat bytet med förklaringen "${note.text}"`;
      } else {
        reason = `har ${pasteSence} nekat bytet från sin sida`;
      }
      break;
    case 'ABORT_REMOVE':
      reason = `har ${pasteSence} avbrutit raderingen av bytet från sin sida`;
      break;
    case 'REMOVE':
      if (note.text) {
        reason = `har ${pasteSence} raderat bytet med förklaringen "${note.text}"`;
      } else {
        reason = `har ${pasteSence} raderat bytet från sin sida`;
      }
      break;
    case 'CLOSE':
      if (note.text) {
        reason = `har ${pasteSence} markerat bytet som avslutad från sin sida med kommentaren "${note.text}"`;
      } else {
        reason = `har ${pasteSence} markerat bytet som avslutad från sin sida`;
      }
      break;
    case 'ABORT':
      reason = `har ${pasteSence} markerat bytet som avbruten från sin sida`;
      break;
    case 'ASSIGN_USER':
      if (note.text) {
        reason = `har ${pasteSence} ändrat handläggare 1 till "${note.text}"`;
      } else {
        reason = `har ${pasteSence} ändrat handläggare 1`;
      }
      break;
    case 'UNASSIGN_USER':
      reason = `har ${pasteSence} tagit bort tilldelningen av handläggare 2`;
      break;
    case 'ASSIGN_SECOND_USER':
      if (note.text) {
        reason = `har ${pasteSence} handläggare 2 till "${note.text}"`;
      } else {
        reason = `har ${pasteSence} ändrat handläggare 2`;
      }
      break;
    case 'UNASSIGN_SECOND_USER':
      reason = `har ${pasteSence} tagit bort tilldelningen av handläggare 2`;
      break;
    case 'RESTORE':
      reason = `har ${pasteSence} återställt bytet från sin sida`;
      break;
    default:
      name = null;
      reason = note.text;
  }
  return {
    name: name,
    text: reason
  };
}
export default statusReason;
