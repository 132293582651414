<template>
  <component :is="tag" class="folder-row" v-on="$listeners"><slot /></component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  }
};
</script>

<style scoped>
.folder-row {
  padding: 1.3rem 3rem;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: 0.2s;
}
.folder-row[disabled] {
  opacity: 0.6;
  cursor: default;
}

.folder-row:not([disabled]):hover {
  background-color: rgba(0, 0, 0, 0.02);
}
</style>
