<template>
  <div id="spinner" :class="classes"></div>
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    classes: {
      type: Array,
      default: () => [],
      required: false
    }
  }
};
</script>

<style scoped>
#spinner {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
