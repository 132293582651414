<template>
  <div class="user-icon-wrapper" v-bind="$attrs">
    <div style="position: relative">
      <p
        :style="{
          'background-color': bgColor
        }"
        :class="['user-icon', showIcon ? 'icon' : '']"
      >
        {{ !showIcon ? initials : '' }}
      </p>
      <slot />
    </div>
    <span v-if="fullNameVisible" class="fullname">{{ fullName }}</span>
  </div>
</template>

<script>
import generateHSL from '@/utils/generateHSL';

export default {
  props: {
    firstName: {
      type: String,
      required: false,
      default: undefined
    },

    lastName: {
      type: String,
      required: false,
      default: undefined
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    fullNameVisible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasFullName() {
      return !!this.firstName && !!this.lastName;
    },
    bgColor() {
      if (this.showIcon) {
        return '#878b9d';
      }
      return generateHSL(this.fullName);
    },

    initials() {
      if (!this.hasFullName) {
        return '';
      }
      return this.firstName[0] + this.lastName[0];
    },

    fullName() {
      if (!this.hasFullName) {
        return '';
      }
      return this.firstName + ' ' + this.lastName;
    }
  }
};
</script>

<style scoped>
.user-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.fullname {
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.user-icon {
  font-weight: 600;
  font-size: 10px;

  width: 2.4rem;
  height: 2.4rem;
  background: #19cccc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.user-icon.icon {
  background: url('@/assets/svgs/icons/administrator.svg') center;
  background-repeat: no-repeat;
}
</style>
