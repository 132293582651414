var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    { on: { close: _vm.closeModal } },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Filter")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "filter-container" }, [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.activeApp === "bytesansokan"
                    ? "Status på bytet"
                    : "Status på ansökan"
                ) +
                " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "filter-btn-container" },
            _vm._l(_vm.activeCategories, function (status) {
              return _c(
                "button",
                {
                  key: status.id,
                  class: {
                    "filter-btn": true,
                    "is-selected": status.selected,
                  },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleActiveCategory(status.id)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(status.title) + " ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "filter-container" }, [
          _c("p", [_vm._v("Handläggare")]),
          _c(
            "div",
            { staticClass: "filter-btn-container filter-btn-container--grid" },
            _vm._l(_vm.landlordUsers, function (user) {
              return _c(
                "button",
                {
                  key: user.id,
                  class: {
                    "filter-btn": true,
                    "is-selected": user.selected,
                  },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleSelectedUsers(user.id)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(user.fullName) + " ")]
              )
            }),
            0
          ),
        ]),
        _vm.activeApp === "bytesansokan"
          ? _c("div", { staticClass: "filter-container" }, [
              _c("p", [_vm._v("Taggar")]),
              _c(
                "div",
                {
                  staticClass:
                    "filter-btn-container filter-btn-container--grid",
                },
                _vm._l(_vm.activeTags, function (tag) {
                  return _c(
                    "button",
                    {
                      key: tag.tagId,
                      staticClass: "filter-btn",
                      class: {
                        "is-selected": tag.selected,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleSelectedTags(tag.id)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(tag.title) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "filter-swap-dates" }, [
          _c("div", { staticClass: "filter-container" }, [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.activeApp === "bytesansokan"
                      ? "Bytet kom in"
                      : "Ansökan kom in"
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "dates-wrapper" }, [
              _c(
                "div",
                { staticClass: "dates-container" },
                [
                  _c("p", [_vm._v("Från")]),
                  _c("DatePickerButton", {
                    attrs: {
                      "active-date-picker": _vm.activeDatePicker,
                      "date-picker-value": _vm.datePickerValue.newCaseFromDate,
                      "date-picker-type": "newCaseFromDate",
                    },
                    on: { "toggle-date-picker": _vm.toggleDatePicker },
                  }),
                  _vm.activeDatePicker === "newCaseFromDate"
                    ? _c(
                        "BaseModal",
                        {
                          attrs: { "show-close-btn": false },
                          on: {
                            close: function ($event) {
                              return _vm.toggleDatePicker("newCaseFromDate")
                            },
                          },
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              lang: _vm.lang,
                              type: "date",
                              inline: true,
                              "value-format": "yyyy-MM-dd",
                              selection: true,
                              "disabled-date": _vm.maxDate,
                            },
                            on: {
                              pick: function ($event) {
                                return _vm.toggleDatePicker("newCaseFromDate")
                              },
                            },
                            model: {
                              value: _vm.newCaseFromDate,
                              callback: function ($$v) {
                                _vm.newCaseFromDate = $$v
                              },
                              expression: "newCaseFromDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dates-container" },
                [
                  _c("p", [_vm._v("Till")]),
                  _c("DatePickerButton", {
                    attrs: {
                      "active-date-picker": _vm.activeDatePicker,
                      "date-picker-value": _vm.datePickerValue.newCaseToDate,
                      "date-picker-type": "newCaseToDate",
                    },
                    on: { "toggle-date-picker": _vm.toggleDatePicker },
                  }),
                  _vm.activeDatePicker === "newCaseToDate"
                    ? _c(
                        "BaseModal",
                        {
                          attrs: { "show-close-btn": false },
                          on: {
                            close: function ($event) {
                              return _vm.toggleDatePicker("newCaseToDate")
                            },
                          },
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              lang: _vm.lang,
                              type: "date",
                              inline: true,
                              "value-format": "yyyy-MM-dd",
                              selection: true,
                              "disabled-date": _vm.maxDate,
                            },
                            on: {
                              pick: function ($event) {
                                return _vm.toggleDatePicker("newCaseToDate")
                              },
                            },
                            model: {
                              value: _vm.newCaseToDate,
                              callback: function ($$v) {
                                _vm.newCaseToDate = $$v
                              },
                              expression: "newCaseToDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm.showSwapFilter
            ? _c("div", { staticClass: "filter-container" }, [
                _c("p", [_vm._v("Hyresgäster önskar byta")]),
                _c("div", { staticClass: "dates-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "dates-container" },
                    [
                      _c("p", [_vm._v("Från")]),
                      _c("DatePickerButton", {
                        attrs: {
                          "active-date-picker": _vm.activeDatePicker,
                          "date-picker-value": _vm.datePickerValue.swapFromDate,
                          "date-picker-type": "swapFromDate",
                        },
                        on: { "toggle-date-picker": _vm.toggleDatePicker },
                      }),
                      _vm.activeDatePicker === "swapFromDate"
                        ? _c(
                            "BaseModal",
                            {
                              attrs: { "show-close-btn": false },
                              on: {
                                close: function ($event) {
                                  return _vm.toggleDatePicker("swapFromDate")
                                },
                              },
                            },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  lang: _vm.lang,
                                  type: "date",
                                  inline: true,
                                  "value-format": "yyyy-MM-dd",
                                  selection: true,
                                },
                                on: {
                                  pick: function ($event) {
                                    return _vm.toggleDatePicker("swapFromDate")
                                  },
                                },
                                model: {
                                  value: _vm.swapFromDate,
                                  callback: function ($$v) {
                                    _vm.swapFromDate = $$v
                                  },
                                  expression: "swapFromDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "dates-container" },
                    [
                      _c("p", [_vm._v("Till")]),
                      _c("DatePickerButton", {
                        attrs: {
                          "active-date-picker": _vm.activeDatePicker,
                          "date-picker-value": _vm.datePickerValue.swapToDate,
                          "date-picker-type": "swapToDate",
                        },
                        on: { "toggle-date-picker": _vm.toggleDatePicker },
                      }),
                      _vm.activeDatePicker === "swapToDate"
                        ? _c(
                            "BaseModal",
                            {
                              attrs: { "show-close-btn": false },
                              on: {
                                close: function ($event) {
                                  return _vm.toggleDatePicker("swapToDate")
                                },
                              },
                            },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  lang: _vm.lang,
                                  type: "date",
                                  inline: true,
                                  "value-format": "yyyy-MM-dd",
                                  selection: true,
                                },
                                on: {
                                  pick: function ($event) {
                                    return _vm.toggleDatePicker("swapToDate")
                                  },
                                },
                                model: {
                                  value: _vm.swapToDate,
                                  callback: function ($$v) {
                                    _vm.swapToDate = $$v
                                  },
                                  expression: "swapToDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "footer-container" }, [
          _c(
            "button",
            {
              staticClass: "clear-search-btn",
              attrs: { type: "button" },
              on: { click: _vm.clearFilter },
            },
            [_vm._v(" Rensa alla filter ")]
          ),
          _c(
            "div",
            { staticClass: "btns-container" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-dark": true },
                  on: { click: _vm.closeModal },
                },
                [
                  _vm._v(
                    " Visa " +
                      _vm._s(_vm.apps.length) +
                      " " +
                      _vm._s(
                        _vm.activeApp === "bytesansokan"
                          ? "byten"
                          : "ansökningar"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }