<template>
  <DefaultPage>
    <h1 class="page-title">Sidan kunde inte hittas</h1>
    <div class="content">
      <div class="card">
        Sidan du försökte komma åt kunde inte hittas. Detta kan bero på flera
        faktorer, såsom en felaktig länk, ett stavfel i webbadressen, eller att
        sidan har tagits bort/flyttats.
      </div>
    </div>
  </DefaultPage>
</template>
<script>
import DefaultPage from '@/components/DefaultPage.vue';

export default {
  components: {
    DefaultPage
  }
};
</script>
<style scoped>
.content {
  margin-left: 4rem;
  max-width: 100%;
  width: 1200px;
  padding-bottom: 20rem;
}

.page-title {
  color: var(--color-dark-blue);
  font-size: 3.2rem;
  margin: 3.2rem 3.2rem 3.2rem 4rem;
  height: 4rem;
  padding-bottom: 2rem;
}

.card {
  max-width: 100%;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  color: #2c3e50;
}
</style>
