var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasError
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _c(
        "DefaultModal",
        {
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("DefaultModalTitle", [_vm._v("Godkänn ansökan?")])],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("div", { style: { textAlign: "center" } }, [
              _c("p", { staticClass: "swap-description" }, [
                !_vm.rentPeriodChanged
                  ? _c("span", [_vm._v("Godkänn med följande period:")])
                  : _c("span", { staticClass: "rentchangetitle" }, [
                      _vm._v("Godkänn med ny period:"),
                    ]),
              ]),
              _c("div", { staticClass: "date-container" }, [
                _c(
                  "div",
                  { staticClass: "datePeriod" },
                  [
                    _c("Input", {
                      class: { hasChanged: _vm.rentFromChanged },
                      attrs: {
                        label: "Start datum",
                        type: "date",
                        min: _vm.sweDateStr(new Date()),
                      },
                      model: {
                        value: _vm.startDateProxy,
                        callback: function ($$v) {
                          _vm.startDateProxy = $$v
                        },
                        expression: "startDateProxy",
                      },
                    }),
                    _c("span", { staticClass: "line" }),
                    _c("Input", {
                      class: { hasChanged: _vm.rentToChanged },
                      attrs: {
                        label: "Slut datum",
                        type: "date",
                        min: _vm.startDateProxy,
                      },
                      model: {
                        value: _vm.endDateProxy,
                        callback: function ($$v) {
                          _vm.endDateProxy = $$v
                        },
                        expression: "endDateProxy",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.rentPeriodChanged
                ? _c("p", { staticClass: "wish-period" }, [
                    _vm._v(" Önskad period: "),
                    _c("br"),
                    _c("b", [
                      _c(
                        "span",
                        { class: { hasChanged: _vm.rentFromChanged } },
                        [_vm._v(_vm._s(_vm.rentFromProxy))]
                      ),
                      _vm._v(" - "),
                      _c("span", { class: { hasChanged: _vm.rentToChanged } }, [
                        _vm._v(_vm._s(_vm.rentToProxy)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.startDateIsAfterEndDate
                ? _c("p", { staticClass: "wish-period no-padding" }, [
                    _c("span", { staticClass: "red" }, [
                      _c("b", [_vm._v("Startdatumet")]),
                      _vm._v(" måste vara före "),
                      _c("b", [_vm._v("slutdatumet")]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-loading": _vm.isSaving,
                    "is-rounded": true,
                    icon: _vm.svgs.ICONS.CHECK_WHITE,
                    "is-disabled": _vm.startDateIsAfterEndDate,
                  },
                  on: { click: _vm.approveAhApplication },
                },
                [_vm._v("Godkänn ansökan")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-disabled": _vm.isSaving,
                    "is-rounded": true,
                    "is-default": true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Avbryt")]
              ),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }