var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultPage",
    [
      _c("FilterMain", {
        attrs: { value: _vm.filterQuery },
        on: { input: _vm.debouncedSearch, clear: _vm.clearInputSearch },
        model: {
          value: _vm.filterQuery,
          callback: function ($$v) {
            _vm.filterQuery = $$v
          },
          expression: "filterQuery",
        },
      }),
      _c(
        "div",
        { staticClass: "applications-view-wrapper" },
        [
          _vm.isLoadingApps
            ? _c(
                "div",
                { staticClass: "loading-wrapper" },
                [
                  _c("BaseLoadingSpinner", {
                    attrs: { "with-overlay": false, size: 60 },
                  }),
                ],
                1
              )
            : [
                _c(
                  "section",
                  { staticClass: "applications-wrapper" },
                  [
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Nekade",
                        status: "rejected",
                        applications: _vm.applicationsRejected,
                        "container-style": {
                          opacity: 0.5,
                        },
                        "to-path": "/andrahand/arkiv",
                      },
                    }),
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Avslutade",
                        status: "closed",
                        applications: _vm.applicationsClosed,
                        "to-path": "/andrahand/arkiv",
                      },
                    }),
                    _c("SubletApplicationsLane", {
                      attrs: {
                        title: "Borttagna",
                        status: "removed",
                        applications: _vm.applicationsDeleted,
                        "to-path": "/andrahand/arkiv",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c("router-view"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }