<template>
  <portal to="modal">
    <transition name="shuffle" appear>
      <div class="modal-wrapper">
        <div class="overlay" @click="closeModal"></div>
        <div ref="modalElement" class="modal">
          <component :is="component" v-bind="$attrs" @close="closeModal" />
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: 'ApplicationModal',
  props: {
    component: {
      type: undefined,
      default: undefined
    }
  },
  methods: {
    closeModal() {
      let returnPath = this.$route.fullPath.split('/');
      returnPath = `/${returnPath[1]}/${returnPath[2]}`;
      this.$router.push({
        path: returnPath
      });
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 10;
}

.modal {
  display: inline-block;
  margin: 0 auto;
  padding: 2em;
  border-radius: 1em;
  text-align: left;
  max-width: 100%;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000055;
}
</style>
