var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationListCheck",
    {
      attrs: {
        "hide-icon": _vm.hideIcon,
        "is-disabled": _vm.isDisabled,
        "is-checked": _vm.isChecked,
        "icon-url": _vm.customIcon || _vm.iconUrl,
        comment: _vm.commentExists ? _vm.comment : null,
        "has-files":
          _vm.isCommentable && !_vm.missingDocuments && !_vm.isIncoming,
        "icon-title": _vm.iconTitle,
      },
      on: {
        checked: function ($event) {
          return _vm.$emit("checksChange", $event)
        },
        selectedComment: function ($event) {
          return _vm.$emit("selectedComment", $event)
        },
        openFile: function ($event) {
          return _vm.$emit("openFile")
        },
        didClick: function ($event) {
          return _vm.$emit("didClick")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }