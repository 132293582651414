<template>
  <DefaultPage>
    <h1 class="page-title">Inställningar</h1>
    <div class="content">
      <div class="card">
        <div v-if="emailSent">
          <h4>Skickat!</h4>
          <p>Vänligen titta efter ett återställnings e-mail i din inbox.</p>
        </div>
        <form v-else @submit.stop.prevent="requestPasswordReset({ username })">
          <h4>Byt lösenord</h4>
          <p>
            Ett e-mail skickas till din e-postadress med instruktioner på hur du
            byter ditt lösenord.
          </p>
          <div class="email-input-wrapper">
            <DefaultInput
              v-model="username"
              name="username"
              :disabled="isLoading"
              placeholder="E-postadress"
              type="text"
              @clear="clearUsername"
            ></DefaultInput>
          </div>

          <p v-if="hasError" class="error-msg">
            Något gick fel, försök igen senare
          </p>

          <div class="reset-button">
            <BaseButton
              type="submit"
              :is-loading="isLoading"
              :is-disabled="isLoading || !username"
              :is-fullwidth="false"
              :is-rounded="true"
              >Återställ lösenord</BaseButton
            >
          </div>
        </form>
      </div>
    </div>
  </DefaultPage>
</template>
<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import DefaultInput from '@/components/DefaultInput.vue';
import DefaultPage from '@/components/DefaultPage.vue';

export default {
  components: {
    DefaultInput,
    BaseButton,
    DefaultPage
  },

  data() {
    return {
      isLoading: false,
      username: '',
      hasError: false,
      emailSent: false
    };
  },
  computed: {
    ...mapState({
      account: state => state.app.account
    }),
    email() {
      return this.account.email;
    }
  },
  mounted() {
    if (this.email) {
      this.username = this.email;
    }
  },
  methods: {
    async requestPasswordReset(username) {
      if (!username) {
        return;
      }

      this.hasError = false;
      this.isLoading = true;

      try {
        await this.$http.requestPasswordReset(this.username.trim());
        this.emailSent = true;
      } catch (error) {
        this.hasError = true;
      }

      this.isLoading = false;
    },

    clearUsername() {
      this.username = '';
    }
  }
};
</script>
<style scoped>
.content {
  margin-left: 4rem;
  max-width: 100%;
  width: 1200px;
  padding-bottom: 20rem;
}

.page-title {
  color: var(--color-dark-blue);
  font-size: 3.2rem;
  margin: 3.2rem 3.2rem 3.2rem 4rem;
  height: 4rem;
  padding-bottom: 2rem;
}

.card {
  max-width: 100%;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  color: #2c3e50;
}

.card h4 {
  font-size: 2.8rem;
  margin: 0 0 1.5rem;
}
.card p {
  margin: 0 0 1.5rem;
}
.card .reset-button {
  margin: 1.5rem 0 0;
}

.email {
  color: var(--color-dark-blue);
}

.email-input-wrapper {
  width: 60rem;
  max-width: 100%;
}
</style>
