import Vue from 'vue';
import App from './App.vue';
import LoginApp from './LoginApp.vue';
import PasswordResetApp from './PasswordResetApp.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import '@/assets/styles/index.css';
import http from './services/http';
import { APP_NAMES, APP_PATHS } from '@/utils/constants';
Vue.config.productionTip = false;

Vue.prototype.$http = http;

function getAppFromPath(appPath) {
  switch (appPath) {
    case APP_PATHS.BYTESANSOKAN:
      return APP_NAMES.BYTESANSOKAN;
    case APP_PATHS.ANDRAHAND:
      return APP_NAMES.ANDRAHAND;
    default:
      return '';
  }
}

function getRedirectPath() {
  let redirectPath = '/logga-in';
  const pathArr = window.location.pathname.split('/');
  const app = getAppFromPath(pathArr[1]);
  const appId = pathArr[3];
  const params = [];
  // Example: trying to access byten/alla/1337, but not authed
  // save info, later on in the Vue router(router/index.js)
  // we'll check these params and route user correctly
  if (app) {
    params.push(`app=${app}`);
  }
  if (appId) {
    params.push(`applicationId=${appId}`);
  }

  return params.length > 0
    ? `${redirectPath}?${params.join('&')}`
    : redirectPath;
}

async function init() {
  //   Eftersom man bara kan se om det är känsligt konto efter att man loggat in
  //   så finns det tre Vue appar. En Loginapp, Återställ lösenord app och PortalAppen.
  //   Loggar man in i LoginAppen körs en window.reload och man hamnar här igen
  //   Är man i PortalAppen och loggar ut körs window.reload (för att cleara Sentry)
  //   och man hamnar här igen...
  //  Ifall det är ändra lösenord startar vi PasswordReset appen (denna del har aldrig igång Sentry)
  if (window.location.pathname.includes('andra-losenord')) {
    new Vue({
      render: h => h(PasswordResetApp)
    }).$mount('#app');
    return;
  }
  try {
    const account = await http.getAccount();
    //  Kolla om man är inloggad
    if (!account) {
      throw new Error('Unauthorized');
    }
    //  Är man inloggad så kollar vi om det är känsligt konto och skippar isåfall sentry.
    if (
      window.location.hostname !== 'localhost' &&
      !account?.hasAltEmailAddress
    ) {
      Sentry.init({
        Vue,
        dsn: 'https://b6cc570b69714ba9bcccc2088efd0f43@o4504678253723648.ingest.sentry.io/4505007007662080',
        environment: process.env.NODE_ENV,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', /^\//]
          })
        ],
        ignoreErrors: [
          'Network Error',
          'Request aborted',
          'Request failed with status code 404',
          'Request failed with status code 500',
          "SyntaxError: Unexpected token '<'"
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
      });
    }
    //  Portalen laddas in
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  } catch (error) {
    //  Är man inte inloggad så startar vi LoginAppen (denna del har aldrig igång Sentry)
    new Vue({
      render: h => h(LoginApp)
    }).$mount('#app');
    if (window.location.pathname !== '/logga-in') {
      window.history.pushState({}, '', getRedirectPath());
    }
  }
}

init();
