var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showRejectReasonModal
    ? _c(
        "DefaultModal",
        {
          attrs: { "show-close-btn": false },
          on: {
            close: function ($event) {
              _vm.rejectReasonModalVisible = false
            },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("DefaultModalTitle", [_vm._v("Nekat byte")])],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "reject-reason" }, [
              _c("p", { staticClass: "explanation" }, [_vm._v("Förklaring:")]),
              _vm.reason
                ? _c("span", [_vm._v('"' + _vm._s(_vm.reason) + '"')])
                : _c("span", [_vm._v("(Förklaring saknas)")]),
              _vm.name
                ? _c("p", [_vm._v("- " + _vm._s(_vm.name))])
                : _c("p", [_vm._v("(Namn saknas)")]),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: {
                    click: function ($event) {
                      _vm.rejectReasonModalVisible = false
                    },
                  },
                },
                [_vm._v("Stäng")]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }