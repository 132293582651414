var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "shuffle", appear: "" } },
    [
      _c(
        "BaseModal",
        _vm._g(_vm._b({}, "BaseModal", _vm.$attrs, false), _vm.$listeners),
        [
          _c("transition", { attrs: { name: "scale", appear: "" } }, [
            _c("div", { staticClass: "error-modal-wrapper" }, [
              _c(
                "section",
                { staticClass: "header flex-center border-bottom" },
                [_c("DefaultModalTitle", [_vm._v(_vm._s(_vm.title))])],
                1
              ),
              _c("section", { staticClass: "body" }, [
                _c("p", { staticClass: "error-description" }, [
                  _vm._v(_vm._s(_vm.message)),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "footer flex-center" },
                [
                  _vm._t("footer", function () {
                    return [
                      _c(
                        "BaseButton",
                        {
                          attrs: { "is-rounded": true, "is-default": true },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v("Stäng")]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }