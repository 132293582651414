<template>
  <span class="title"><slot /></span>
</template>

<script>
export default {
  name: 'DefaultModalTitle'
};
</script>

<style scoped>
.title {
  padding: 2rem 4rem 1.5rem;
  text-transform: uppercase;
  font-family: var(--font-poppins);
  font-weight: 600;
}
</style>
