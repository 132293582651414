<template>
  <input
    :name="name"
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    name: {
      type: String,
      required: true
    }
  }
};
</script>
